import React from 'react';
import { withModel } from '@rexlabs/model-generator';
import sessionsModel from 'data/models/custom/session';

import LoadingLayout from 'view/layouts/loading';
import AuthLayout from 'view/layouts/auth';
import AppLayout from 'view/layouts/app';

function AdminApp({ session }) {
  if (!session.ready) {
    return <LoadingLayout />;
  }

  if (!session.apiToken) {
    return <AuthLayout />;
  }

  return <AppLayout />;
}

export default withModel(sessionsModel)(AdminApp);
