import { InputProps } from 'view/components/theme-settings/theme-setting-input';
import { Dropdown } from '@rexlabs/select';

const LISTING_CONTENT_OPTIONS = [
  { value: 'address', label: 'Address' },
  { value: 'price', label: 'Price' },
  { value: 'marketing', label: 'Marketing' }
];

export function getListingContentInput(): InputProps {
  return {
    input: Dropdown,
    inputProps: {
      items: LISTING_CONTENT_OPTIONS
    }
  };
}
