import React, { Component } from 'react';
import types from 'prop-types';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS } from 'src/theme';

const propTypes = {
  type: types.func.isRequired,
  inCircle: types.bool
};

const defaultStyles = {
  icon: {
    width: '100%',
    height: '100%',
    '& *': {
      fill: 'currentColor'
    }
  },
  container: {
    display: 'inline-block',
    width: '3.6rem',
    height: '3.6rem',
    color: 'black',
    boxSizing: 'content-box'
  },
  circle: {
    padding: '1rem',
    background: COLORS.GRAY,
    borderRadius: '50%'
  }
};

@styled(StyleSheet(defaultStyles))
class Icon extends Component {
  static propTypes = propTypes;

  render() {
    const { type: Type, inCircle, styles: s, ...rest } = this.props;
    return (
      <div {...rest} {...s.with('container', { circle: inCircle })(rest)}>
        {Type && <Type {...s('icon')} />}
      </div>
    );
  }
}

export default Icon;
