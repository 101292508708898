import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  ReactElement
} from 'react';

interface Toast {
  id: number;
  Icon?: ReactElement;
  type?: 'info' | 'warning' | 'error' | 'success';
  title?: string;
  content: string | ReactElement;
  duration?: number;
  target: string;
  onRemove: () => void;
  clearable?: boolean;
}

interface AddToastArgs {
  Icon?: ReactElement;
  title?: string;
  type?: 'info' | 'warning' | 'error' | 'success';
  content: string | ReactElement;
  duration?: number;
  target?: string;
  clearable?: boolean;
}

export type AddToastFn = (args: AddToastArgs) => void;

export interface ToastContextType {
  toasts: Toast[];
  addToast: (arg: AddToastArgs) => void;
}

export const ToastContext = createContext<ToastContextType>({
  toasts: [],
  addToast: () => {}
});

let counter = 0;

export function ToastProvider({ children }) {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const value = useMemo<ToastContextType>(
    () => ({
      toasts,
      addToast: ({
        title,
        Icon,
        type,
        content,
        duration = 5000,
        target = 'main',
        clearable
      }: AddToastArgs) => {
        const uuid = ++counter;
        const newToast = {
          id: uuid,
          title,
          Icon,
          content,
          target,
          duration,
          type,
          onRemove: () =>
            setToasts((toasts) => toasts.filter((toast) => toast.id !== uuid)),
          clearable
        };

        setToasts((toasts) => [...toasts, newToast]);
      }
    }),
    [toasts]
  );

  return (
    <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
  );
}

export function useToast() {
  return useContext(ToastContext);
}
