import Generator from '../generator';
import { api } from 'utils/api-client';

const config = {
  entities: {
    api: {
      fetchItem: (type, { id }) => api.get(`/users/${id}`),
      fetchList: (type, { websiteId, ...args }) =>
        api.get(`/websites/${websiteId}/users`, args),
      createItem: (type, args) => api.post(`/users`, args),
      updateItem: (type, { id, ...args }) => api.post(`/users/${id}`, args),
      trashItem: (type, { id, websiteId }) =>
        api.delete(`/websites/${websiteId}/users/${id}`)
    }
  }
};

export default new Generator('users', config).createEntityModel();
