/**
 * To clear the wordpress cookies, we hit the authentication endpoint (admin-ajax.php) on wordpress
 * with the `logout` parameter set to true. This manually runs the `wp_logout()` function
 * which clears all the cookies set by wordpress. After the iframe is done loading we redirect back
 * to the login page.
 */

import React, { useRef, useMemo, useState } from 'react';
import { matchMany, replace } from '@rexlabs/whereabouts';
import { withWhereabouts } from '@rexlabs/react-whereabouts';

import { compose } from 'lodash/fp';
import { withModel } from '@rexlabs/model-generator';
import sessionsModel from 'data/models/custom/session';

import ROUTES from 'src/routes/app';
import config from 'src/config';
import { match } from '@rexlabs/whereabouts/lib/utils/match';

function WordpressLogout({ whereabouts, session }) {
  const frameRef = useRef();
  const [loadingText, setLoadingText] = useState('Please wait...');

  const websiteId = useMemo(
    () =>
      match(whereabouts, { path: '/website/:websiteId' })?.params?.websiteId,
    [whereabouts]
  );

  const onLoad = () => {
    setLoadingText('Done! Redirecting...');
    session.logout();
    replace(ROUTES.HOME);
  };

  const url = useMemo(() => {
    let memUrl = `${config.WP_URL}/wp-admin/admin-ajax.php?logout=true`;
    const matched = matchMany(whereabouts, ROUTES);
    if (matched?.[0]?.route?.wordpressUrl) {
      memUrl += matched[0].route.wordpressUrl;
    }
    return memUrl;
  }, [websiteId, whereabouts]);

  const [frameUrl] = useState(url);
  return (
    <div>
      <p>{loadingText}</p>
      <iframe
        width='100%'
        style={{ height: '100%', display: 'none' }}
        ref={frameRef}
        src={frameUrl}
        onLoad={onLoad}
      />
    </div>
  );
}

export default compose(
  withWhereabouts,
  withModel(sessionsModel)
)(WordpressLogout);
