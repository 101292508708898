import React, { useCallback, useEffect, useState } from 'react';
import { compose } from 'lodash/fp';
import { styled, StyleSheet } from '@rexlabs/styling';
import types from 'prop-types';
import { OutlineButton } from '@rexlabs/button';
import FileUpload from '@rexlabs/file-upload-input';
import Box from '@rexlabs/box';
import _ from 'lodash';
import { api } from 'utils/api-client';
import { withWhereabouts } from '@rexlabs/react-whereabouts';
import { match } from '@rexlabs/whereabouts/lib/utils/match';
import * as t from 'src/theme';
import { Body } from '@rexlabs/text';

// todo is styling not working?
const fileUploadStyles = StyleSheet({
  container: {
    marginTop: t.PADDINGS.L,
    marginBottom: t.PADDINGS.M,
    borderColor: t.COLORS.RED,
    backgroundColor: t.COLORS.RED
  },
  inner: {
    backgroundColor: t.COLORS.GREY_BLUE_LIGHT,
    cursor: 'pointer',
    minHeight: '7.8rem'
  },
  containerError: {
    borderColor: t.COLORS.GREY_BLUE_LIGHT
  },
  error: {
    color: ({ token }) => token('color.textStyle.body.error')
  }
});

const defaultAcceptedTypes = [
  'image/png',
  'image/svg+xml',
  'image/jpg',
  'image/jpeg',
  'image/bmp',
  'application/pdf'
];

const defaultAcceptedExtensions = [
  '.png',
  '.pdf',
  '.svg',
  '.jpg',
  '.jpeg',
  '.bmp'
];

function FileInputZone({
  styles: s,
  whereabouts,
  onChange,
  name,
  value,
  multiple,
  category,
  type,
  settingKey,
  themeFile = false,
  actions: { setValue },
  acceptedTypes = defaultAcceptedTypes,
  acceptedExtensions = defaultAcceptedExtensions
}) {
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const [files, setFiles] = useState(Array.isArray(value) ? value : [value]);

  // Load on mount
  /**
   * For some reason full setting object being sent to handlesubmit function
   * this is temporary workaround
   */
  useEffect(() => {
    onChange({
      persist: () => null,
      target: {
        type: 'string',
        name,
        id: name,
        value: multiple ? files?.map?.((file) => file?.id) : files?.[0]?.id
      }
    });
  }, []);

  const handleSubmit = useCallback(
    (values) => {
      // Don't submit if we have nothing to submit
      if (!values?.target?.files.length) {
        return;
      }

      // Reset errors
      setError(null);

      const formData = new FormData();

      values?.target?.files.forEach((f) => {
        formData.append('files[]', f);
      });
      formData.append('setting_key', settingKey);
      formData.append('category', category);
      formData.append('type', type);
      formData.append('theme_file', themeFile === true);

      const websiteId = match(whereabouts, {
        path: '/website/:websiteId'
      })?.params?.websiteId;

      setIsUploading(true);

      api
        .post(`/websites/${websiteId}/files`, formData)
        .then((res) => {
          setIsUploading(false);
          onChange({
            persist: () => null,
            target: {
              type: 'string',
              name,
              id: name,
              // TODO: Clean this mess up
              value: multiple
                ? res?.data?.length > 1
                  ? _.map(res?.data, 'id')
                  : [res?.data[0]?.id]
                : res?.data[0]?.id
            }
          });
          setValue(multiple ? res.data.map((d) => d.id) : res.data[0].id);
        })
        .catch((e) => {
          setIsUploading(false);
          setError(e?.message);
        });
    },
    [onChange, whereabouts, name, settingKey, category, type, themeFile]
  );

  function resetFiles() {
    setFiles(null);
    onChange({
      persist: () => null,
      target: {
        type: 'string',
        name,
        id: name,
        value: null
      }
    });
    setValue(null);
  }

  if (files) {
    return (
      <Box flex={1} flexDirection={'row'} justifyContent={'space-between'}>
        <Box
          id={'file-input-preview'}
          flexDirection={'row'}
          style={{
            overflow: 'scroll',
            backgroundColor: 'rgb(225, 225, 225)',
            borderRadius: '4px'
          }}
          ali
        >
          {files.map((f) => {
            return (
              <Box
                key={f?.id}
                flex={1}
                justifyContent={'center'}
                alignItems={'center'}
                height={'75px'}
                padding={'4px'}
              >
                <img
                  width={files.length > 1 ? 'auto' : '100%'}
                  height='100%'
                  style={{ objectFit: 'contain' }}
                  src={f?.url || f?.uri}
                />
              </Box>
            );
          })}
        </Box>
        <Box display={'flex'} alignItems={'center'} marginLeft={'4px'}>
          <OutlineButton onClick={resetFiles}>Upload new</OutlineButton>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      {isUploading && <p>uploading...</p>}
      {error && (
        <Body {...s('error')}>
          An error occurred while uploading the file.{error}
        </Body>
      )}
      <FileUpload
        onInvalidType={() =>
          setError(
            ` Accepted file extensions (${acceptedExtensions.join(', ')}).`
          )
        }
        onInvalidFileSize={() => setError(' Maximum file size is 5 MB.')}
        acceptTypes={acceptedTypes}
        acceptExtensions={acceptedExtensions}
        onChange={handleSubmit}
        shouldAllowMultiple={multiple}
        styles={fileUploadStyles}
        maxFileSize={5000}
      />
    </Box>
  );
}

FileInputZone.propTypes = {
  multiple: types.bool,
  category: types.string.isRequired,
  type: types.string.isRequired
};

FileInputZone.defaultProps = {
  multiple: false
};

export default compose(
  withWhereabouts,
  styled(fileUploadStyles)
)(FileInputZone);
