import Box from '@rexlabs/box';
import { PrimaryButton } from '@rexlabs/button';
import { Field, Form, ReactForms } from '@rexlabs/form';
import { withModel } from '@rexlabs/model-generator';
import { PasswordInput } from '@rexlabs/password-input';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Body, Heading } from '@rexlabs/text';
import React, { PureComponent, Fragment } from 'react';
import { Link } from '@rexlabs/react-whereabouts';
import { createValidationRules } from '@rexlabs/validator';
import { autobind } from 'core-decorators';

import ROUTES from 'src/routes/auth';
import { COLORS } from 'src/theme';
import sessionModel from 'data/models/custom/session';
import { ErrorNotification } from 'src/view/components/notification';

const validate = createValidationRules({
  new_password: 'required|min:8',
  confirm_password: [
    'required|min:8|same:new_password',
    'password confirmation'
  ]
});

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    height: '100%',
    background: ({ token }) => token('color.primary.idle.default'),
    padding: ({ token }) => token('spacing.s')
  },

  button: {
    width: '100%',
    marginTop: '10px',
    marginBottom: '10px'
  },

  description: {
    color: COLORS.GRAY,
    padding: '10px 0'
  },

  backButtonWrapper: {
    height: '4rem',
    width: 'auto',
    marginBottom: '10rem',
    color: COLORS.GRAY,
    textDecoration: 'none'
  },

  backButton: {
    color: COLORS.GRAY,
    textDecoration: 'none'
  },

  formField: {
    marginBottom: '2rem'
  },

  fieldWrapper: {
    padding: '10px 0'
  }
});

@withModel(sessionModel)
@styled(defaultStyles)
@autobind
class ResetPassword extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: ''
    };
  }

  componentDidMount() {
    window.analytics?.page?.('Reset Password');
  }

  handleSubmit(values) {
    const { session, whereabouts } = this.props;

    return session
      .resetPassword({
        token: whereabouts.query.token,
        email: whereabouts.query.email,
        new_password: values.new_password,
        confirm_password: values.confirm_password
      })
      .then(() => {
        session
          .login({
            email: whereabouts.query.email,
            password: values.new_password
          })
          .catch((e) => {
            console.error(e);
            this.setState({ error: e.message });
          });
      })
      .catch((e) => {
        console.error(e);
        this.setState({ error: e.message });
      });
  }

  render() {
    const { styles: s } = this.props;
    const { error } = this.state;

    return (
      <Fragment>
        <Body {...s('backButtonWrapper')}>
          <Link to={ROUTES.LOGIN} {...s('backButton')}>
            Back
          </Link>
        </Body>
        <Heading>Reset your password</Heading>
        <Body {...s('description')}>Minimum of 8 characters</Body>
        {error && <ErrorNotification>{error}</ErrorNotification>}
        <ReactForms validate={validate} handleSubmit={this.handleSubmit}>
          {({ submitForm, isSubmitting }) => (
            <Form>
              <Box {...s('fieldWrapper')}>
                <Field
                  Input={PasswordInput}
                  name='new_password'
                  type='password'
                  label='New password'
                  inputProps={{
                    placeholder: 'Enter Password'
                  }}
                  optional={false}
                  useSlowField
                />
              </Box>
              <Box {...s('fieldWrapper')}>
                <Field
                  Input={PasswordInput}
                  name='confirm_password'
                  type='password'
                  label='Confirm password'
                  inputProps={{
                    placeholder: 'Retype Password'
                  }}
                  optional={false}
                  useSlowField
                />
              </Box>
              <Box
                flexDirection='row'
                alignItems='center'
                justifyContent='space-between'
              >
                <PrimaryButton
                  {...s('button')}
                  isLoading={isSubmitting}
                  onClick={submitForm}
                >
                  Save password &amp; log in
                </PrimaryButton>
              </Box>
            </Form>
          )}
        </ReactForms>
      </Fragment>
    );
  }
}

export default ResetPassword;
