import React, { useState } from 'react';
import PreviewLayout from '../../layouts/preview';
import EditHomePageSettings from 'view/components/theme-settings/edit-home-page-settings';
import { get } from 'lodash';

import './block.css';

function getWebsiteId(props) {
  return get(props, 'match.params.websiteId');
}

interface Props {
  blocks: any;
  match: any;
  styles: any;
}

function BlockScreen(props: Props) {
  const defaultPreviewProps = {
    websiteId: getWebsiteId(props),
    values: {
      // settings: homepageSettings?.values,
      isTheme: true,
      component: 'index.twig'
    }
  };
  const [previewProps, setPreviewsProps] = useState(defaultPreviewProps);
  const updatePreviewProps = () => {
    setPreviewsProps({
      ...defaultPreviewProps,
      values: { ...defaultPreviewProps.values }
    });
  };

  return (
    <PreviewLayout
      Settings={
        <EditHomePageSettings
          updatePreviewProps={updatePreviewProps}
          {...props}
        />
      }
      previewProps={previewProps}
    />
  );
}

export default BlockScreen;
