export const BANNER_COLOR_VARIANTS: Record<
  string,
  { label: string; value: string }
> = {
  'primary-500': {
    label: 'Primary',
    value: 'primary-500'
  },
  'primary-600': {
    label: 'Primary Light',
    value: 'primary-600'
  },
  'primary-400': {
    label: 'Primary Dark',
    value: 'primary-400'
  },
  'secondary-500': {
    label: 'Secondary',
    value: 'secondary-500'
  },
  'secondary-600': {
    label: 'Secondary Light',
    value: 'secondary-600'
  },
  'secondary-400': {
    label: 'Secondary Dark',
    value: 'secondary-400'
  },
  white: {
    label: 'White',
    value: 'white'
  },
  dark: {
    label: 'Black',
    value: 'dark'
  }
};
