import React, { forwardRef } from 'react';
import { PrimaryCircleButton } from '@rexlabs/button';
import ActionMenu from '@rexlabs/action-menu';
import QuestionMarkIcon from '@rexlabs/icons/medium/question-open.svg';

function HelpButton() {
  const items = [
    {
      label: 'Contact Support',
      onClick: () =>
        window.open('https://support.siteloft.com/hc/en-us', '_blank')
    }
  ];

  return (
    <ActionMenu
      items={items}
      Button={forwardRef((props, ref) => (
        <PrimaryCircleButton {...props} ref={ref} Icon={QuestionMarkIcon} />
      ))}
    />
  );
}
export default HelpButton;
