import { InputProps } from 'view/components/theme-settings/theme-setting-input';
import { Dropdown } from '@rexlabs/select';

type VariantOption = { [key: string]: { value: string; label: string } };

export const COLOR_VARIANT_OPTIONS: VariantOption = {
  light1: { value: 'light1', label: 'White' },
  light2: { value: 'light2', label: 'Grey' }
};

export function getColorVariantInput(): InputProps {
  return {
    input: Dropdown,
    inputProps: {
      items: Object.values(COLOR_VARIANT_OPTIONS)
    }
  };
}
