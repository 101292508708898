import React, { useEffect } from 'react';
import { compose } from 'redux';
import Box from '@rexlabs/box';
import { ReactForms, Form, Field } from '@rexlabs/form';
import { RenderLoading } from 'view/components/render-loading';
import { withQuery, query } from '@rexlabs/model-generator';
import settings from 'data/models/entities/settings';
import { handleSettingsSubmit } from 'utils/handle-settings-submit';
import { PrimaryButton } from '@rexlabs/button';
import { TextInput } from '@rexlabs/text-input';
import { withToast } from 'view/components/toast';
import { FileInputZone } from 'view/components/file-input';
import { Dropdown } from '@rexlabs/select';
import { BANNER_COLOR_VARIANTS } from './constants/bannerColorVariants.ts';

function getWebsiteId(props) {
  return props?.match?.params?.websiteId;
}

const settingQuery = query`{
  ${settings} (websiteId: ${getWebsiteId}, category: listings_landing_page_info) {
    key
    value
    label
    type
    category
  }
}`;

const listingsinfo = {
  name: 'listingsinfo',
  mapPropsToValues: (props) => {
    const { settings } = props;
    const settingsData = settings?.list?.items;

    const data = Object.assign(
      {},
      ...settingsData.map((object) => {
        switch (object.type) {
          case 'image':
            return {
              [object.key]: object?.value?.data
                ? object?.value.data
                : object?.value
            };
          default:
            return { [object.key]: object?.value ? object?.value[0] : null };
        }
      })
    );
    return {
      ...data,
      listings_landing_page_banner_color:
        BANNER_COLOR_VARIANTS[data.listings_landing_page_banner_color]
    };
  },
  handleSubmit: handleSettingsSubmit,
  asyncValuesReady: (props) => {
    return props.settings.list.status === 'loaded';
  }
};

const SectionTitle = (props) => {
  return (
    <Box
      flex={1}
      flexDirection={'row'}
      padding='10px'
      alignItems={'center'}
      marginTop='20px'
    >
      <h2
        style={{
          fontSize: '18px',
          fontWeight: '700',
          marginRight: '20px'
        }}
      >
        {props.title}
      </h2>
    </Box>
  );
};

function ListingsInfo(props) {
  const { settings } = props;

  const trackSave = () => {
    window.analytics?.track?.('siteloft.design', {
      action: 'updated listings landing page',
      location: 'Listings Landing Page'
    });
  };

  useEffect(() => {
    window.analytics?.page?.('Listings Landing Page');
  }, []);

  return (
    <Box flex={1} style={{ padding: '20px 20px 100px 20px', overflow: 'auto' }}>
      <RenderLoading isLoading={settings.list.status !== 'loaded'}>
        <ReactForms
          initialValues={listingsinfo.mapPropsToValues(props)}
          handleSubmit={(values) =>
            listingsinfo.handleSubmit(values, { props: props })
          }
        >
          {({ submitForm, isSubmitting }) => (
            <Form name='customCss' style={{ width: '100%' }}>
              <Box
                flex={1}
                flexDirection={'row'}
                padding='10px'
                alignItems={'center'}
              >
                <h1
                  style={{
                    fontSize: '28px',
                    fontWeight: '700',
                    marginRight: '20px'
                  }}
                >
                  {' '}
                  Listings Landing Page{' '}
                </h1>

                <PrimaryButton
                  onClick={(e) => {
                    trackSave();
                    submitForm(e);
                  }}
                  isLoading={isSubmitting}
                >
                  Save
                </PrimaryButton>
              </Box>

              <SectionTitle title={'Cover Banner'} />

              <Box padding='10px' flex={1}>
                <Field
                  name='listings_landing_page_banner_title'
                  label='Banner Title'
                  Input={TextInput}
                  optional={false}
                />
              </Box>
              <Box padding='10px' flex={1}>
                <Field
                  name='listings_landing_page_cover_image'
                  label='Banner Image'
                  Input={FileInputZone}
                  inputProps={{
                    type: 'image',
                    settingKey: 'listings_landing_page_cover_image',
                    acceptedTypes: ['image/png', 'image/jpg', 'image/jpeg'],
                    acceptedExtensions: ['.png', '.jpg', '.jpeg']
                  }}
                  optional={true}
                  HelpTooltipContent={() =>
                    ' If an image is not provided, the banner will use the background colour.'
                  }
                />
              </Box>
              <Box padding='10px' flex={1}>
                <Field
                  name='listings_landing_page_banner_color'
                  label='Tint Colour'
                  Input={Dropdown}
                  inputProps={{
                    items: Object.values(BANNER_COLOR_VARIANTS)
                  }}
                  optional={false}
                />
              </Box>
              <Box padding='10px' flex={1}>
                <Field
                  name='listings_landing_page_banner_color_tint'
                  label='Tint Opacity'
                  Input={TextInput}
                  inputProps={{
                    type: 'number',
                    min: 0,
                    max: 1,
                    step: 0.05
                  }}
                  HelpTooltipContent={() => 'Must be between 0.0 and 1.0.'}
                  optional={false}
                />
              </Box>

              <SectionTitle title={'SEO Settings'} />

              <Box padding='10px' flex={1}>
                <Field
                  name='listings_landing_page_seo_title'
                  label='Page Title'
                  Input={TextInput}
                  optional={true}
                />
              </Box>
              <Box padding='10px' flex={1}>
                <Field
                  name='listings_landing_page_seo_description'
                  label='Description'
                  Input={TextInput}
                  optional={true}
                />
              </Box>
              <Box padding='10px' flex={1}>
                <Field
                  name='listings_landing_page_seo_canonical_url'
                  label='Canonical URL'
                  Input={TextInput}
                  optional={true}
                />
              </Box>
              <Box padding='10px' flex={1}>
                <Field
                  name='listings_landing_page_seo_image'
                  label='Social Image'
                  Input={FileInputZone}
                  HelpTooltipContent={() => (
                    <span>
                      The featured image for sites like Facebook & Twitter
                    </span>
                  )}
                  inputProps={{
                    type: 'image',
                    settingKey: 'listings_landing_page_seo_image',
                    acceptedTypes: ['image/png', 'image/jpg', 'image/jpeg'],
                    acceptedExtensions: ['.png', '.jpg', '.jpeg']
                  }}
                  optional={true}
                />
              </Box>
            </Form>
          )}
        </ReactForms>
      </RenderLoading>
    </Box>
  );
}

export default compose(withToast, withQuery(settingQuery))(ListingsInfo);
