import React from 'react';
import { useToast } from './context';

export default function withToast(Component) {
  function WrappedComponent(props: any) {
    const { addToast } = useToast();

    return <Component addToast={addToast} {...props} />;
  }

  return WrappedComponent;
}
