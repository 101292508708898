import React from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, styled, useToken, insertCss } from '@rexlabs/styling';
import { createTokenFn } from '@rexlabs/element-styles';
import { TOKENS } from 'theme/tokens';
import LoadingSpinner from '@rexlabs/loading-spinner';
import { Body } from '@rexlabs/text';

const token = createTokenFn(TOKENS);

insertCss(`
  body {
    background: ${token('color.pageBackground.default')};
  }
`);

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    height: '100%'
  }
});

function LoadingLayout({ styles: s, message = 'Initialising app...' }) {
  const token = useToken();
  return (
    <Box
      {...s('container')}
      flex={1}
      alignItems='center'
      justifyContent='center'
      flexDirection='column'
    >
      <LoadingSpinner
        size={32}
        strokeWidth={4}
        color={[token('color.grey.200'), token('color.grey.400')]}
      />
      <Body grey>{message}</Body>
    </Box>
  );
}

export default styled(defaultStyles)(LoadingLayout);
