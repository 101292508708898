import { InputProps } from 'view/components/theme-settings/theme-setting-input';
import { Dropdown } from '@rexlabs/select';

export const TESTIMONIAL_OPTIONS = {
  featured: { value: 'featured', label: 'Featured Testimonials' },
  all: { value: 'all', label: 'All Testimonials' }
};

export function getTestimonialsInput(): InputProps {
  return {
    input: Dropdown,
    inputProps: {
      items: Object.values(TESTIMONIAL_OPTIONS)
    }
  };
}
