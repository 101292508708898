import React, { useCallback } from 'react';
import { Dialog } from '@rexlabs/dialog';
import { ReactForms, Form, Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { Checkbox } from '@rexlabs/checkbox';
import { withQuery } from '@rexlabs/model-generator';
import { ButtonGroup, GhostButton, PrimaryButton } from '@rexlabs/button';
import { createValidationRules } from '@rexlabs/validator';

import { domainsQuery } from 'data/queries/domains';

const validate = createValidationRules({
  domain: 'required'
});

function CreateDomainDialog({ match, domains, onClose, ...props }) {
  const handleSubmit = useCallback(
    async (values) => {
      await domains.createItem({
        data: {
          websiteId: match?.params?.websiteId,
          domain: values?.domain,
          is_primary: values?.is_primary ?? false,
          redirect_to_path: values?.redirect_to_path,
          is_microsite: values?.is_microsite ?? false
        }
      });
      await domains.refreshList();
      onClose();
    },
    [domains, match, onClose]
  );

  return (
    <Dialog title='Add a Domain' onClose={onClose} width='40rem' {...props}>
      <ReactForms handleSubmit={handleSubmit} validate={validate}>
        {({ isSubmitting, submitForm }) => (
          <Form>
            <Field
              fullWidth
              optional={false}
              Input={TextInput}
              name='domain'
              label='Domain'
            />
            <Field
              fullWidth
              optional={true}
              Input={Checkbox}
              name='is_primary'
              label='Primary'
            />
            <Field
              fullWidth
              optional={true}
              Input={TextInput}
              name='redirect_to_path'
              label='Redirects to path'
            />
            <Field
              fullWidth
              optional={true}
              Input={Checkbox}
              name='is_microsite'
              label='Acts as microsite'
            />

            <ButtonGroup mt='2rem' end>
              <GhostButton onClick={onClose}>Cancel</GhostButton>
              <PrimaryButton isLoading={isSubmitting} onClick={submitForm}>
                Save
              </PrimaryButton>
            </ButtonGroup>
          </Form>
        )}
      </ReactForms>
    </Dialog>
  );
}

export default withQuery(domainsQuery)(CreateDomainDialog);
