/*
|-------------------------------------------------------------------------------
| Singleton App Store (Redux)
|-------------------------------------------------------------------------------
|
| It's inclusion in other modules in the App should ideally come from dependency
| injection techniques to keep those files testable.
|
*/

import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineModels } from '@rexlabs/model-generator';
import { configureStore } from 'utils/redux';
import {
  whereaboutsReducer as whereabouts,
  whereaboutsMiddleware
} from '@rexlabs/whereabouts';

/*
| Core Models
|------------------------
*/
import session from 'data/models/custom/session';
import connection, { middleware } from 'data/models/custom/connection';

/*
 | Entity Models (Model Generator)
 |------------------------
 */
import websites from './data/models/entities/websites';
import redirects from './data/models/entities/redirects';
import domains from './data/models/entities/domains';
import forms from './data/models/entities/forms';
import settings from './data/models/entities/settings';
import themeSettings from './data/models/entities/themesettings';
import themes from './data/models/entities/themes';
import submissions from './data/models/entities/submissions';
import actions from './data/models/entities/actions';
import popups from './data/models/entities/popups';
import pages from './data/models/entities/pages';
import menus from './data/models/entities/menus';
import users from './data/models/entities/users';
import agencies from './data/models/entities/agencies';
import listings from './data/models/entities/listings';
import files from './data/models/entities/files';
import syncs from './data/models/entities/syncs';
import websiteListingSources from './data/models/entities/website-listing-sources';
import blocks from 'data/models/entities/blocks';
import seo from './data/models/entities/seo';

const entities = combineModels('entities', {
  websites,
  redirects,
  domains,
  forms,
  settings,
  submissions,
  menus,
  popups,
  pages,
  actions,
  syncs,
  users,
  websiteListingSources,
  themeSettings,
  themes,
  agencies,
  listings,
  files,
  blocks,
  seo
});

/*
| Setup for Store (incl. persistance)
|------------------------
*/
const persistSessionConfig = {
  key: '@siteloft/session',
  storage,
  blacklist: ['ready']
};
const persistSession = persistReducer(persistSessionConfig, session);

const reducers = combineReducers({
  session: persistSession,
  connection,
  whereabouts,
  entities
});

const store = configureStore(reducers, [middleware, whereaboutsMiddleware]);
const persistor = persistStore(store, null, () => {
  store.dispatch(session.actionCreators.init());
});

if (__DEV__) {
  window.purgePersistedStore = persistor.purge;
}

export { store, persistor };
