import React, { PureComponent } from 'react';
import ForgotPassword from './forgot-password';
import ResetPassword from './reset-password';

class ForgotPasswordScreen extends PureComponent {
  render() {
    const { whereabouts } = this.props;
    return whereabouts.query.token ? (
      <ResetPassword {...this.props} />
    ) : (
      <ForgotPassword {...this.props} />
    );
  }
}

export default ForgotPasswordScreen;
