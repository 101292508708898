import { getImageInput } from 'view/components/theme-settings/dynamic-inputs/imageInput';
import { getMultiImageInput } from 'view/components/theme-settings/dynamic-inputs/multiImageInput';
import { ColorPickerInput } from 'view/components/color-picker';
import { Checkbox } from '@rexlabs/checkbox';
import { getTestimonialsInput } from 'view/components/theme-settings/dynamic-inputs/testimonialsInput';
import { getBlogOptionsInput } from 'view/components/theme-settings/dynamic-inputs/blogOptionsInput';
import { getStaffOptionsInput } from 'view/components/theme-settings/dynamic-inputs/staffOptionsInput';
import { getListingsOptionsInput } from 'view/components/theme-settings/dynamic-inputs/listingsOptionsInput';
import { getNumberInput } from 'view/components/theme-settings/dynamic-inputs/numberInput';
import { TextArea, TextInput } from '@rexlabs/text-input';
import { RangeInput } from 'view/components/range';
import { Dropdown } from '@rexlabs/select';
import { getFormInput } from 'view/components/theme-settings/dynamic-inputs/formInput';
import { getMenuInput } from 'view/components/theme-settings/dynamic-inputs/menuInput';
import { getColumnAmountInput } from 'view/components/theme-settings/dynamic-inputs/columnAmountInput';
import { getListingContentInput } from 'view/components/theme-settings/dynamic-inputs/listingContentInput';
import { getListingsMaxColumnsInput } from 'view/components/theme-settings/dynamic-inputs/listingsMaxColumnsInput';
import { getOptionalBannerComponentInput } from 'view/components/theme-settings/dynamic-inputs/optionalBannerComponentInput';
import {
  InputProps,
  Setting
} from 'view/components/theme-settings/theme-setting-input';
import { contentBlockAlignment } from 'view/components/theme-settings/dynamic-inputs/contentBlockAlignment';
import { getColorVariantInput } from 'view/components/theme-settings/dynamic-inputs/colorVariantInput';
import { getBrandColorVariantInput } from 'view/components/theme-settings/dynamic-inputs/brandColorVariantInput';
import VersatileSettings from '../../../../../backend/resources/views/personas/versatile/settings.json';
import ByronSettings from '../../../../../backend/resources/views/personas/byron/settings.json';
import { getBlockVariantInput } from 'view/components/theme-settings/dynamic-inputs/blockVariantInput';
import { getLaunchpadVariantInput } from 'view/components/theme-settings/dynamic-inputs/launchpadVariantInput';
import { getContentContainerInput } from 'view/components/theme-settings/dynamic-inputs/contentContainerVariant';

export function getInputFromType(
  setting: Setting,
  resourceList: any,
  persona: string
): InputProps | null {
  switch (setting.type) {
    case 'image':
      return getImageInput(setting);
    case 'multi-image':
      return getMultiImageInput(setting);
    case 'color':
      return { input: ColorPickerInput };
    case 'checkbox':
      return { input: Checkbox };
    case 'testimonials':
      return getTestimonialsInput();
    case 'blog':
      return getBlogOptionsInput();
    case 'staff':
      return getStaffOptionsInput();
    case 'listings':
      return getListingsOptionsInput();
    case 'number':
      return getNumberInput();
    case 'banner-subtitle-text':
      return {
        input: TextArea,
        inputProps: setting.input_props ?? {
          charLimit: 300,
          isSoftLimit: true
        }
      };
    case 'text':
      return {
        input: TextInput,
        inputProps: setting.input_props ?? {}
      };
    case 'multi-line-text':
      return { input: TextArea, inputProps: setting.input_props ?? {} };
    case 'slider':
      return { input: RangeInput };
    case 'menu-dropdown':
      return { input: Dropdown };
    case 'form':
      return getFormInput(resourceList);
    case 'menu':
      return getMenuInput(resourceList);
    case 'column-amount':
      return getColumnAmountInput();
    case 'color-variant':
      return getColorVariantInput();
    case 'brand-color-variant':
      return getBrandColorVariantInput();
    case 'block-variant':
      return getBlockVariantInput(setting.input_props?.options ?? []);
    case 'launchpad-variant':
      return getLaunchpadVariantInput();
    case 'listings-content':
      return getListingContentInput();
    case 'listings-max-columns':
      return getListingsMaxColumnsInput();
    case 'optional-banner-component':
      return getOptionalBannerComponentInput(persona);
    case 'content-block-alignment':
      return contentBlockAlignment();
    case 'content-container-variant':
      return getContentContainerInput();
    default:
      return null;
  }
}

export function getPersonaBlockTypes(key: string) {
  const blockTypes = {
    versatile: (VersatileSettings as any).groups
      .filter((i) => i.static === false)
      .map((b) => ({
        key: b.id,
        label: b.label
      })),
    byron: (ByronSettings as any).groups
      .filter((i) => i.static === false)
      .map((b) => ({
        key: b.id,
        label: b.label
      }))
  };

  return blockTypes[key];
}
