import React from 'react';
import Box from '@rexlabs/box';
import { PrimaryButton } from '@rexlabs/button';
import { Field } from '@rexlabs/form';
import { Dropdown } from '@rexlabs/select';
import { withQuery } from '@rexlabs/model-generator';
import { websiteQuery } from 'data/queries/websites';
import { compose } from 'redux';
import { TextInput } from '@rexlabs/text-input';
import { SettingsWrapper } from 'view/forms/edit-header-settings';
import { Checkbox } from '@rexlabs/checkbox';
import { Body } from '@rexlabs/text';

interface Props {
  submitForm: (e: any) => void;
  isSubmitting: boolean;
  menu: { name: string; id: string }[];
  headerColorVariants: { label: string; value: string }[];
  logoVariants: { label: string; value: string }[];
}

const VersatileHeaderSettings = (props: Props) => {
  const {
    submitForm,
    isSubmitting,
    menu,
    headerColorVariants,
    logoVariants
  } = props;
  return (
    <SettingsWrapper>
      <Box
        flex={1}
        flexDirection='row'
        alignItems='center'
        marginBottom={'10px'}
      >
        <h1
          style={{
            fontSize: '28px',
            marginRight: '20px',
            fontWeight: 700
          }}
        >
          Header Settings
        </h1>
        <PrimaryButton
          onClick={(e) => {
            window.analytics?.track?.('siteloft.design', {
              action: 'updated header behaviour',
              location: 'Header behaviour'
            });
            submitForm(e);
          }}
          isLoading={isSubmitting}
        >
          Save
        </PrimaryButton>
      </Box>

      <Box flexDirection='column' flexWrap='wrap' marginBottom={'100px'}>
        <Box padding='10px' flex={1}>
          <Field
            label='Select Header Menu'
            name='header_menu'
            Input={Dropdown}
            inputProps={{
              items: menu.map((item) => ({
                label: item.name,
                value: item.id
              }))
            }}
            HelpTooltipContent={() =>
              'Select the menu you want to display in the header.'
            }
            optional={false}
          />
        </Box>

        <Box padding='10px' flex={1}>
          <Field
            name='header_phone'
            label='Contact Phone Number'
            Input={TextInput}
            optional={true}
          />
        </Box>

        <Box padding='10px' flex={1} data-testId={'header-contact-link-field'}>
          <Field
            label='Contact Button Link'
            name='versatile_header_contact_link'
            Input={TextInput}
            HelpTooltipContent={() =>
              'Provide the path to your contact page eg. /contact-us'
            }
          />
        </Box>

        <Body bold>Default Header</Body>
        <Box padding='10px' flex={1}>
          <Field
            label='Default Header Logo'
            name='default_header_logo'
            Input={Dropdown}
            optional={false}
            inputProps={{ items: logoVariants }}
            HelpTooltipContent={() =>
              'Select the logo you want to display in the header.'
            }
          />
        </Box>

        <Box padding={10} flex={1} data-testId={'header-background-color'}>
          <Field
            label='Header Background Colour'
            name='header_background_color'
            optional={false}
            Input={Dropdown}
            inputProps={{
              items: headerColorVariants
            }}
          />
        </Box>

        <Box padding={10} flex={1} data-testId={'header-font-color'}>
          <Field
            label='Header Font Colour'
            name='header_font_color'
            optional={false}
            Input={Dropdown}
            inputProps={{
              items: headerColorVariants
            }}
          />
        </Box>

        <Body bold>Transparent Header</Body>
        <Box padding='10px' flex={1} data-testId={'disable-transparent-header'}>
          <Field
            label='Disable Transparent Header'
            name='disable_transparent_header'
            Input={Checkbox}
            optional={false}
            HelpTooltipContent={() =>
              'Disable the transparent header that animates to a background colour upon scrolling.'
            }
          />
        </Box>

        <Box padding='10px' flex={1}>
          <Field
            label='Transparent Header Logo'
            name='transparent_header_logo'
            Input={Dropdown}
            optional={false}
            inputProps={{ items: logoVariants }}
            HelpTooltipContent={() =>
              'Select the logo you want to display when using the transparent header.'
            }
          />
        </Box>

        <Box
          padding={10}
          flex={1}
          data-testId={'transparent-header-font-color'}
        >
          <Field
            label='Transparent Header Font Colour'
            name='transparent_header_font_color'
            optional={false}
            Input={Dropdown}
            inputProps={{
              items: headerColorVariants
            }}
          />
        </Box>

        <Body bold>Mobile Menu</Body>
        <Box padding={10} flex={1} data-testId={'mobile-menu-background-color'}>
          <Field
            label='Mobile Menu Background Colour'
            name='mobile_menu_background_color'
            optional={false}
            Input={Dropdown}
            inputProps={{
              items: headerColorVariants
            }}
          />
        </Box>

        <Box padding={10} flex={1} data-testId={'mobile-menu-font-color'}>
          <Field
            label='Mobile Menu Font Colour'
            name='mobile_menu_font_color'
            optional={false}
            Input={Dropdown}
            inputProps={{
              items: headerColorVariants
            }}
          />
        </Box>
      </Box>
    </SettingsWrapper>
  );
};

export default compose(withQuery(websiteQuery))(VersatileHeaderSettings);
