import React, { useCallback, useState } from 'react';
import { compose } from 'lodash/fp';
import { Dialog } from '@rexlabs/dialog';
import { ReactForms } from '@rexlabs/form';
import { createValidationRules } from '@rexlabs/validator';
import { popupsQuery } from 'data/queries/popups';
import { withQuery } from '@rexlabs/model-generator';
import { pagesQuery } from 'data/queries/pages';
import { formsQuery } from 'data/queries/forms';
import EditPopupForm from './edit-form';

const validate = createValidationRules({
  name: 'required',
  title: 'required'
});

function EditPopupsDialog({
  popups,
  match,
  closeModal,
  onClose,
  pages,
  forms,
  ...props
}) {
  const [triggers, setTriggers] = useState([]);

  const onSubmit = useCallback(
    (values) => {
      return popups
        .createItem({
          data: {
            websiteId: match?.params?.websiteId,
            type: values?.type?.value ?? null,
            position: values?.position?.value ?? null,
            name: values?.name ?? null,
            title: values?.title ?? null,
            content: values?.content ?? null,
            form_id: values?.form?.value ?? null,
            css_class: values?.css ?? null,
            size: values?.size?.value ?? null,
            animation: values?.animation?.value ?? null,
            triggers: triggers ?? []
          }
        })
        .then(() => {
          popups.refreshList();
          onClose();
        })
        .catch((e) => {
          console.error(e);
        });
    },
    [popups, match, triggers, onClose]
  );

  const handleAddTrigger = (values) => {
    const newTrigger = {
      type: values?.triggerType?.value,
      form_id: values?.triggerForm?.value,
      trigger_class: values?.triggerClass,
      page_id: values?.triggerPage?.value,
      delay: values?.triggerDelay
    };

    setTriggers([...triggers, newTrigger]);
  };

  return (
    <Dialog title={'Create New Popup'} width='80%' onClose={onClose} {...props}>
      <ReactForms
        validate={validate}
        handleSubmit={onSubmit}
        validateOnBlur={false}
      >
        {({ submitForm, isSubmitting, values }) => (
          <EditPopupForm
            submitForm={submitForm}
            isSubmitting={isSubmitting}
            values={values}
            pages={pages}
            forms={forms}
            triggers={triggers}
            handleAddTrigger={handleAddTrigger}
            closeModal={closeModal}
          />
        )}
      </ReactForms>
    </Dialog>
  );
}

export default compose(
  withQuery(pagesQuery),
  withQuery(formsQuery),
  withQuery(popupsQuery)
)(EditPopupsDialog);
