import React, { ReactNode, useMemo } from 'react';
import Box from '@rexlabs/box';
import { Form } from '@rexlabs/form';
import { getThemeQuery } from 'data/queries/themes';
import { useEntityQuery } from '@rexlabs/model-generator';
import { RenderLoading } from 'view/components/render-loading';
import ClassicHeaderSettings from 'view/forms/personas/classic/ClassicHeaderSettings';
import VersatileHeaderSettings from 'view/forms/personas/versatile/VersatileHeaderSettings';
import MinimalHeaderSettings from 'view/forms/personas/minimal/MinimalHeaderSettings';

const HEADER_BEHAVIOUR_OPTIONS = [
  { label: 'Hide & Seek', value: 'hide_and_seek' },
  { label: 'Full Stick', value: 'full_stick' }
];

const BLOG_FEATURE_SIZES = [
  { label: 'Cover', value: 'cover' },
  { label: 'Contain', value: 'contain' }
];

const HEADER_COLOR_VARIANTS = {
  'primary-500': {
    label: 'Primary',
    value: { color: 'primary-500', hover: 'primary-hover' }
  },
  'primary-600': {
    label: 'Primary Light',
    value: { color: 'primary-600', hover: 'primary-500' }
  },
  'primary-400': {
    label: 'Primary Dark',
    value: { color: 'primary-400', hover: 'primary-500' }
  },
  'secondary-500': {
    label: 'Secondary',
    value: { color: 'secondary-500', hover: 'secondary-hover' }
  },
  'secondary-600': {
    label: 'Secondary Light',
    value: { color: 'secondary-600', hover: 'secondary-500' }
  },
  'secondary-400': {
    label: 'Secondary Dark',
    value: { color: 'secondary-400', hover: 'secondary-500' }
  },
  white: {
    label: 'White',
    value: { color: 'white', hover: 'light-hover' }
  },
  dark: {
    label: 'Black',
    value: { color: 'dark', hover: 'dark-hover' }
  }
};

const HEADER_LOGO_VARIANTS = [
  { label: 'Primary Logo', value: 'desktop_logo' },
  { label: 'Secondary Logo', value: 'secondary_logo' }
];

export const mapPropsToValues = (props) => {
  const { settings, menus } = props;
  const settingsData = settings.list.items;

  const data = settingsData.reduce((acc, object) => {
    if (
      ![
        'header_menu',
        'menu_background_color',
        'header_accent_color',
        'menu_font_color',
        'menu_font_hover_color',
        'menu_dropdown_background_color',
        'menu_dropdown_font_color',
        'menu_dropdown_font_hover_color',
        'show_contact_details',
        'contact_details_font_color',
        'header_behaviour',
        'blog_feature_size',
        'transparent_header',
        'disable_transparent_header',
        'boxed_logo',
        'transparent_header_hide_logo',
        'transparent_header_font_color',
        'menu_item_hover_color',
        'versatile_header_contact_link',
        'header_background_color',
        'header_font_color',
        'mobile_menu_background_color',
        'mobile_menu_font_color',
        'default_header_logo',
        'transparent_header_logo',
        'header_phone',
        'header_email',
        'header_address',
        'homepage_font_color',
        'menu_overlay_font_color',
        'main_menu_style'
      ].includes(object.key)
    )
      return acc;

    const value = object?.value ? object.value[0] : null;

    if (object.key === 'header_behaviour') {
      acc[object.key] = HEADER_BEHAVIOUR_OPTIONS.find((o) => o.value === value);
      return acc;
    }

    if (object.key === 'header_menu') {
      const menuList = menus?.list?.items.map((item) => {
        return { label: item.name, value: item.id };
      });

      acc[object.key] = menuList?.find((i) => i.value === value?.id);
      return acc;
    }

    if (object.key === 'blog_feature_size') {
      switch (value) {
        case 'contain':
          acc[object.key] = BLOG_FEATURE_SIZES[1];
          return acc;
        case 'cover':
        default:
          acc[object.key] = BLOG_FEATURE_SIZES[0];
          return acc;
      }
    }

    if (
      object.key === 'default_header_logo' ||
      object.key === 'transparent_header_logo'
    ) {
      switch (value) {
        case 'secondary_logo':
          acc[object.key] = HEADER_LOGO_VARIANTS[1];
          return acc;
        case 'desktop_logo':
        default:
          acc[object.key] = HEADER_LOGO_VARIANTS[0];
          return acc;
      }
    }

    if (
      object?.value[0] &&
      [
        'transparent_header_font_color',
        'header_background_color',
        'header_font_color',
        'mobile_menu_background_color',
        'mobile_menu_font_color',
        'homepage_font_color',
        'menu_overlay_font_color',
        'main_menu_style'
      ].includes(object.key)
    ) {
      const fontColor = HEADER_COLOR_VARIANTS[object.value[0].color];
      if (fontColor) {
        acc[object.key] = {
          label: fontColor?.label,
          value: fontColor?.value
        };
        return acc;
      }
    }

    acc[object.key] = value;
    return acc;
  }, {});

  // Set default values
  if (!data?.blog_feature_size) {
    data.blog_feature_size = BLOG_FEATURE_SIZES[0];
  }

  return data;
};

export const SettingsWrapper = ({ children }: { children: ReactNode }) => (
  <Box p={24}>
    <Form name='headerForm'>{children}</Form>
  </Box>
);

function EditHeaderSettings({ isSubmitting, submitForm, menus, themeId }) {
  const themeQuery = useMemo(() => {
    if (themeId) return getThemeQuery(themeId);
  }, [themeId]);

  const { status: themeStatus, data: themeData } = useEntityQuery(themeQuery);

  return (
    <RenderLoading isLoading={themeStatus === 'loading'}>
      {themeData?.key === 'minimal' ? (
        <MinimalHeaderSettings
          submitForm={submitForm}
          isSubmitting={isSubmitting}
          menu={menus.list.items}
          headerColorVariants={Object.values(HEADER_COLOR_VARIANTS)}
        />
      ) : null}
      {themeData?.key === 'versatile' ? (
        <VersatileHeaderSettings
          submitForm={submitForm}
          isSubmitting={isSubmitting}
          menu={menus.list.items}
          headerColorVariants={Object.values(HEADER_COLOR_VARIANTS)}
          logoVariants={Object.values(HEADER_LOGO_VARIANTS)}
        />
      ) : null}
      {themeData?.key === 'byron' ? (
        <ClassicHeaderSettings
          isSubmitting={isSubmitting}
          submitForm={submitForm}
          menus={menus}
        />
      ) : null}
    </RenderLoading>
  );
}
export default EditHeaderSettings;
