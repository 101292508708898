import React, { useMemo, useState } from 'react';
import Box from '@rexlabs/box';
import {
  TableProvider,
  Table,
  SimplePagination,
  TextCell,
  useTableQuery
} from '@rexlabs/table';
import { Link } from '@rexlabs/react-whereabouts';
import { Heading, Body } from '@rexlabs/text';
import {
  ButtonGroup,
  PrimaryButton,
  DestructiveButton,
  SecondaryButton
} from '@rexlabs/button';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'src/routes/app';
import Screen from 'view/components/screen';
import { usersQuery } from 'data/queries/users';
import usersModel from 'data/models/entities/users';
import { withModel } from '@rexlabs/model-generator';
import { Dialog } from '@rexlabs/dialog';

function UsersScreen({ match, users }) {
  const websiteId = match?.params?.websiteId;
  const [deleteUserModal, setDeleteUserModal] = useState();
  const tableQuery = useMemo(() => {
    usersQuery.args.websiteId = websiteId;
    return usersQuery;
  }, [websiteId]);

  const { getTableProps: getTableQueryProps } = useTableQuery({
    getQuery: () => tableQuery
  });

  const deleteUser = () => {
    if (!deleteUserModal) return;
    // TODO: Need to investigate signature of trashItem method on users model.
    return users
      .trashItem({
        id: deleteUserModal?.id,
        args: {
          id: deleteUserModal?.id,
          websiteId: websiteId
        }
      })
      .then(() => setDeleteUserModal(null));
  };

  const getActionMenuItems = ({ item }) => {
    return [
      {
        label: 'Edit',
        onClick: () =>
          push(ROUTES.ADMIN_USERS.EDIT, {
            params: {
              websiteId: match?.params?.websiteId,
              userId: item?.id
            }
          })
      },
      {
        label: 'Delete',
        onClick: () => {
          setDeleteUserModal(item);
        }
      }
    ];
  };

  const columns = [
    {
      id: 'name',
      width: 'auto',
      Header: 'Name',
      Cell: TextCell
    },
    {
      id: 'email',
      width: 'auto',
      Header: 'Email',
      Cell: TextCell
    }
  ];

  return (
    <Screen>
      <Heading>Users</Heading>
      <Body grey>Create/edit users</Body>
      <Box
        justifyContent='space-between'
        alignItems='center'
        pt={'1.6rem'}
        pb={'1.6rem'}
      >
        <Link to={ROUTES.ADMIN_USERS.CREATE} params={{ websiteId }}>
          {({ onClick }) => (
            <PrimaryButton onClick={onClick}>Create New</PrimaryButton>
          )}
        </Link>
      </Box>
      <TableProvider
        columns={columns}
        {...getTableQueryProps()}
        getActionMenuItems={getActionMenuItems}
      >
        <Table />
        <SimplePagination />
      </TableProvider>
      {!!deleteUserModal && (
        <Dialog
          title={'Are you sure you want to delete this user?'}
          onClose={() => setDeleteUserModal(null)}
        >
          <Box justifyContent={'center'}>
            <ButtonGroup>
              <SecondaryButton onClick={() => setDeleteUserModal(null)}>
                Cancel
              </SecondaryButton>
              <DestructiveButton onClick={() => deleteUser()}>
                Delete
              </DestructiveButton>
            </ButtonGroup>
          </Box>
        </Dialog>
      )}
    </Screen>
  );
}

export default withModel(usersModel, 'users')(UsersScreen);
