import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { ReactForms, Form, Field } from '@rexlabs/form';
import { RenderLoading } from 'view/components/render-loading';
import { withQuery, query } from '@rexlabs/model-generator';
import { autobind } from 'core-decorators';
import settings from 'data/models/entities/settings';
import { handleSettingsSubmit } from 'utils/handle-settings-submit';
import { PrimaryButton } from '@rexlabs/button';
import { withToast } from 'view/components/toast';
import { Checkbox } from '@rexlabs/checkbox';
import { TextArea, TextInput } from '@rexlabs/text-input';
import { Heading } from '@rexlabs/text';

function getWebsiteId(props) {
  return props?.match?.params?.websiteId;
}

const settingQuery = query`{
  ${settings} (websiteId: ${getWebsiteId}, category: feeds) {
    key
    value
    label
    category
  }
}`;

const mapProps = (props) => {
  const { settings } = props;
  const settingsData = settings?.list?.items;

  const data = Object.assign(
    {},
    ...settingsData.map((object) => {
      return { [object.key]: object?.value ? object?.value[0] : null };
    })
  );

  return data;
};

const feedSettings = {
  name: 'feedSettings',
  mapPropsToValues: mapProps,
  handleSubmit: handleSettingsSubmit,
  asyncValuesReady: (props) => {
    return props.settings.list.status === 'loaded';
  }
};

@withToast
@withQuery(settingQuery)
@autobind
class FeedSettings extends PureComponent {
  render() {
    return (
      <Box
        flex={1}
        style={{ padding: '20px 20px 100px 20px', overflow: 'auto' }}
      >
        <RenderLoading isLoading={this.props.settings.list.status !== 'loaded'}>
          <ReactForms
            initialValues={feedSettings.mapPropsToValues(this.props)}
            handleSubmit={(values) =>
              feedSettings.handleSubmit(values, { props: this.props })
            }
          >
            {({ submitForm, isSubmitting }) => (
              <Form name='feedSettings' style={{ width: '100%' }}>
                <Box
                  flex={1}
                  flexDirection={'row'}
                  padding='10px'
                  alignItems={'center'}
                >
                  <h1
                    style={{
                      fontSize: '28px',
                      fontWeight: '700',
                      marginRight: '20px'
                    }}
                  >
                    {' '}
                    Feeds{' '}
                  </h1>

                  <PrimaryButton onClick={submitForm} isLoading={isSubmitting}>
                    Save
                  </PrimaryButton>
                </Box>

                <Heading
                  style={{
                    marginTop: '40px',
                    marginBottom: '20px',
                    marginLeft: '10px'
                  }}
                >
                  Blog Posts
                </Heading>

                <Box padding='10px' flex={1}>
                  <Field
                    name='enable_blog_rss_feed'
                    label='Enable Blog Posts RSS Feed'
                    optional={false}
                    Input={Checkbox}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='blog_rss_feed_title'
                    label='Custom feed title (defaults to website name)'
                    Input={TextInput}
                    optional={false}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='blog_rss_feed_description'
                    label='Custom feed description (defaults to website tagline)'
                    Input={TextArea}
                    optional={false}
                  />
                </Box>
              </Form>
            )}
          </ReactForms>
        </RenderLoading>
      </Box>
    );
  }
}

export default FeedSettings;
