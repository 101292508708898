import React from 'react';
import { RenderMatchedRoutes } from '@rexlabs/react-whereabouts';
import Box from '@rexlabs/box';
import { StyleSheet, styled } from '@rexlabs/styling';

import ROUTES from 'src/routes/auth';
import { PADDINGS } from 'src/theme';
import LoginScreen from 'view/screens/auth/login';

import loginBackground from 'assets/login_background.png';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    height: '100%',
    background: ({ token }) => token('color.red.default')
  },

  content: {
    height: '100%',
    padding: ` 90px ${PADDINGS.S}`,
    width: '480px',
    background: ({ token }) => token('color.white.default')
  },

  background: {
    height: '100%',
    width: 'calc(100% - 480px)',
    backgroundColor: 'blue',
    padding: '0',
    margin: '0',
    background: `url("${loginBackground}") no-repeat`,
    backgroundSize: 'cover'
  },

  wrapScreen: {
    position: 'relative',
    width: '100%',
    maxWidth: '320px',
    height: '100%',
    padding: 0,
    background: ({ token }) => token('color.white.default'),
    boxShadow: ({ token }) => token('shadows.drop.light')
  },

  footer: {
    width: '100%',
    height: '20px',
    padding: 0,
    position: 'absolute',
    bottom: 'min(0px, calc(100% - 500px))', // Stick to bottom while keeping padding with above element
    background: ({ token }) => token('color.white.default'),
    boxShadow: ({ token }) => token('shadows.drop.light')
  }
});

function AuthLayout({ styles: s }) {
  return (
    <Box
      {...s('container')}
      alignItems='left'
      justifyContent='left'
      flexDirection='row'
    >
      <Box
        {...s('content')}
        alignItems='center'
        justifyContent='center'
        flexDirection='column'
      >
        <Box {...s('wrapScreen')}>
          <RenderMatchedRoutes routes={ROUTES} NotFound={LoginScreen} />
          <Box
            {...s('footer')}
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
          >
            <a href='https://www.rexsoftware.com/contact/websites-sales-enquiry'>
              Don&apos;t have an account?
            </a>
            <a
              href='https://support.siteloft.com/hc/en-us'
              target='_blank'
              rel='noreferrer noopener'
            >
              Need help?
            </a>
          </Box>
        </Box>
      </Box>
      <Box {...s('background')} flexDirection='column' />
    </Box>
  );
}

export default styled(defaultStyles)(AuthLayout);
