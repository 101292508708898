/* eslint-disable max-lines */
import React, { useState, useEffect, useMemo } from 'react';
import { compose } from 'redux';
import { partition, orderBy } from 'lodash/fp';
import Box from '@rexlabs/box';
import { useEntityQuery, withQuery } from '@rexlabs/model-generator';
import { DraggableProvider, Droppable, Draggable } from '@rexlabs/draggable';
import { Heading, Body } from '@rexlabs/text';
import { styled, StyleSheet, useToken } from '@rexlabs/styling';
import { blocksQuery } from 'data/queries/blocks';
import BlockItem from './block-list-item';
import { useToast } from 'view/components/toast';
import { RenderLoading } from 'view/components/render-loading';
import { PrimaryButton } from '@rexlabs/button';
import { websiteQuery } from 'data/queries/websites';
import { getThemeQuery } from 'data/queries/themes';
import { getPersonaBlockTypes } from 'view/components/theme-settings/helpers';
import { CreateBlockDialog } from 'view/components/theme-settings/CreateBlockDialog';

interface BlockProps {
  match?: any;
  blocks?: any;
  handleClick: (any) => any;
  styles: any;
  updatePreviewProps: () => void;
  websites: {
    item: {
      data: {
        theme_id: string;
      };
    };
  };
  blockListShouldRefresh: boolean;
}

export type BlockItemProps = {
  id: string;
  label: string;
  key: string;
  priority: number;
  enabled: boolean;
  static: boolean;
};

const getItemStyle = (draggableStyle) => {
  const { transform } = draggableStyle;

  // Lock the x axis if moving.
  const activeTransform = transform
    ? {
        transform: `translate(0, ${transform.substring(
          transform.indexOf(',') + 1,
          transform.indexOf(')')
        )})`
      }
    : {};

  return {
    // Some basic styles
    userSelect: 'none',
    margin: '8px 0',
    borderRadius: 4,
    // Styles we need to apply on draggables
    ...draggableStyle,
    ...activeTransform,
    left: 'inherit',
    top: 'inherit'
  };
};

const defaultStyles = StyleSheet({
  locked: {
    margin: '8px 0',
    borderRadius: 4
  },
  titleContainer: {
    position: 'sticky',
    top: 0,
    background: '#FFFFFF',
    padding: '24px 24px 12px 24px',
    zIndex: 1,
    width: '100%'
  },
  blockContainer: {
    padding: '12px 24px 24px 24px'
  },
  createBlockDialogContainer: {
    padding: '24px'
  }
});

function BlockList({
  styles: s,
  handleClick,
  blocks,
  match,
  websites,
  updatePreviewProps,
  blockListShouldRefresh
}: BlockProps) {
  const [list, setList] = useState<BlockItemProps[]>([]);
  const [staticList, setStaticList] = useState<BlockItemProps[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const token = useToken();
  const { addToast } = useToast();
  const { websiteId } = match?.params;
  const { theme_id: themeId } = websites.item.data;

  const themeQuery = useMemo(() => {
    if (themeId) return getThemeQuery(themeId);
  }, [themeId]);

  const { status: themeStatus, data: themeData } = useEntityQuery(themeQuery);

  const refreshBlocks = () => blocks.fetchList().then(() => setLoaded(false));

  useEffect(() => {
    refreshBlocks();
  }, [blockListShouldRefresh]);

  useEffect(() => {
    if (!loaded && blocks.list.status === 'loaded') {
      const [staticItems, orderableItems] = compose(
        partition((item: BlockItemProps) => item.static),
        orderBy('priority')('desc')
      )(blocks.list.items.filter((i) => i.label !== 'Footer'));

      setList(orderableItems);
      setStaticList(staticItems);
      setLoaded(true);
    }
  }, [loaded, blocks]);

  /**
   * Reorder array based on what index the item was dropped at
   */
  const reorder = (
    list: BlockItemProps[],
    startIndex: number,
    endIndex: number
  ): BlockItemProps[] => {
    const result: BlockItemProps[] = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = async (result) => {
    // Do nothing if it hasn't moved
    if (!result?.destination) {
      return;
    }

    // Reorder the array
    const items = reorder(list, result.source.index, result.destination.index);

    // Set the priority based on it's index
    // Reverse so top element has highest priority
    const final = items?.reverse()?.map((item, index) => ({
      ...item,
      priority: index
    }));

    setList(final.reverse());

    try {
      // Commented out due to bulkUpdateItems erroring with a possible bug in
      // vivid model generator

      // const data = await settingGroups.bulkUpdateItems({
      //   items: final.map((i) => ({ id: i.id, priority: i.priority })),
      //   args: {
      //     websiteId: match?.params?.websiteId,
      //     items: final.map((i) => ({ id: i.id, priority: i.priority }))
      //   }
      // });

      addToast({
        type: 'success',
        title: 'Saving page order',
        content: 'The website preview will update shortly'
      });

      const finalItems = final.map((i) => ({ id: i.id, priority: i.priority }));

      await Promise.all(
        finalItems.map(async (item) => {
          await blocks.updateItem({
            id: item.id,
            data: item,
            args: {
              websiteId: match.params?.websiteId
            }
          });
        })
      );

      updatePreviewProps();
    } catch (e) {
      console.error(e);
    }
  };

  const handleToggleClick = async (block) => {
    try {
      await blocks.updateItem({
        id: block.id,
        data: {
          websiteId: match?.params?.websiteId,
          enabled: !block.enabled
        }
      });

      updatePreviewProps();
      setLoaded(false);
      blocks.refreshList({
        id: 'refresh-blocks'
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleBlockCreated = () => {
    addToast({
      type: 'success',
      title: 'Block Created Successfully',
      content: 'The website preview will update shortly'
    });
    refreshBlocks();
    updatePreviewProps();
  };

  return (
    <RenderLoading
      isLoading={blocks.list?.status === 'loading' || themeStatus === 'loading'}
    >
      <Box {...s('titleContainer')}>
        <Box
          flexDirection={'row'}
          alignItems='center'
          justifyContent={'space-between'}
        >
          <Heading>Homepage</Heading>
        </Box>
        <Body>Customise your homepage content.</Body>

        <hr />

        {['byron', 'versatile'].includes(themeData?.key) ? (
          <PrimaryButton
            id={'add-block-button'}
            style={{ width: '100%', marginTop: 8 }}
            isLoading={themeStatus === 'loading'}
            onClick={() => setOpenDialog(true)}
          >
            {'Add Block'}
          </PrimaryButton>
        ) : null}

        {openDialog && (
          <CreateBlockDialog
            close={() => setOpenDialog(false)}
            blockList={
              themeData?.key ? getPersonaBlockTypes(themeData.key) : []
            }
            websiteId={websiteId}
            onSuccess={handleBlockCreated}
          />
        )}
      </Box>

      <Box {...s('blockContainer')}>
        {staticList
          // Non draggable items
          .map((block) => (
            <Box key={block.id} {...s('locked')}>
              <BlockItem
                item={block}
                handleEditClick={() => handleClick(block.id)}
                handleToggleClick={() =>
                  block.enabled !== null && handleToggleClick(block)
                }
              />
            </Box>
          ))}

        <DraggableProvider onDragEnd={onDragEnd}>
          <Droppable droppableId={'block-droppable'}>
            {(provided) => (
              <Box ref={provided.innerRef} {...provided.droppableProps}>
                {list
                  // Draggable items
                  .map((block, i) => (
                    <Draggable
                      index={i}
                      key={`${block.id}-draggable`}
                      draggableId={block.id}
                    >
                      {(provided, snapshot) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle({
                            ...provided.draggableProps.style,
                            background: snapshot.isDragging
                              ? token('navigation.item.hover.background.color')
                              : 'none'
                          })}
                        >
                          <BlockItem
                            item={block}
                            handleEditClick={() => handleClick(block.id)}
                            handleToggleClick={() => handleToggleClick(block)}
                          />
                        </Box>
                      )}
                    </Draggable>
                  ))}
              </Box>
            )}
          </Droppable>
        </DraggableProvider>
      </Box>
    </RenderLoading>
  );
}

// TODO Remove <any, any...> hint to compose once the passed functions have better typing
export default compose<any>(
  styled(defaultStyles),
  withQuery(blocksQuery),
  withQuery(websiteQuery)
)(BlockList);
