import React from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, styled } from '@rexlabs/styling';
import { ErrorBoundary } from '@rexlabs/error-boundary';

const defaultStyles = StyleSheet({
  wrapScreen: {
    width: '100%',
    padding: '5rem',
    overflow: 'scroll'
  }
});

function Screen({ styles: s, ...props }) {
  return (
    <ErrorBoundary>
      <Box {...s('wrapScreen')} flexDirection='column' {...props} />
    </ErrorBoundary>
  );
}

export default styled(defaultStyles)(Screen);
