import React from 'react';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import CodeInput from '@rexlabs/code-input';

function JupixSyncFields() {
  return (
    <>
      <Field
        label='Client ID'
        name='clientID'
        Input={TextInput}
        optional={false}
      />
      <Field
        label='Passphrase'
        name='passphrase'
        Input={TextInput}
        inputProps={{
          type: 'password'
        }}
        optional={false}
      />
      <Field label='Filter' name='filter' Input={CodeInput} />
    </>
  );
}

export default JupixSyncFields;
