import React, { useState } from 'react';
import Box from '@rexlabs/box';
import { Dropdown } from '@rexlabs/select';
import { TextInput } from '@rexlabs/text-input';
import { PrimaryButton } from '@rexlabs/button';
import { Dialog } from '@rexlabs/dialog';
import { ReactForms, Form, Field } from '@rexlabs/form';
import List from '@rexlabs/list';
import { createValidationRules } from '@rexlabs/validator';

const validate = createValidationRules({
  triggerType: 'required',
  triggerForm: 'required_if:triggerType.value,form',
  triggerClass: 'required_if:triggerType.value,button',
  triggerPage: 'required_if:triggerType.value,page',
  triggerDelay: 'digits_between:0,60000'
});

const TriggerItem = (item) => {
  const value =
    item.type === 'form'
      ? item.form_id
      : item.type === 'button'
      ? item.trigger_class
      : item.type === 'page'
      ? item.page_id
      : null;
  return (
    <Box flexDirection={'row'}>
      <Box p={5} flex={1}>
        {item?.type ?? '-'}
      </Box>
      <Box p={5} flex={1}>
        {value ?? '-'}
      </Box>
      <Box p={5} flex={1}>
        {item?.delay ?? '-'}
      </Box>
    </Box>
  );
};

const TriggerHeader = () => (
  <Box flexDirection={'row'}>
    <Box p={5} flex={1}>
      Type
    </Box>
    <Box p={5} flex={1}>
      Value
    </Box>
    <Box p={5} flex={1}>
      Delay
    </Box>
  </Box>
);

function PopupTriggers({ forms, pages, triggers, handleAddTrigger }) {
  const [show, setShow] = useState(false);

  const pageList = pages?.list?.items?.map?.((val) => ({
    value: val?.id,
    label: val?.title
  }));

  const formList = forms?.list?.items?.map?.((form) => ({
    value: form?.id,
    label: form?.definition?.name
  }));

  const onTriggerSubmit = (values) => {
    handleAddTrigger(values);
    setShow(false);
  };

  return (
    <>
      <Box padding={'10px'} flex={1}>
        <PrimaryButton onClick={() => setShow(true)}>Add Trigger</PrimaryButton>
      </Box>

      <Box p={10}>
        <List
          items={triggers}
          renderItem={TriggerItem}
          Header={TriggerHeader}
        />
      </Box>

      <Field
        name={'xyz'}
        Input={() => <input type={'hidden'} value={triggers} />}
      />

      {show && (
        <Dialog title={'Add New Trigger'} onClose={() => setShow(false)}>
          <ReactForms validate={validate} handleSubmit={onTriggerSubmit}>
            {({ submitForm, values }) => (
              <Form>
                <>
                  <Box p={10}>
                    <Field
                      fullWidth
                      label={'Trigger Type'}
                      name={'triggerType'}
                      Input={Dropdown}
                      inputProps={{
                        items: [
                          { value: 'form', label: 'Form' },
                          { value: 'button', label: 'Button' },
                          { value: 'page', label: 'Page' }
                        ]
                      }}
                    />
                  </Box>

                  {values?.triggerType?.value === 'form' && (
                    <Box p={10}>
                      <Field
                        fullWidth
                        label='Form'
                        name='triggerForm'
                        Input={Dropdown}
                        inputProps={{
                          items: formList
                        }}
                      />
                    </Box>
                  )}

                  {values?.triggerType?.value === 'button' && (
                    <Box p={10}>
                      <Field
                        fullWidth
                        label='CSS Selector'
                        name='triggerClass'
                        Input={TextInput}
                      />
                    </Box>
                  )}

                  {values?.triggerType?.value === 'page' && (
                    <Box p={10}>
                      <Field
                        fullWidth
                        label='Page'
                        name='triggerPage'
                        Input={Dropdown}
                        inputProps={{
                          items: pageList
                        }}
                      />
                    </Box>
                  )}

                  {values?.triggerType?.value && (
                    <Box p={10}>
                      <Field
                        fullWidth
                        label='Delay (ms)'
                        name='triggerDelay'
                        Input={TextInput}
                      />
                    </Box>
                  )}

                  <Box p={10}>
                    <PrimaryButton onClick={submitForm}>Add</PrimaryButton>
                  </Box>
                </>
              </Form>
            )}
          </ReactForms>
        </Dialog>
      )}
    </>
  );
}

export default PopupTriggers;
