import { InputProps } from 'view/components/theme-settings/theme-setting-input';
import { Dropdown } from '@rexlabs/select';

type BrandVariantOption = { [key: string]: { value: string; label: string } };

export const BRAND_COLOR_VARIANT_OPTIONS: BrandVariantOption = {
  primary: {
    label: 'Primary',
    value: 'primary'
  },
  'primary-light': {
    label: 'Primary Light',
    value: 'primary-light'
  },
  'primary-dark': {
    label: 'Primary Dark',
    value: 'primary-dark'
  },
  secondary: {
    label: 'Secondary',
    value: 'secondary'
  },
  'secondary-light': {
    label: 'Secondary Light',
    value: 'secondary-light'
  },
  'secondary-dark': {
    label: 'Secondary Dark',
    value: 'secondary-dark'
  },
  white: {
    label: 'White',
    value: 'white'
  },
  black: {
    label: 'Black',
    value: 'black'
  }
};

export function getBrandColorVariantInput(): InputProps {
  return {
    input: Dropdown,
    inputProps: {
      items: Object.values(BRAND_COLOR_VARIANT_OPTIONS)
    }
  };
}
