import { withModel } from '@rexlabs/model-generator';
import { withWhereabouts } from '@rexlabs/react-whereabouts';
import { push } from '@rexlabs/whereabouts';
import { compose } from 'lodash/fp';
import React, { forwardRef } from 'react';
import Avatar from '@rexlabs/avatar';
import ActionMenu from '@rexlabs/action-menu';
import sessionsModel from 'data/models/custom/session';
import ROUTES from 'src/routes/app';
import omit from 'lodash/omit';

function HeaderAvatar({ session }) {
  const fullName = session.user.first_name + ' ' + session.user.last_name;

  const items = [
    {
      label: fullName,
      useAlternateBackground: true
    },
    // {
    //   label: 'Billing', // TODO billing will be added later
    //   onClick: () => console.log('billing button clicked')
    // },
    {
      label: 'Logout',
      onClick: () => {
        push(ROUTES.LOGOUT);
      }
    }
  ];

  return (
    <ActionMenu
      items={items}
      Button={forwardRef((props, ref) => (
        <Avatar {...omit(props, 'Icon')} ref={ref} name={fullName} />
      ))}
    />
  );
}

export default compose(withWhereabouts, withModel(sessionsModel))(HeaderAvatar);
