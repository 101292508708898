/*
|-------------------------------------------------------------------------------
| App Setup
|-------------------------------------------------------------------------------
|
| Connects the DOM to our App. Exposes core App data at DEV time.
|
| It also should be the only file that is importing the store - all other usages
| of store are through react connectors or middleware.
|
*/

import 'core-js';
import 'utils/globals';
import config from 'config';

import React, { useEffect, Fragment } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { StylesProvider, insertCss } from '@rexlabs/styling';
import { createTokenFn } from '@rexlabs/element-styles';
import { TextProvider } from '@rexlabs/text';
import { DialogProvider, DialogTarget } from '@rexlabs/dialog';
import { ToastTarget, ToastProvider } from 'view/components/toast';

import { store, persistor } from 'src/store';
import { api } from 'utils/api-client';
import AdminApp from 'view/app';
import LoadingLayout from 'view/layouts/loading';
import { Segment, initSprig } from 'view/components/analytics';
import { DevicePreviewProvider } from 'utils/device-preview/hooks/device-preview';

import { init } from '@rexlabs/theme-luna';
import { TOKENS } from 'theme/tokens';

if (__DEV__) {
  window.app = {
    store,
    api
  };
}

// Setup global parts of theme
init();

const token = createTokenFn(TOKENS);
insertCss(`
  *, *:before, *:after {
    box-sizing: border-box;
  }

  html, body, #app {
    width: 100%;
    height: 100%;
  }

  body {
    overflow: hidden;
    transform: translate3d(0,0,0);
    background: ${token('color.grey.50')};
  }
`);

// Setup Sprig
initSprig(config.SPRIG_ID);

function Mount() {
  useEffect(() => {
    window.document.body.classList.add('ready');
  }, []);

  return (
    <StylesProvider tokens={TOKENS} debug={__DEV__}>
      <TextProvider>
        <ToastProvider>
          <Provider store={store}>
            <DevicePreviewProvider>
              <DialogProvider>
                <PersistGate loading={<LoadingLayout />} persistor={persistor}>
                  <Fragment>
                    <Segment />
                    <DialogTarget />
                    <ToastTarget />
                    <AdminApp />
                  </Fragment>
                </PersistGate>
              </DialogProvider>
            </DevicePreviewProvider>
          </Provider>
        </ToastProvider>
      </TextProvider>
    </StylesProvider>
  );
}

render(<Mount />, document.getElementById('app'));
