import React from 'react';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';

function ReapitSyncFields() {
  return (
    <>
      <Field
        Input={TextInput}
        name={'client_id'}
        label={'Client ID'}
        optional={false}
      />
      <Field
        Input={TextInput}
        name={'password'}
        label={'Password'}
        optional={false}
      />
    </>
  );
}

export default ReapitSyncFields;
