import Generator from '../generator';
import { api } from 'utils/api-client';
import { SettingModel } from 'data/models/entities/settings';
import {
  BaseModelGeneratorModel,
  DefaultEntityMethods,
  Includes
} from '@rexlabs/model-generator';

export interface Block extends BaseModelGeneratorModel {
  id: string;
  label: string;
  key: string;
  priority: number;
  static: boolean;
  enabled: boolean | null;
  settings: Includes<SettingModel> | null;
}

export type BlockModel = Block & DefaultEntityMethods<Block>;

const config = {
  entities: {
    api: {
      fetchItem: (type, { websiteId, id, ...args }) =>
        api.get(`/websites/${websiteId}/blocks/${id}`, args),
      fetchList: (type, { websiteId, ...args }) =>
        api.get(`/websites/${websiteId}/blocks`, args),
      updateItem: (type, { websiteId, ...args }, id) =>
        api.patch(`/websites/${websiteId}/blocks/${id}`, args),
      bulkUpdateItems: (type, { websiteId, ...args }) =>
        api.patch(`/websites/${websiteId}/blocks`, args.items)
    }
  }
};

// TODO: fix types
// eslint-disable-next-line
// @ts-ignore
export default new Generator('blocks', config).createEntityModel();
