import Generator from '../generator';
import { api } from 'utils/api-client';

const config = {
  entities: {
    api: {
      fetchList: (type, { websiteId, ...args }) =>
        api.get(`/websites/${websiteId}/seo`, args),
      createItem: (type, { websiteId, ...args }) =>
        api.post(`/websites/${websiteId}/seo`, args),
      fetchItem: (type, { websiteId, ...args }, id) =>
        api.get(`/websites/${websiteId}/seo/${id}`, args),
      updateItem: (type, { websiteId, ...args }, id) =>
        api.patch(`/websites/${websiteId}/seo/${id}`, args)
    }
  }
};

export default new Generator('seo', config).createEntityModel();
