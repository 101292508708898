import { query } from '@rexlabs/model-generator';
import redirectsModel from 'data/models/entities/redirects';

export const redirectsQuery = query`{
  ${redirectsModel} (websiteId: ${(p) => p?.match?.params?.websiteId}) {
    id
    redirects_from
    redirects_to
  }
}`;

export const redirectQuery = query`{
  ${redirectsModel} (websiteId: ${(p) => p?.match?.params?.websiteId}, id: ${(
  p
) => p?.match?.params?.redirectId}) {
    id
    redirects_from
    redirects_to
  }
}`;
