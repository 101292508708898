import { FileInputZone } from 'view/components/file-input';
import {
  InputProps,
  Setting
} from 'view/components/theme-settings/theme-setting-input';

export function getImageInput(setting: Setting): InputProps {
  return {
    input: FileInputZone,
    inputProps: {
      settingKey: setting.key,
      category: 'style',
      type: 'image',
      themeFile: true,
      value: setting?.value
    }
  };
}
