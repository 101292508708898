import React, { useRef, useState } from 'react';
import { SketchPicker, Color } from 'react-color';
import { useClickAway } from 'use-click-away';

import { styled, StyleSheet } from '@rexlabs/styling';

interface PickerProps {
  displayColorPicker: boolean;
  handleClose: () => void;
  handleChange: (Color) => void;
  color: Color;
}

interface DecoratorProps {
  styles: any;
}

const defaultStyles = StyleSheet({
  popover: {
    zIndex: 2
  },
  hidden: {
    display: 'none'
  }
});

function Picker({
  styles: s,
  displayColorPicker,
  handleClose,
  handleChange,
  color
}: PickerProps & DecoratorProps) {
  const node = useRef();
  const [internalColor, setInternalColor] = useState(color);

  const handleInternalChange = (color) => {
    setInternalColor(color?.hex);
  };

  useClickAway(node, () => {
    handleClose();
  });

  return (
    <>
      <div
        ref={node}
        {...s.with('popover')({
          display: displayColorPicker ? 'inherit' : 'none'
        })}
      >
        <SketchPicker
          disableAlpha={true}
          color={internalColor}
          onChange={handleInternalChange}
          onChangeComplete={handleChange}
        />
      </div>
    </>
  );
}

export default styled(defaultStyles)(Picker);
