import Generator from '../generator';
import { api } from 'utils/api-client';

const config = {
  entities: {
    api: {
      fetchList: (type, args) =>
        api.get(
          `/websites/${args.websiteId}/forms/${args.formId}/actions`,
          args
        ),
      fetchItem: (type, args, id) =>
        api.get(
          `/websites/${args.websiteId}/forms/${args.formId}/actions/${id}`,
          args
        ),
      createItem: (type, args) =>
        api.post(
          `/websites/${args.websiteId}/forms/${args.formId}/actions`,
          args
        ),
      updateItem: (type, args, id) =>
        api.post(
          `/websites/${args.websiteId}/forms/${args.formId}/actions/${id}`,
          args
        ),
      trashItem: (type, args, id) =>
        api.delete(
          `/websites/${args.websiteId}/forms/${args.formId}/actions/${id}`,
          args
        )
    }
  }
};

export default new Generator('actions', config).createEntityModel();
