import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { ReactForms, Form, Field } from '@rexlabs/form';
import { RenderLoading } from 'view/components/render-loading';
import { withQuery, query } from '@rexlabs/model-generator';
import { autobind } from 'core-decorators';
import settings from 'data/models/entities/settings';
import { handleSettingsSubmit } from 'utils/handle-settings-submit';
import { PrimaryButton } from '@rexlabs/button';
import { withToast } from 'view/components/toast';
import { Dropdown } from '@rexlabs/select';
import { Checkbox } from '@rexlabs/checkbox';
import { TextArea, TextInput } from '@rexlabs/text-input';
import { Heading, Bold } from '@rexlabs/text';
import { CodeInput } from '@rexlabs/code-input';
import { SHOW_HIDE_OPTIONS } from 'view/screens/admin/listing-search';

function getWebsiteId(props) {
  return props?.match?.params?.websiteId;
}

const settingQuery = query`{
  ${settings} (websiteId: ${getWebsiteId}, category: listings) {
    key
    value
    label
    category
  }
}`;

const mapProps = (props) => {
  const { settings } = props;
  const settingsData = settings?.list?.items;

  const data = Object.assign(
    {},
    ...settingsData.map((object) => {
      return { [object.key]: object?.value ? object?.value[0] : null };
    })
  );

  return data;
};

const listingSettings = {
  name: 'listingSettings',
  mapPropsToValues: mapProps,
  handleSubmit: handleSettingsSubmit,
  asyncValuesReady: (props) => {
    return props.settings.list.status === 'loaded';
  }
};

const CURRENCY_OPTIONS = [
  { label: 'AUD (Australian Dollar) - $', value: 'aud' },
  { label: 'GBP (British Pound Sterling) - £', value: 'gbp' },
  { label: 'NZD (New Zealand Dollar) - $', value: 'nzd' },
  { label: 'USD (United States Dollar) - $', value: 'usd' },
  { label: 'EUR (Euro) - €', value: 'eur' },
  { label: 'AED (United Arab Emirates Dirham) - د.إ', value: 'aed' }
];

@withToast
@withQuery(settingQuery)
@autobind
class ListingSettings extends PureComponent {
  render() {
    return (
      <Box
        flex={1}
        style={{ padding: '20px 20px 100px 20px', overflow: 'auto' }}
      >
        <RenderLoading isLoading={this.props.settings.list.status !== 'loaded'}>
          <ReactForms
            initialValues={listingSettings.mapPropsToValues(this.props)}
            handleSubmit={(values) =>
              listingSettings.handleSubmit(values, { props: this.props })
            }
          >
            {({ submitForm, isSubmitting }) => (
              <Form name='listingSettings' style={{ width: '100%' }}>
                <Box
                  flex={1}
                  flexDirection={'row'}
                  padding='10px'
                  alignItems={'center'}
                >
                  <h1
                    style={{
                      fontSize: '28px',
                      fontWeight: '700',
                      marginRight: '20px'
                    }}
                  >
                    {' '}
                    Listings Settings{' '}
                  </h1>

                  <PrimaryButton onClick={submitForm} isLoading={isSubmitting}>
                    Save
                  </PrimaryButton>
                </Box>

                <Box
                  padding={10}
                  flex={1}
                  data-testId={'display-currency-select'}
                >
                  <Field
                    label='Display Currency'
                    name='display_currency'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: CURRENCY_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='display_currency_use_currency_code'
                    label='Use currency code instead of currency symbol'
                    HelpTooltipContent={() =>
                      'Select this option if you would like to use the currency code (e.g. AUD, GBP, EUR) instead of the currency symbol (e.g. $, £, €).'
                    }
                    optional={false}
                    Input={Checkbox}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listings_hide_marketing_headline'
                    label='Hide the Marketing Headline on the listing details page'
                    optional={false}
                    Input={Checkbox}
                    HelpTooltipContent={() =>
                      'This can be set if your marketing headline set in Rex CRM mirrors part of the listing description'
                    }
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listings_hide_agents'
                    label='Hide Agents on Single Listing Pages'
                    optional={false}
                    Input={Checkbox}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listings_hide_agency'
                    label='Hide Agency Details on Single Listing Pages'
                    optional={false}
                    Input={Checkbox}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='hide_listings_from_maps_when_address_hidden'
                    label='Listings with hidden addresses should be excluded from maps'
                    optional={false}
                    Input={Checkbox}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='show_listing_sale_date'
                    label='Display the sale date on sold listings'
                    optional={false}
                    Input={Checkbox}
                  />
                </Box>

                <Box padding={10} flex={1}>
                  <Field
                    label='Date available text for listings that are already available'
                    name='listings_available_now_text'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: [
                        {
                          label: 'Display date available (Default)',
                          value: 'date'
                        },
                        {
                          label: "Display text 'Available Now'",
                          value: 'text'
                        }
                      ]
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='open_homes_show_for_x_hours_after'
                    label='Continue to display open home / auction events for X hours after the start date / time'
                    Input={TextInput}
                    optional={false}
                    HelpTooltipContent={() =>
                      "Leave blank or set to zero to hide open home / auction events immediately once it's start time has passed"
                    }
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='auction_fees_label'
                    label='Auction Fees Tooltip Label'
                    Input={TextInput}
                    optional={false}
                    HelpTooltipContent={() =>
                      'This label will be appended to the price for all listings with an auction set. Both the label and description below must be set for this to apply.'
                    }
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='auction_fees_description'
                    label='Auction Fees Description'
                    Input={TextArea}
                    optional={false}
                    HelpTooltipContent={() =>
                      'This content will be displayed in a popup window when the tooltip label set above is clicked. Both the description and label above must be set for this to apply.'
                    }
                  />
                </Box>

                <Box padding={10} flex={1}>
                  <Field
                    label='Single Listing Page Layout'
                    name='single_listing_page_layout'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: [
                        { label: 'Full-width banner', value: 'full_banner' },
                        {
                          label: 'Inset primary image',
                          value: 'inset_primary_image'
                        }
                      ]
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listings_external_link_label'
                    label='External Link Button Label'
                    Input={TextInput}
                    optional={false}
                    HelpTooltipContent={() =>
                      "This can be overridden at the agency level. Defaults to 'External Link'"
                    }
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listings_external_link_label_rentals'
                    label='External Link Button Label (Rentals)'
                    Input={TextInput}
                    optional={false}
                    HelpTooltipContent={() =>
                      "This can be overridden at the agency level. Defaults to the value set above or 'External Link' if blank"
                    }
                  />
                </Box>

                <Heading
                  style={{
                    marginTop: '40px',
                    marginBottom: '20px',
                    marginLeft: '10px'
                  }}
                >
                  Listing Stats
                </Heading>

                <Bold
                  as={'h3'}
                  style={{
                    marginTop: '20px',
                    marginBottom: '10px',
                    marginLeft: '10px'
                  }}
                >
                  Residential
                </Bold>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_residential_bedrooms'
                    label='Bedrooms'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_residential_sleeps'
                    label='Sleeps'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_residential_bathrooms'
                    label='Bathrooms'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_residential_cars'
                    label='Car Spaces'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_residential_land_area'
                    label='Land Area'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_residential_build_area'
                    label='Build Area'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Bold
                  as={'h3'}
                  style={{
                    marginTop: '20px',
                    marginBottom: '10px',
                    marginLeft: '10px'
                  }}
                >
                  Rural
                </Bold>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_rural_bedrooms'
                    label='Bedrooms'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_rural_sleeps'
                    label='Sleeps'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_rural_bathrooms'
                    label='Bathrooms'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_rural_cars'
                    label='Car Spaces'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_rural_land_area'
                    label='Land Area'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_rural_build_area'
                    label='Build Area'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Bold
                  as={'h3'}
                  style={{
                    marginTop: '20px',
                    marginBottom: '10px',
                    marginLeft: '10px'
                  }}
                >
                  Land
                </Bold>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_land_bedrooms'
                    label='Bedrooms'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_land_sleeps'
                    label='Sleeps'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_land_bathrooms'
                    label='Bathrooms'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_land_cars'
                    label='Car Spaces'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_land_land_area'
                    label='Land Area'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_land_build_area'
                    label='Build Area'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Bold
                  as={'h3'}
                  style={{
                    marginTop: '20px',
                    marginBottom: '10px',
                    marginLeft: '10px'
                  }}
                >
                  Commercial
                </Bold>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_commercial_bedrooms'
                    label='Bedrooms'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_commercial_sleeps'
                    label='Sleeps'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_commercial_bathrooms'
                    label='Bathrooms'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_commercial_cars'
                    label='Car Spaces'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_commercial_land_area'
                    label='Land Area'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_commercial_build_area'
                    label='Build Area'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Bold
                  as={'h3'}
                  style={{
                    marginTop: '20px',
                    marginBottom: '10px',
                    marginLeft: '10px'
                  }}
                >
                  Business
                </Bold>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_business_bedrooms'
                    label='Bedrooms'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_business_sleeps'
                    label='Sleeps'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_business_bathrooms'
                    label='Bathrooms'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_business_cars'
                    label='Car Spaces'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_business_land_area'
                    label='Land Area'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_business_build_area'
                    label='Build Area'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Bold
                  as={'h3'}
                  style={{
                    marginTop: '20px',
                    marginBottom: '10px',
                    marginLeft: '10px'
                  }}
                >
                  Holiday
                </Bold>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_holiday_bedrooms'
                    label='Bedrooms'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_holiday_sleeps'
                    label='Sleeps'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_holiday_bathrooms'
                    label='Bathrooms'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_holiday_cars'
                    label='Car Spaces'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_holiday_land_area'
                    label='Land Area'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_stats_holiday_build_area'
                    label='Build Area'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Heading
                  style={{
                    marginTop: '40px',
                    marginBottom: '20px',
                    marginLeft: '10px'
                  }}
                >
                  Custom Content / Snippets
                </Heading>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listings_custom_content_auction'
                    label='Apply to listings with Auctions'
                    Input={CodeInput}
                    inputProps={{
                      mode: 'html',
                      theme: 'github',
                      charLimit: 9000
                    }}
                    optional={false}
                    HelpTooltipContent={() =>
                      'This content will be appended to the main content block for listings that have an upcoming auction scheduled'
                    }
                  />
                </Box>
              </Form>
            )}
          </ReactForms>
        </RenderLoading>
      </Box>
    );
  }
}

export default ListingSettings;
