import { Body } from '@rexlabs/text';
import Box from '@rexlabs/box';
import { DestructiveButton, GhostButton } from '@rexlabs/button';
import React, { useState } from 'react';
import { Dialog } from '@rexlabs/dialog';
import { api } from 'utils/api-client';

interface Props {
  close: () => void;
  blockId: string;
  websiteId: string;
  onSuccess: () => void;
}

export const DeleteBlockDialog = (props: Props) => {
  const { close, blockId, websiteId, onSuccess } = props;
  const [error, setError] = useState<string>('');

  const handleDelete = async () => {
    try {
      const response = await api.delete(
        `websites/${websiteId}/blocks/delete?id=${blockId}`
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      if (response.ok) {
        setError('');
        onSuccess();
        close();
      }
    } catch {
      setError(`Failed to delete block: ${blockId}`);
    }
  };

  return (
    <Dialog onClose={close} title={'Delete Block'} width={'600px'}>
      <Box width={'100%'}>
        <Body>
          Are you sure you want to delete this block? This action cannot be
          reversed.
        </Body>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'flex-end'}
          width={'100%'}
          marginTop={'24px'}
        >
          <GhostButton onClick={close}>{'Cancel'}</GhostButton>
          <DestructiveButton
            id={'delete-block-button'}
            style={{ marginLeft: '8px' }}
            onClick={handleDelete}
          >
            Delete Block
          </DestructiveButton>
        </Box>
      </Box>
    </Dialog>
  );
};
