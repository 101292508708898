import { Body } from '@rexlabs/text';
import Box from '@rexlabs/box';
import { GhostButton, PrimaryButton } from '@rexlabs/button';
import React, { useState } from 'react';
import { Dialog } from '@rexlabs/dialog';
import { Field, Form, ReactForms } from '@rexlabs/form';
import { Dropdown } from '@rexlabs/select';
import { BlockItemProps } from 'view/components/theme-settings/block-list';
import { api } from 'utils/api-client';
import { TextInput } from '@rexlabs/text-input';
import { RenderLoading } from 'view/components/render-loading';

type FormValues = {
  block_type: {
    value: string;
    label: string;
  } | null;
  block_label: string | null;
};

interface Props {
  close: () => void;
  blockList: BlockItemProps[];
  websiteId: string;
  onSuccess: () => void;
}

export const CreateBlockDialog = (props: Props) => {
  const { close, blockList, websiteId, onSuccess } = props;
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (values: FormValues) => {
    if (values?.block_type?.value && values?.block_label) {
      try {
        const response = await api.post(`websites/${websiteId}/blocks/create`, {
          key: values.block_type.value,
          label: values.block_label
        });
        if (response.ok) {
          setError(null);
          onSuccess();
          close();
        }
      } catch (err) {
        setError(`Failed to create block with key: ${values.block_type.value}`);
      }
    }
  };
  return (
    <Dialog title={'Add New Block'} width={'400px'} onClose={close}>
      <Box width={'100%'}>
        <Body>Select a block type to add to your homepage</Body>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <Box flex={1} display={'flex'}>
          <ReactForms
            initialValues={{ block_type: null, block_label: null }}
            handleSubmit={handleSubmit}
          >
            {({ isSubmitting, submitForm }) => {
              return (
                <RenderLoading isLoading={isSubmitting}>
                  <Form
                    data-testid={'add-block-form'}
                    style={{ width: '100%' }}
                  >
                    <Box display={'flex'} flex={1} flexDirection={'column'}>
                      <Box
                        data-testId={'select-block-type-field'}
                        width={'100%'}
                      >
                        <Field
                          label='Select Block Type'
                          optional={false}
                          fullWidth={true}
                          name='block_type'
                          Input={Dropdown}
                          inputProps={{
                            items: blockList.map((item) => ({
                              label: item.label,
                              value: item.key
                            }))
                          }}
                        />
                      </Box>
                      <Box data-testId={'block-name-field'} width={'100%'}>
                        <Field
                          label='Name'
                          optional={false}
                          fullWidth={true}
                          name='block_label'
                          Input={TextInput}
                          HelpTooltipContent={() =>
                            'Create a custom name for your block'
                          }
                        />
                      </Box>
                    </Box>
                    <Box
                      display={'flex'}
                      flex={1}
                      marginTop={'24px'}
                      flexDirection={'row'}
                      justifyContent={'flex-end'}
                    >
                      <GhostButton onClick={close}>{'Cancel'}</GhostButton>
                      <PrimaryButton
                        style={{ marginLeft: '8px' }}
                        isLoading={isSubmitting}
                        onClick={() => {
                          submitForm();
                        }}
                      >
                        Save
                      </PrimaryButton>
                    </Box>
                  </Form>
                </RenderLoading>
              );
            }}
          </ReactForms>
        </Box>
      </Box>
    </Dialog>
  );
};
