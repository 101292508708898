import Generator from '../generator';
import { api } from 'utils/api-client';
import _ from 'lodash';

export const TYPE = 'themeSettings';

const config = {
  entities: {
    api: {
      fetchList: (type, args) =>
        api
          .get(
            `/websites/${args.websiteId}/blocks/${args.blockId}/themesettings`,
            args
          )
          .then((data) => {
            return data;
          }),
      fetchItem: (type, args) =>
        api
          .get(
            `/websites/${args.websiteId}/blocks/${args.blockId}/themesettings/${args.settingId}`,
            args
          )
          .then((data) => {
            return data;
          })
    }
  }
};

const actionCreators = {
  updateSettings: {
    request: (payload) =>
      api.post(
        `/websites/${payload.websiteId}/blocks/${payload.blockId}/theme-settings/update`,
        payload.data
      ),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const ThemeSettingsModelGenerator = new Generator(TYPE, config);
export default ThemeSettingsModelGenerator.createEntityModel({
  actionCreators
});
