import React from 'react';
import Box from '@rexlabs/box';
import LoadingSpinner from '@rexlabs/loading-spinner';

function DefaultLoadingView() {
  return (
    <Box
      w='100%'
      h='100%'
      flex={1}
      alignItems='center'
      justifyContent='center'
      flexDirection='column'
    >
      <LoadingSpinner size={32} strokeWidth={4} />
    </Box>
  );
}

interface RenderLoadingProps {
  isLoading: boolean;
  LoadingView?: React.ComponentType;
  children?: React.ReactNode;
}

function RenderLoading({
  isLoading,
  LoadingView = DefaultLoadingView,
  children
}: RenderLoadingProps) {
  return isLoading ? <LoadingView /> : <>{children}</>;
}

export { RenderLoading, DefaultLoadingView as LoadingView };
