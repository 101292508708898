import React, { useEffect, useState } from 'react';

import { Dialog, DialogProps } from '@rexlabs/dialog';
import { ReactForms, Field, Form } from '@rexlabs/form';
import Box from '@rexlabs/box';
import { PrimaryButton, SecondaryButton, ButtonGroup } from '@rexlabs/button';
import { withModel } from '@rexlabs/model-generator';
import { TextInput } from '@rexlabs/text-input';

import seoModel from 'data/models/entities/seo';
import LoadingLayout from 'view/layouts/loading';
import { FileInputZone } from 'view/components/file-input';

export interface EditSEODialogProps extends DialogProps {
  onClose: () => any;
  postType: string;
  postId: string;
  websiteId: string;
}

export interface InputProps {
  seo: any;
}

interface SEOItem {
  id: string;
  post_type: string;
  post_id: number;
  title: string;
  tagline: string;
  image: {
    id: string;
  };
  permalink: string;
}

function EditSEODialog({
  postType,
  postId,
  onClose,
  websiteId,
  seo,
  ...props
}: EditSEODialogProps & InputProps) {
  const [loading, setLoading] = useState<boolean>(true);
  const [item, setItem] = useState<SEOItem | null>(null);

  // Load initial SEO
  useEffect(() => {
    seo
      .fetchList({
        id: 'fetch-seo-data',
        args: {
          websiteId: websiteId,
          q: `post_id.eq(${postId}),post_type.eq(${postType})`
        }
      })
      .then((res) => {
        if (res?.data?.[0]?.item) {
          setItem(res?.data?.[0]?.item);
        }
        setLoading(false);
      });
  }, [postType, postId]);

  const handleSubmit = (values) => {
    // SEO already exists. Update.
    if (item) {
      return seo
        .updateItem({
          id: item?.id,
          args: {
            websiteId: websiteId,
            title: values?.title,
            tagline: values?.description,
            image_id: values?.image,
            permalink: values?.canonical_url
          }
        })
        .then(onClose);
    }

    // Create new SEO item
    return seo
      .createItem({
        args: {
          websiteId: websiteId
        },
        data: {
          post_id: postId,
          post_type: postType,

          title: values?.title,
          tagline: values?.description,
          image_id: values?.image,
          permalink: values?.canonical_url
        }
      })
      .then(onClose);
  };

  return (
    <Dialog {...props} onClose={onClose} width={'40rem'}>
      <Box mb={'1.6rem'}>
        <span>
          Tags available: <code>%site_title%</code>, <code>%post_title%</code>,{' '}
          <code>%post_content%</code>
        </span>
      </Box>
      {loading ? (
        <LoadingLayout />
      ) : (
        <ReactForms
          handleSubmit={handleSubmit}
          initialValues={{
            title: item?.title,
            description: item?.tagline,
            image: item?.image,
            canonical_url: item?.permalink
          }}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <Field label={'Page Title'} name={'title'} Input={TextInput} />
              <Field
                label={'Description'}
                name={'description'}
                Input={TextInput}
                inputProps={{
                  charLimit: 170
                }}
              />
              <Field
                label={'Canonical Url'}
                name={'canonical_url'}
                Input={TextInput}
              />
              <Field
                label='Social Image'
                name='image'
                Input={FileInputZone}
                inputProps={{
                  type: 'image',
                  category: 'style',
                  settingKey: 'image'
                }}
              />

              <Box justifyContent={'flex-end'} mt={'1.6rem'}>
                <ButtonGroup>
                  <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
                  <PrimaryButton
                    isLoading={isSubmitting}
                    isDisabled={isSubmitting}
                    // eslint-disable-next-line
                    // @ts-ignore
                    onClick={submitForm}
                  >
                    Save
                  </PrimaryButton>
                </ButtonGroup>
              </Box>
            </Form>
          )}
        </ReactForms>
      )}
    </Dialog>
  );
}

export default withModel(seoModel)(EditSEODialog);
