import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { ReactForms, Form, Field } from '@rexlabs/form';
import { RenderLoading } from 'view/components/render-loading';
import { withQuery, query } from '@rexlabs/model-generator';
import { get } from 'lodash';
import { autobind } from 'core-decorators';
import settings from 'data/models/entities/settings';
import { handleSettingsSubmit } from 'utils/handle-settings-submit';
import { PrimaryButton } from '@rexlabs/button';
import { TextArea } from '@rexlabs/text-input';
import { withToast } from 'view/components/toast';

function getWebsiteId(props) {
  return get(props, 'match.params.websiteId');
}

const settingQuery = query`{
  ${settings} (websiteId: ${getWebsiteId}, category: robots) {
    key
    value
    label
    category
  }
}`;

const mapProps = (props) => {
  const { settings } = props;
  const settingsData = get(settings, 'list.items');

  const data = Object.assign(
    {},
    ...settingsData.map((object) => {
      return { [object.key]: object?.value ? object?.value[0] : null };
    })
  );

  return data;
};

const robotsForm = {
  name: 'robotsForm',
  mapPropsToValues: mapProps,
  handleSubmit: handleSettingsSubmit,
  asyncValuesReady: (props) => {
    return props.settings.list.status === 'loaded';
  }
};

@withToast
@withQuery(settingQuery)
@autobind
class RobotsTxt extends PureComponent {
  componentDidMount() {
    window.analytics?.page?.('Robots');
  }

  trackSave() {
    window.analytics?.track?.('siteloft.design', {
      action: 'updated header behaviour',
      location: 'Header behaviour'
    });
  }

  render() {
    return (
      <Box flex={1} padding={'20px'} style={{ overflow: 'auto' }}>
        <RenderLoading isLoading={this.props.settings.list.status !== 'loaded'}>
          <ReactForms
            initialValues={robotsForm.mapPropsToValues(this.props)}
            handleSubmit={(values) =>
              robotsForm.handleSubmit(values, { props: this.props })
            }
          >
            {({ submitForm, isSubmitting }) => (
              <Form name='customCss' style={{ width: '100%' }}>
                <Box
                  flex={1}
                  flexDirection={'row'}
                  padding='10px'
                  alignItems={'center'}
                >
                  <h1
                    style={{
                      fontSize: '28px',
                      fontWeight: '700',
                      marginRight: '20px'
                    }}
                  >
                    {' '}
                    Robots.txt{' '}
                  </h1>

                  <PrimaryButton
                    onClick={(e) => {
                      this.trackSave();
                      submitForm(e);
                    }}
                    isLoading={isSubmitting}
                  >
                    Save
                  </PrimaryButton>
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='robots_txt'
                    Input={TextArea}
                    inputProps={{
                      charLimit: 9000,
                      style: {
                        width: '100%',
                        maxWidth: 'unset !important'
                      }
                    }}
                    optional={false}
                    HelpTooltipContent={() =>
                      'If left blank this will default to a minimal robots.txt that disallows all crawling'
                    }
                  />
                </Box>
              </Form>
            )}
          </ReactForms>
        </RenderLoading>
      </Box>
    );
  }
}

export default RobotsTxt;
