import * as React from 'react';
import { Range } from 'react-range';

class RangeInput extends React.Component {
  state = { values: [this.props.value] };

  handleChange = (values) => {
    this.setState({ values });

    const { onChange, name } = this.props;
    onChange({
      persist: () => null,
      target: {
        type: 'string',
        name,
        id: name,
        value: String(values[0])
      }
    });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({ value: nextProps.value });
    }
  }

  render() {
    return (
      <Range
        step={1}
        min={0}
        max={100}
        values={this.state.values}
        onChange={this.handleChange}
        renderTrack={({ props, children }) => (
          <divs
            {...props}
            style={{
              ...props.style,
              height: '6px',
              width: '100%',
              backgroundColor: '#ccc',
              marginTop: '30px'
            }}
          >
            {children}
          </divs>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '20px',
              width: '10px',
              backgroundColor: '#999'
            }}
          >
            <span
              style={{
                position: 'absolute',
                top: '-20px',
                left: '-6px'
              }}
            >
              {this.state.values[0]}
            </span>
          </div>
        )}
      />
    );
  }
}

export default RangeInput;
