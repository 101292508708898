import React, { useEffect } from 'react';
import { compose } from 'redux';
import Box from '@rexlabs/box';
import { ReactForms, Form, Field } from '@rexlabs/form';
import { RenderLoading } from 'view/components/render-loading';
import { withQuery, query } from '@rexlabs/model-generator';
import settings from 'data/models/entities/settings';
import { handleSettingsSubmit } from 'utils/handle-settings-submit';
import { PrimaryButton } from '@rexlabs/button';
import { TextInput } from '@rexlabs/text-input';
import { FileInputZone } from 'view/components/file-input';
import { withToast } from 'view/components/toast';
import { Dropdown } from '@rexlabs/select';
import { Checkbox } from '@rexlabs/checkbox';

function getWebsiteId(props) {
  return props?.match?.params?.websiteId;
}

const settingQuery = query`{
  ${settings} (websiteId: ${getWebsiteId}, category: site_info) {
    key
    value
    label
    type
    category
  }
}`;

const listingTypeValues = {
  sale_and_rental: 'Sale & Rental',
  sale: 'Sale Only',
  rental: 'Rental Only'
};

const siteInfo = {
  name: 'siteInfo',
  mapPropsToValues: (props) => {
    const { settings } = props;
    const settingsData = settings?.list?.items;

    const data = Object.assign(
      {},
      ...settingsData.map((object) => {
        switch (object.type) {
          case 'image':
            return {
              [object.key]: object?.value?.data
                ? object?.value.data
                : object?.value
            };
          default:
            if (object?.key === 'listing_types' && object?.value) {
              return {
                [object.key]: {
                  label: listingTypeValues[object?.value[0]],
                  value: object?.value[0]
                }
              };
            }
            return { [object.key]: object?.value ? object?.value[0] : null };
        }
      })
    );
    return data;
  },
  handleSubmit: handleSettingsSubmit,
  asyncValuesReady: (props) => {
    return props.settings.list.status === 'loaded';
  }
};

function SiteInfo(props) {
  const { settings } = props;
  const trackSave = () => {
    window.analytics?.track?.('siteloft.design', {
      action: 'updated header behaviour',
      location: 'Header behaviour'
    });
  };

  useEffect(() => {
    window.analytics?.page?.('Site Info');
  }, []);

  return (
    <Box flex={1} style={{ padding: '20px 20px 100px 20px', overflow: 'auto' }}>
      <RenderLoading isLoading={settings.list.status !== 'loaded'}>
        <ReactForms
          initialValues={siteInfo.mapPropsToValues(props)}
          handleSubmit={(values) =>
            siteInfo.handleSubmit(values, { props: props })
          }
        >
          {({ submitForm, isSubmitting }) => (
            <Form name='customCss' style={{ width: '100%' }}>
              <Box
                flex={1}
                flexDirection={'row'}
                padding='10px'
                alignItems={'center'}
              >
                <h1
                  style={{
                    fontSize: '28px',
                    fontWeight: '700',
                    marginRight: '20px'
                  }}
                >
                  {' '}
                  Site Info{' '}
                </h1>

                <PrimaryButton
                  onClick={(e) => {
                    trackSave();
                    submitForm(e);
                  }}
                  isLoading={isSubmitting}
                >
                  Save
                </PrimaryButton>
              </Box>

              <Box p={10}>
                <Field
                  name='site_image'
                  label='Featured Image'
                  Input={FileInputZone}
                  HelpTooltipContent={() => (
                    <span>
                      The featured image for sites like Facebook & Twitter
                    </span>
                  )}
                  inputProps={{
                    type: 'image',
                    settingKey: 'site_image',
                    acceptedTypes: ['image/png', 'image/jpg', 'image/jpeg'],
                    acceptedExtensions: ['.png', '.jpg', '.jpeg']
                  }}
                  optional={false}
                />
              </Box>

              <Box padding='10px' flex={1}>
                <Field
                  name='site_title'
                  label='Site Title'
                  Input={TextInput}
                  optional={false}
                />
              </Box>
              <Box padding='10px' flex={1}>
                <Field
                  name='site_tagline'
                  label='Tagline'
                  Input={TextInput}
                  optional={false}
                />
              </Box>

              <Box padding='10px' flex={1}>
                <Field
                  name='site_keywords'
                  label='Keywords'
                  Input={TextInput}
                  HelpTooltipContent={() => (
                    <span>Separate each word with a comma</span>
                  )}
                  inputProps={{}}
                  optional={false}
                />
              </Box>

              <Box padding='10px'>
                <Field
                  label='Favicon'
                  name='favicon'
                  Input={FileInputZone}
                  HelpTooltipContent={() => (
                    <span>The small image that displays in the tab</span>
                  )}
                  inputProps={{
                    category: settings?.list?.args?.category,
                    type: 'image',
                    settingKey: 'favicon',
                    acceptedTypes: [
                      'image/png',
                      'image/jpg',
                      'image/jpeg',
                      'image/vnd.microsoft.icon',
                      'image/x-icon'
                    ],
                    acceptedExtensions: ['.png', '.jpg', '.jpeg', '.ico']
                  }}
                  optional={false}
                />
              </Box>

              <Box p={10}>
                <Field
                  name='apple_touch_icon'
                  label='Apple Touch Icon'
                  Input={FileInputZone}
                  inputProps={{
                    type: 'image',
                    settingKey: 'apple_touch_icon',
                    acceptedTypes: ['image/png'],
                    acceptedExtensions: ['.png']
                  }}
                />
              </Box>

              <Box p={10}>
                <Field
                  name='disable_ios_phone_number_detection'
                  label='Disable iOS phone number detection on website'
                  HelpTooltipContent={() =>
                    "iOS will attempt to detect phone numbers in page content and apply additional markup to make them 'linkable' which may cause unwanted layout shifts."
                  }
                  optional={false}
                  Input={Checkbox}
                />
              </Box>

              <Box p={10}>
                <Field
                  name='listing_types'
                  label='Listing Types'
                  Input={Dropdown}
                  inputProps={{
                    defaultValue: {
                      value: 'sale_and_rental',
                      label: 'Sale & Rental'
                    },
                    items: [
                      { value: 'sale_and_rental', label: 'Sale & Rental' },
                      { value: 'sale', label: 'Sale Only' },
                      { value: 'rental', label: 'Rental Only' }
                    ]
                  }}
                />
              </Box>

              <Box p={10}>
                <Field
                  name='mix_contract_available'
                  label='Mix Contract + Available'
                  HelpTooltipContent={() =>
                    'Select this option if you would like Under Contract/Sold STC listings to display with available listings.'
                  }
                  optional={false}
                  Input={Checkbox}
                />
              </Box>

              <Box p={10}>
                <Field
                  name='country'
                  label='Country'
                  optional={false}
                  Input={Dropdown}
                  inputProps={{
                    items: [
                      { value: 'AU', label: 'Australia' },
                      { value: 'UK', label: 'United Kingdom' },
                      { value: 'NZ', label: 'New Zealand' },
                      { value: 'UAE', label: 'United Arab Emirates' }
                    ]
                  }}
                />
              </Box>

              <Box p={10}>
                <Field
                  name='suburb_formatter'
                  label='Format Suburb/Locality Name'
                  HelpTooltipContent={() =>
                    'Allows you to change how Rex Websites displays suburb/locality names - allows you to apply consistent formatting to data imported from your CRM'
                  }
                  optional={false}
                  Input={Dropdown}
                  inputProps={{
                    items: [
                      { value: 'no_formatting', label: 'Do nothing' },
                      {
                        value: 'uppercase',
                        label: 'Capitalise all letters'
                      },
                      {
                        value: 'capitalise',
                        label:
                          'Title Case - capitalise first letter of each word'
                      },
                      {
                        value: 'lowercase',
                        label: 'Lowercase all letters'
                      }
                    ]
                  }}
                />
              </Box>

              {/* <Box p={10}>
                <Field
                  name='overlay_location'
                  label='Location for Listing Cards'
                  HelpTooltipContent={() =>
                    'This allows you to determine what address field to us for the location banner on listing cards. If field is not present on a listing it will default to Locality > Suburb > State (in that order).'
                  }
                  optional={false}
                  Input={Dropdown}
                  inputProps={{
                    items: [
                      {
                        value: 'suburb',
                        label: 'Suburb'
                      },
                      {
                        value: 'locality',
                        label: 'Locality'
                      },
                      {
                        value: 'state',
                        label: 'State or Region'
                      }
                    ]
                  }}
                />
              </Box> */}

              {/* <Box p={10}>
                <Field
                  name='title_formatter'
                  label='Listing Title (Marketing) Formatting'
                  HelpTooltipContent={() =>
                    'Allows you to change how Rex Websites displays the listing title (a.k.a. the marketing title) - allows you to apply consistent formatting to data imported from your CRM'
                  }
                  optional={false}
                  Input={Dropdown}
                  inputProps={{
                    items: [
                      { value: 'no_formatting', label: 'Do nothing' },
                      {
                        value: 'uppercase',
                        label: 'Capitalise all letters'
                      },
                      {
                        value: 'capitalise',
                        label:
                          'Title Case - capitalise first letter of each word'
                      },
                      {
                        value: 'lowercase',
                        label: 'Lowercase all letters'
                      }
                    ]
                  }}
                />
              </Box> */}
            </Form>
          )}
        </ReactForms>
      </RenderLoading>
    </Box>
  );
}

export default compose(withToast, withQuery(settingQuery))(SiteInfo);
