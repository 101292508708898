import { InputProps } from 'view/components/theme-settings/theme-setting-input';
import { Dropdown } from '@rexlabs/select';

function getModelFromType(type: string, agencies: any, listings: any) {
  if (type === 'agency') {
    return agencies;
  }

  if (type === 'listings') {
    return listings;
  }

  throw new Error('Model not supported');
}

export function getMultiPostTypeInput(
  type: string,
  agencies: any,
  listings: any
): InputProps {
  const postType = type.split(':')?.[1];

  // Get the model from the post type
  const model = getModelFromType(postType, agencies, listings);

  // Format the values
  const modelList = model.list?.items?.map((agency) => ({
    value: agency.id,
    label: agency.title
  }));

  const multi = /^multi-post-type:(.+)$/.test(type);

  return {
    input: Dropdown,
    inputProps: {
      items: modelList,
      multi: multi
    }
  };
}
