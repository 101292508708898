import Box from '@rexlabs/box';
import { Field, Form } from '@rexlabs/form';
import { PrimaryButton } from '@rexlabs/button';
import { TextArea, TextInput } from '@rexlabs/text-input';
import { Dropdown } from '@rexlabs/select';
import React from 'react';
import { withQuery } from '@rexlabs/model-generator';
import { compose } from 'redux';
import { websiteQuery } from 'data/queries/websites';
import { Checkbox } from '@rexlabs/checkbox';

function ClassicFooterSettings({
  isSubmitting,
  submitForm,
  forms,
  menus,
  logoVariants
}) {
  const formItems = forms?.list?.items.map((form) => ({
    value: form.id,
    label: form.name
  }));

  return (
    <Box p={24}>
      <Form name='footerBehaviourForm'>
        <Box flex={1} flexDirection='row' alignItems='center'>
          <h1
            style={{
              fontSize: '28px',
              marginRight: '20px',
              fontWeight: 700
            }}
          >
            Footer Settings
          </h1>
          <PrimaryButton onClick={submitForm} isLoading={isSubmitting}>
            Save
          </PrimaryButton>
        </Box>

        <Box padding='10px' flex={1}>
          <Field
            label='Footer Logo'
            name='unique_footer_logo'
            Input={Dropdown}
            optional={false}
            inputProps={{ items: logoVariants }}
            HelpTooltipContent={() =>
              'Select the logo you want to display in the footer.'
            }
          />
        </Box>

        <Box padding='10px' flex={1} data-testId={'logo-link-url-field'}>
          <Field
            label='Logo Link URL'
            name='footer_logo_link_url'
            optional={true}
            Input={TextInput}
          />
        </Box>

        <Box padding={10} flex={1} data-testId={'logo-link-target-field'}>
          <Field
            label='Logo Link Target'
            name='footer_logo_link_target'
            optional={true}
            Input={Dropdown}
            inputProps={{
              items: [
                {
                  label: 'Same browser tab',
                  value: '_self'
                },
                {
                  label: 'New browser tab',
                  value: '_blank'
                }
              ]
            }}
          />
        </Box>

        <Box flexDirection='column' flexWrap='wrap'>
          <Box padding='10px' flex={1} data-testId={'hide-agency-details'}>
            <Field
              name='hide_agency_details'
              label='Hide Agency Details'
              optional={false}
              HelpTooltipContent={() =>
                'Hide the agency details card along with the agency address, opening hours, and contact details.'
              }
              Input={Checkbox}
            />
          </Box>

          <Box padding='10px' flex={1} data-testId={'address-1-field'}>
            <Field
              label='Address Line 1'
              name='footer_address_line_1'
              optional={false}
              Input={TextInput}
            />
          </Box>

          <Box padding='10px' flex={1} data-testId={'address-2-field'}>
            <Field
              label='Address Line 2'
              name='footer_address_line_2'
              optional={false}
              Input={TextInput}
            />
          </Box>

          <Box padding='10px' flex={1} data-testId={'postcode-field'}>
            <Field
              label='Postcode'
              name='footer_postcode'
              optional={false}
              Input={TextInput}
            />
          </Box>

          <Box padding='10px' flex={1} data-testId={'state-field'}>
            <Field
              label='State'
              name='footer_state'
              optional={false}
              Input={TextInput}
            />
          </Box>

          <Box padding='10px' flex={1} data-testId={'country-field'}>
            <Field
              label='Country'
              name='footer_country'
              optional={false}
              Input={TextInput}
            />
          </Box>

          <Box padding='10px' flex={1} data-testId={'phone-field'}>
            <Field
              label='Phone Number'
              name='footer_phone'
              optional={false}
              Input={TextInput}
            />
          </Box>

          <Box padding='10px' flex={1} data-testId={'fax-field'}>
            <Field
              label='Fax Number'
              name='footer_fax'
              optional={false}
              Input={TextInput}
            />
          </Box>

          <Box padding='10px' flex={1} data-testId={'email-field'}>
            <Field
              label='Email Address'
              name='footer_email'
              optional={false}
              Input={TextInput}
            />
          </Box>

          <Box padding='10px' flex={1} data-testId={'agency-name-field'}>
            <Field
              label='Agency Name'
              name='footer_agency_name'
              optional={true}
              Input={TextInput}
            />
          </Box>

          <Box padding='10px' flex={1} data-testId={'opening-hours-field'}>
            <Field
              label='Opening hours'
              name='footer_agency_opening_hours'
              optional={true}
              Input={TextArea}
            />
          </Box>

          <Box padding='10px' flex={1} data-testId={'form-field'}>
            <Field
              label='Form'
              name='footer_form'
              optional={false}
              Input={Dropdown}
              inputProps={{
                items: [{ value: null, label: 'No form' }].concat(formItems)
              }}
              HelpTooltipContent={() => 'Choose a form to add to your footer'}
            />
          </Box>

          <Box padding='10px' flex={1} data-testId={'hide-agency-details'}>
            <Field
              name='include_footer_form_inner_pages'
              label='Include form block on inner pages'
              optional={false}
              HelpTooltipContent={() =>
                'Will include the selected form (and agency details if enabled above) on inner pages'
              }
              Input={Checkbox}
            />
          </Box>

          <Box padding={10} flex={1} data-testId={'legal-menu-field'}>
            <Field
              label='Legal Menu'
              name='legal_menu'
              optional={false}
              Input={Dropdown}
              inputProps={{
                items: menus?.list?.items?.map((item) => ({
                  label: item.name,
                  value: item.id
                }))
              }}
              HelpTooltipContent={() =>
                'Select the legal menu you want to display in the footer.'
              }
            />
          </Box>

          <Box padding='10px' flex={1} data-testId={'legal-disclaimer'}>
            <Field
              label='Legal Disclaimer'
              name='legal_disclaimer'
              optional={true}
              Input={TextArea}
              HelpTooltipContent={() =>
                "Display an additional legal disclaimer in the footer, e.g. 'Licensed REAA 2008'."
              }
            />
          </Box>
        </Box>
      </Form>
    </Box>
  );
}

export default compose(withQuery(websiteQuery))(ClassicFooterSettings);
