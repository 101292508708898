import { get, map } from 'lodash';

const handleThemeSettingsSubmit = (values, { props }) => {
  const { themeSettings, addToast } = props;
  const data = map(values, (value, key) => {
    return {
      key,
      value: Array.isArray(value)
        ? value.map((v) => v?.value ?? v)
        : [value?.value ?? value]
    };
  });

  return themeSettings
    .updateSettings({
      websiteId: get(props, 'match.params.websiteId'),
      data: {
        settings: data
      }
    })
    .then(() =>
      addToast?.({
        type: 'success',
        title: 'Saved',
        content: 'Theme settings successfully updated'
      })
    )
    .catch((e) =>
      addToast?.({
        title: 'Error',
        type: 'error',
        content: e?.message ?? 'An unknown error occurred'
      })
    );
};

const handleSettingsSubmit = (values, { props }) => {
  const { settings, addToast } = props;
  const data = map(values, (value, key) => {
    // We never want to send BE an empty array
    // Empty values should just be null
    if (Array.isArray(value) && value.length === 0) {
      value = null;
    }

    return {
      key,
      value: [value?.value ?? value?.id ?? value],
      category: settings?.list?.args?.category || 'style',
      type: [
        'apple_touch_icon',
        'favicon',
        'site_image',
        'homepage_seo_image',
        'listings_landing_page_seo_image',
        'listings_landing_page_cover_image',
        'openhomes_landing_page_seo_image',
        'openhomes_landing_page_cover_image',
        'auctions_landing_page_cover_image',
        'posts_landing_page_seo_image',
        'posts_landing_page_cover_image',
        'projects_landing_page_seo_image',
        'staff_landing_page_seo_image',
        'staff_landing_page_cover_image',
        'testimonials_landing_page_seo_image',
        'testimonials_landing_page_cover_image'
      ].includes(key)
        ? 'image'
        : null
    };
  });

  return settings
    .updateSettings({
      websiteId: get(props, 'match.params.websiteId'),
      data: {
        settings: data
      }
    })
    .then(() =>
      addToast?.({
        type: 'success',
        title: 'Saved',
        content: 'Settings successfully updated'
      })
    )
    .catch((e) =>
      addToast?.({
        title: 'Error',
        type: 'error',
        content: e?.message ?? 'An unknown error occurred'
      })
    );
};

export { handleSettingsSubmit, handleThemeSettingsSubmit };
