import React, { useCallback } from 'react';
import { Dialog } from '@rexlabs/dialog';
import { ReactForms, Form, Field } from '@rexlabs/form';
import { withQuery } from '@rexlabs/model-generator';
import { ButtonGroup, GhostButton, PrimaryButton } from '@rexlabs/button';
import { Dropdown } from '@rexlabs/select';
import { websiteListingSourcesQuery } from 'data/queries/website-listing-sources';
import { syncsQuery } from 'data/queries/syncs';
import Box from '@rexlabs/box';
import _ from 'lodash';
import CodeInput from '@rexlabs/code-input';
import { RenderLoading } from 'view/components/render-loading';

// const validate = createValidationRules({
//   name: 'required'
// });

function AddDataSource({
  match,
  websiteListingSources,
  onClose,
  syncs,
  ...props
}) {
  const handleSubmit = useCallback(
    async (values) => {
      const { sync_id, filters } = values;

      await websiteListingSources.createItem({
        data: {
          listing_source_id: sync_id?.value,
          website_id: match?.params?.websiteId,
          filters: JSON.parse(filters)
        }
      });
      await websiteListingSources.refreshList();
      onClose();
    },
    [match, onClose, websiteListingSources]
  );

  const syncList = syncs.list.items.map((sync) => ({
    value: sync?.id,
    label: sync?.label ?? sync?.source_name
  }));

  return (
    <Dialog
      title='Add a listing source'
      onClose={onClose}
      width='40rem'
      {...props}
    >
      <RenderLoading isLoading={syncs.list.status === 'loading'}>
        <ReactForms handleSubmit={handleSubmit}>
          {({ isSubmitting, submitForm }) => (
            <Form>
              <Box padding='10px' flex={1}>
                <Field
                  label='Select Sync'
                  name='sync_id'
                  optional={false}
                  Input={Dropdown}
                  inputProps={{
                    items: syncList
                  }}
                />
              </Box>

              <Box padding='10px' flex={1}>
                <Field label='Add filters' name='filters' Input={CodeInput} />
              </Box>

              <ButtonGroup mt='2rem' end>
                <GhostButton onClick={onClose}>Cancel</GhostButton>
                <PrimaryButton isLoading={isSubmitting} onClick={submitForm}>
                  Save
                </PrimaryButton>
              </ButtonGroup>
            </Form>
          )}
        </ReactForms>
      </RenderLoading>
    </Dialog>
  );
}

export default _.flow([
  withQuery(websiteListingSourcesQuery),
  withQuery(syncsQuery)
])(AddDataSource);
