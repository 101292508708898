import React from 'react';
import Box from '@rexlabs/box';
import { PrimaryButton } from '@rexlabs/button';
import { Field } from '@rexlabs/form';
import { Dropdown } from '@rexlabs/select';
import { withQuery } from '@rexlabs/model-generator';
import { websiteQuery } from 'data/queries/websites';
import { compose } from 'redux';
import { SettingsWrapper } from 'view/forms/edit-header-settings';
import { TextInput } from '@rexlabs/text-input';
import { Large } from '@rexlabs/text';

interface Props {
  submitForm: (e: any) => void;
  isSubmitting: boolean;
  menu: { name: string; id: string }[];
  headerColorVariants: { label: string; value: string }[];
}

const MinimalHeaderSettings = (props: Props) => {
  const { submitForm, isSubmitting, menu, headerColorVariants } = props;
  return (
    <SettingsWrapper>
      <Box
        flex={1}
        flexDirection='row'
        alignItems='center'
        marginBottom={'10px'}
      >
        <h1
          style={{
            fontSize: '28px',
            marginRight: '20px',
            fontWeight: 700
          }}
        >
          Header Settings
        </h1>
        <PrimaryButton
          onClick={(e) => {
            window.analytics?.track?.('siteloft.design', {
              action: 'updated header behaviour',
              location: 'Header behaviour'
            });
            submitForm(e);
          }}
          isLoading={isSubmitting}
        >
          Save
        </PrimaryButton>
      </Box>
      <Box flexDirection='column' flexWrap='wrap' marginBottom={'100px'}>
        <Box padding='10px' flex={1}>
          <Field
            label='Select Header Menu'
            name='header_menu'
            Input={Dropdown}
            inputProps={{
              items: menu.map((item) => ({
                label: item.name,
                value: item.id
              }))
            }}
            HelpTooltipContent={() =>
              'Select the menu you want to display in the header. Supports a maximum of 5 top-level menu items which each support a maximum of 7 sub-menu items.'
            }
            optional={false}
          />
        </Box>

        <Box padding={10} flex={1} data-testId={'main-menu-style'}>
          <Field
            label='Main Menu Style'
            name='main_menu_style'
            optional={false}
            Input={Dropdown}
            inputProps={{
              items: [
                {
                  label: 'Full screen overlay (Default)',
                  value: 'overlay'
                },
                {
                  label: 'Extended header',
                  value: 'header'
                }
              ]
            }}
          />
        </Box>

        <Box padding={10} flex={1} data-testId={'header-background-color'}>
          <Field
            label='Header Background Colour'
            name='header_background_color'
            optional={false}
            Input={Dropdown}
            inputProps={{
              items: headerColorVariants
            }}
            HelpTooltipContent={() => 'Does not apply to homepage content.'}
          />
        </Box>

        <Box padding={10} flex={1} data-testId={'header-font-color'}>
          <Field
            label='Header Font Colour'
            name='header_font_color'
            optional={false}
            Input={Dropdown}
            inputProps={{
              items: headerColorVariants
            }}
            HelpTooltipContent={() => 'Does not apply to homepage content.'}
          />
        </Box>

        <Box padding={10} flex={1} data-testId={'header-font-color'}>
          <Field
            label='Homepage Font Colour'
            name='homepage_font_color'
            optional={false}
            Input={Dropdown}
            inputProps={{
              items: headerColorVariants
            }}
            HelpTooltipContent={() => 'Applies to all homepage text.'}
          />
        </Box>

        <Box padding={10} flex={1} data-testId={'header-font-color'}>
          <Field
            label='Overlay Menu Font Colour'
            name='menu_overlay_font_color'
            optional={false}
            Input={Dropdown}
            inputProps={{
              items: headerColorVariants
            }}
            HelpTooltipContent={() =>
              'Applies to all text and links inside the menu overlay.'
            }
          />
        </Box>

        <Box padding='10px' flex={1} flexDirection={'column'}>
          <Large semibold={true}>Contact Details</Large>
          <hr style={{ width: '100%' }} />
        </Box>

        <Box padding='10px' flex={1}>
          <Field
            name='header_email'
            label='Contact Email Address (Overlay Menu Only)'
            Input={TextInput}
            optional={true}
          />
        </Box>

        <Box padding='10px' flex={1}>
          <Field
            name='header_phone'
            label='Contact Phone Number'
            Input={TextInput}
            optional={true}
          />
        </Box>

        <Box padding='10px' flex={1}>
          <Field
            name='header_address'
            label='Contact Address (Overlay Menu Only)'
            Input={TextInput}
            optional={true}
          />
        </Box>

        <Box padding='10px' flex={1} data-testId={'header-contact-link-field'}>
          <Field
            label='Contact Button Link (Extended Header Menu Only)'
            name='versatile_header_contact_link'
            Input={TextInput}
            HelpTooltipContent={() =>
              'Provide the path to your contact page eg. /contact-us'
            }
          />
        </Box>
      </Box>
    </SettingsWrapper>
  );
};

export default compose(withQuery(websiteQuery))(MinimalHeaderSettings);
