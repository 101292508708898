import React, { useCallback } from 'react';
import { Dialog } from '@rexlabs/dialog';
import { ReactForms, Form, Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { PasswordInput } from '@rexlabs/password-input';
import { withQuery, withModel } from '@rexlabs/model-generator';
import { ButtonGroup, GhostButton, PrimaryButton } from '@rexlabs/button';
import { createValidationRules } from '@rexlabs/validator';
import { compose } from 'redux';
import sessionsModel from 'data/models/custom/session';
import { userQuery } from 'data/queries/users';

const validate = createValidationRules({
  email: 'required'
});

function CreateDomainDialog({ match, users, session, onClose, ...props }) {
  const user = users?.item?.data;

  const handleSubmit = useCallback(
    async (values) => {
      await users.updateItem({
        data: {
          id: user?.id,
          first_name: values?.first_name,
          last_name: values?.last_name,
          email: values?.email,
          password: values?.password,
          roles: 'administrator'
        }
      });
      onClose();
    },
    [users, user, onClose]
  );

  return (
    <Dialog title='Edit User' onClose={onClose} width='40rem' {...props}>
      <ReactForms
        handleSubmit={handleSubmit}
        validate={validate}
        initialValues={{
          first_name: user?.first_name,
          last_name: user?.last_name,
          email: user?.email
        }}
      >
        {({ isSubmitting, submitForm }) => (
          <Form>
            <Field
              fullWidth
              Input={TextInput}
              name='first_name'
              label='First Name'
            />
            <Field
              fullWidth
              Input={TextInput}
              name='last_name'
              label='Last Name'
            />
            <Field fullWidth Input={TextInput} name='email' label='Email' />
            <Field
              fullWidth
              Input={PasswordInput}
              name='password'
              label='Password'
            />
            <ButtonGroup mt='2rem' end>
              <GhostButton onClick={onClose}>Cancel</GhostButton>
              <PrimaryButton isLoading={isSubmitting} onClick={submitForm}>
                Save
              </PrimaryButton>
            </ButtonGroup>
          </Form>
        )}
      </ReactForms>
    </Dialog>
  );
}

export default compose(
  withQuery(userQuery),
  withModel(sessionsModel)
)(CreateDomainDialog);
