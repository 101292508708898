import React from 'react';
import { SettingsWrapper } from 'view/forms/edit-header-settings';
import Box from '@rexlabs/box';
import { PrimaryButton } from '@rexlabs/button';
import { Field } from '@rexlabs/form';
import { Dropdown } from '@rexlabs/select';
import { Checkbox } from '@rexlabs/checkbox';
import { Large } from '@rexlabs/text';
import { ColorPickerInput } from 'view/components/color-picker';
import { TextInput } from '@rexlabs/text-input';

const HEADER_BEHAVIOUR_OPTIONS = [
  { label: 'Hide & Seek', value: 'hide_and_seek' },
  { label: 'Full Stick', value: 'full_stick' }
];

function ClassicHeaderSettings({ isSubmitting, submitForm, menus }) {
  return (
    <SettingsWrapper>
      <>
        <Box
          flex={1}
          flexDirection='row'
          alignItems='center'
          marginBottom={'10px'}
        >
          <h1
            style={{
              fontSize: '28px',
              marginRight: '20px',
              fontWeight: 700
            }}
          >
            Header Settings
          </h1>
          <PrimaryButton
            onClick={(e) => {
              window.analytics?.track?.('siteloft.design', {
                action: 'updated header behaviour',
                location: 'Header behaviour'
              });
              submitForm(e);
            }}
            isLoading={isSubmitting}
          >
            Save
          </PrimaryButton>
        </Box>
        <Box flexDirection='column' flexWrap='wrap' marginBottom={'100px'}>
          <Box padding='10px' flex={1}>
            <Field
              label='Select Header Menu'
              name='header_menu'
              Input={Dropdown}
              inputProps={{
                items: menus.list.items.map((item) => ({
                  label: item.name,
                  value: item.id
                }))
              }}
              HelpTooltipContent={() =>
                'Select the menu you want to display in the header.'
              }
              optional={false}
            />
          </Box>

          <Box padding='10px' flex={1} data-testId={'header-behaviour-field'}>
            <Field
              label='Header behaviour'
              name='header_behaviour'
              Input={Dropdown}
              inputProps={{
                items: HEADER_BEHAVIOUR_OPTIONS
              }}
              optional={false}
            />
          </Box>

          <Box
            padding='10px'
            flex={1}
            data-testId={'hide-contact-details-checkbox'}
          >
            <Field
              label='Show Contact Details'
              name='show_contact_details'
              Input={Checkbox}
              HelpTooltipContent={() =>
                'Shows your agency contact details above the main menu.'
              }
            />
          </Box>

          <Box padding='10px' flex={1}>
            <Field
              label='Display boxed logo'
              name='boxed_logo'
              Input={Checkbox}
              HelpTooltipContent={() =>
                'Display the logo in a box that hangs over the header'
              }
            />
          </Box>

          <Box padding='10px' flex={1}>
            <Field
              name='header_phone'
              label='Contact Phone Number'
              Input={TextInput}
              optional={true}
            />
          </Box>

          <Box padding='10px' flex={1}>
            <Field
              name='header_email'
              label='Contact Email Address'
              Input={TextInput}
              optional={true}
            />
          </Box>

          <Box
            padding='10px'
            flex={1}
            data-testId={'transparent-header-checkbox'}
          >
            <Field
              label='Transparent Header'
              name='transparent_header'
              Input={Checkbox}
              HelpTooltipContent={() => 'Make the header transparent'}
            />
          </Box>

          <Box
            padding='10px'
            flex={1}
            data-testId={'transparent-header-hide-logo-checkbox'}
          >
            <Field
              label='Hide logo on transparent header'
              name='transparent_header_hide_logo'
              Input={Checkbox}
              HelpTooltipContent={() => 'Hides the logo from the header'}
            />
          </Box>

          <Box padding='10px' flex={1} flexDirection={'column'}>
            <Large semibold={true}>Background Colours</Large>
            <hr style={{ width: '100%' }} />
          </Box>

          <Box padding='10px' flex={1}>
            <Field
              id={'menu-background-colour-field'}
              label='Header Background Colour'
              name='menu_background_color'
              Input={ColorPickerInput}
              HelpTooltipContent={() =>
                'Sets the background colour of the top navigation.'
              }
            />
          </Box>

          <Box padding='10px' flex={1}>
            <Field
              id={'header-menu-dropdown-bg-color-field'}
              label='Dropdown Background Colour'
              name='menu_dropdown_background_color'
              Input={ColorPickerInput}
              HelpTooltipContent={() =>
                'Sets the background colour of drop down menus on the top navigation.'
              }
            />
          </Box>

          <Box padding='10px' flex={1}>
            <Field
              id={'header-menu-dropdown-item-hover-color-field'}
              label='Dropdown Menu Item Hover Colour'
              name='menu_item_hover_color'
              Input={ColorPickerInput}
              HelpTooltipContent={() =>
                'Sets the background colour of the dropdown menu items when hovering.'
              }
            />
          </Box>

          <Box padding='10px' flex={1} flexDirection={'column'}>
            <Large semibold={true}>Font Colours</Large>
            <hr style={{ width: '100%' }} />
          </Box>

          <Box padding='10px' flex={1}>
            <Field
              id={'menu-font-colour-field'}
              label='Menu Font Colour'
              name='menu_font_color'
              Input={ColorPickerInput}
              HelpTooltipContent={() =>
                'Sets the font colour used on the top navigation.'
              }
            />
          </Box>

          <Box padding='10px' flex={1}>
            <Field
              id={'menu-font-hover-colour-field'}
              label='Menu Font Hover Colour'
              name='menu_font_hover_color'
              Input={ColorPickerInput}
              HelpTooltipContent={() =>
                'Sets the font colour used on the top navigation when the user has hovered over it.'
              }
            />
          </Box>

          <Box padding='10px' flex={1}>
            <Field
              id={'menu-contact-details-font-color-field'}
              label='Contact Details Font Colour'
              name='contact_details_font_color'
              Input={ColorPickerInput}
              HelpTooltipContent={() =>
                'Sets the colour of your contact details in the menu.'
              }
            />
          </Box>

          <Box padding='10px' flex={1}>
            <Field
              id={'header-menu-dropdown-font-color-field'}
              label='Dropdown Font Colour'
              name='menu_dropdown_font_color'
              Input={ColorPickerInput}
              HelpTooltipContent={() =>
                'Sets the font colour of drop down menus on the top navigation.'
              }
            />
          </Box>

          <Box padding='10px' flex={1}>
            <Field
              id={'header-menu-dropdown-font-hover-color-field'}
              label='Dropdown Font Hover Colour'
              name='menu_dropdown_font_hover_color'
              Input={ColorPickerInput}
              HelpTooltipContent={() =>
                'Sets the font colour of drop down menus on the top navigation when the user has hovered over it.'
              }
            />
          </Box>

          <Box padding='10px' flex={1}>
            <Field
              id={'header-transparent-font-color-field'}
              label='Transparent Header Font Colour'
              name='transparent_header_font_color'
              Input={ColorPickerInput}
              HelpTooltipContent={() =>
                'Set the colour of your menu when the header is transparent'
              }
            />
          </Box>
        </Box>
      </>
    </SettingsWrapper>
  );
}
export default ClassicHeaderSettings;
