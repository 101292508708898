import Generator from '../generator';
import { api } from 'utils/api-client';
import _ from 'lodash';

export const TYPE = 'settings';

const config = {
  entities: {
    api: {
      fetchList: (type, args) =>
        api
          .get(
            `/websites/${args.websiteId}/settings/${args.category || ''}`,
            args
          )
          .then((data) => {
            return data;
          }),
      fetchItem: (type, args) =>
        api
          .get(`/websites/${args.websiteId}/settings/${args.settingId}`, args)
          .then((data) => {
            return data;
          })
    }
  }
};

const actionCreators = {
  updateSettings: {
    request: (payload) =>
      api.post(`/websites/${payload.websiteId}/settings/update`, payload.data),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const SettingsModelGenerator = new Generator(TYPE, config);
export default SettingsModelGenerator.createEntityModel({ actionCreators });
