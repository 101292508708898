import Box from '@rexlabs/box';
import { PrimaryButton } from '@rexlabs/button';
import { Field, Form, ReactForms } from '@rexlabs/form';
import { withModel } from '@rexlabs/model-generator';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Body, Heading } from '@rexlabs/text';
import { TextInput } from '@rexlabs/text-input';
import React, { PureComponent, Fragment } from 'react';
import { Link } from '@rexlabs/react-whereabouts';
import { createValidationRules } from '@rexlabs/validator';
import { autobind } from 'core-decorators';

import ROUTES from 'src/routes/auth';
import { COLORS } from 'src/theme';
import sessionModel from 'data/models/custom/session';
import Icon from 'src/view/components/icon';
import { ErrorNotification } from 'src/view/components/notification';
import ChevronLeft from '@rexlabs/icons/medium/chevron-left.svg';

const validate = createValidationRules({
  email: 'required|email'
});

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    height: '100%',
    background: ({ token }) => token('color.primary.idle.default'),
    padding: ({ token }) => token('spacing.s')
  },

  button: {
    width: '100%',
    marginTop: '10px',
    marginBottom: '10px',
    fontWeight: '600'
  },

  description: {
    color: COLORS.GRAY,
    padding: '10px 0'
  },

  backButtonWrapper: {
    height: '5rem',
    width: 'auto',
    marginBottom: '8.5rem',
    color: COLORS.GRAY,
    textDecoration: 'none'
  },

  backButton: {
    color: COLORS.GRAY,
    textDecoration: 'none',
    fontWeight: '600',
    '&:hover': {
      color: COLORS.GRAY,
      textDecoration: 'underline',
      fontWeight: '600'
    },
    '&:active': {
      color: COLORS.PRIMARY_DARK,
      textDecoration: 'underline'
    }
  },

  formField: {
    marginBottom: '2rem'
  },

  fieldWrapper: {
    padding: '10px 0'
  },

  icon: {
    width: '16px',
    marginRight: '10px',
    top: '3px',
    height: 'auto',
    position: 'relative',
    color: COLORS.GRAY,
    '&:active': {
      color: COLORS.PRIMARY_DARK
    }
  }
});

@withModel(sessionModel)
@styled(defaultStyles)
@autobind
class ForgotPassword extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      sent: false,
      error: ''
    };
  }

  componentDidMount() {
    window.analytics?.page?.('Forgot Password');
  }

  handleSubmit(values) {
    const { session } = this.props;
    this.setState({ error: '' });

    return session
      .forgotPassword({
        email: values.email
      })
      .then(() => {
        this.setState({ sent: true });
      })
      .catch((e) => {
        this.setState({ error: e.message || 'An error occurred.' });
      });
  }

  render() {
    const { styles: s } = this.props;
    const { error, sent } = this.state;

    return (
      <Fragment>
        <Body {...s('backButtonWrapper')}>
          <Link to={ROUTES.LOGIN} {...s('backButton')}>
            <Icon {...s('icon')} type={ChevronLeft} />
            <span>Back</span>
          </Link>
        </Body>
        {sent ? (
          <>
            <Heading>Check your inbox</Heading>
            <Body {...s('description')}>
              If the account exists, an email will be sent with further
              instructions.
            </Body>
            <Link to={ROUTES.LOGIN}>
              {({ onClick }) => (
                <PrimaryButton {...s('button')} onClick={onClick}>
                  Back to login
                </PrimaryButton>
              )}
            </Link>
          </>
        ) : (
          <>
            <Heading>Forgot password</Heading>
            <Body {...s('description')}>
              Enter your email address below to receive password recovery
              instructions.
            </Body>
            {error && <ErrorNotification>{error}</ErrorNotification>}
            <ReactForms
              validate={validate}
              validateOnChange={false}
              handleSubmit={this.handleSubmit}
            >
              {({ submitForm, isSubmitting }) => (
                <Form>
                  <Box {...s('fieldWrapper')}>
                    <Field
                      Input={TextInput}
                      name='email'
                      label='Email'
                      optional={false}
                      inputProps={{
                        type: 'email',
                        placeholder: 'e.g. name@company.com'
                      }}
                    />
                  </Box>
                  <Box
                    flexDirection='row'
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <PrimaryButton
                      {...s('button')}
                      isLoading={isSubmitting}
                      onClick={submitForm}
                    >
                      Send
                    </PrimaryButton>
                  </Box>
                </Form>
              )}
            </ReactForms>
          </>
        )}
      </Fragment>
    );
  }
}

export default ForgotPassword;
