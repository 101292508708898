/*
|-------------------------------------------------------------------------------
| App Config Loader
|-------------------------------------------------------------------------------
|
| Exposes app environment variables, resolving dev variables when available.
|
| ```js
| import config from 'config'
| config.API_URL
| ```
|
*/

import userConfig from '../env.js';

const isDev = process.env.NODE_ENV !== 'production';
// eslint-disable-next-line
console.log(`Config loading in ${isDev ? 'development' : 'production'} mode.`);

export default userConfig;
