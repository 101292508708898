import React, { useMemo } from 'react';

import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';

import {
  Toast,
  InfoToast,
  WarningToast,
  ErrorToast,
  SuccessToast,
  useToast
} from 'view/components/toast';

const defaultStyles = StyleSheet({
  container: {
    position: 'fixed',
    top: 75,
    right: 20,
    zIndex: 9999
  }
});

const TYPE_MAP = {
  info: InfoToast,
  warning: WarningToast,
  error: ErrorToast,
  success: SuccessToast
};

function ToastTarget({ styles: s, name = 'main' }) {
  const { toasts: allToasts } = useToast();
  const toasts = useMemo(
    () => allToasts.filter((toast) => toast.target === name),
    [allToasts, name]
  );

  return toasts.length ? (
    <Box {...s('container')}>
      {toasts.map((toast) => {
        const Component: any = toast.type
          ? TYPE_MAP[toast.type] || Toast
          : Toast;
        return <Component key={toast.id} {...toast} />;
      })}
    </Box>
  ) : null;
}

export default styled(defaultStyles)(ToastTarget);
