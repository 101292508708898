import { InputProps } from 'view/components/theme-settings/theme-setting-input';
import { Dropdown } from '@rexlabs/select';

export function getMenuInput(resourceList): InputProps {
  return {
    input: Dropdown,
    inputProps: {
      items: resourceList
    }
  };
}
