import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { ReactForms, Form, Field } from '@rexlabs/form';
import { RenderLoading } from 'view/components/render-loading';
import { Dropdown } from '@rexlabs/select';
import _, { get } from 'lodash';
import { withQuery, query } from '@rexlabs/model-generator';
import { autobind } from 'core-decorators';
import settings from 'data/models/entities/settings';
import { menusQuery } from 'data/queries/menus';
import { PrimaryButton } from '@rexlabs/button';
import { withToast } from 'view/components/toast';

function getWebsiteId(props) {
  return get(props, 'match.params.websiteId');
}

const menusSettingsQuery = query`{
  ${settings} (websiteId: ${getWebsiteId}, category: menus)  {
    key
    value
    label
  }
 }
`;

const menusSettings = {
  name: 'menusSettings',
  mapPropsToValues: (props) => {
    const { settings, menus } = props;
    const settingsData = get(settings, 'list.items');
    const menusData = get(menus, 'list.items');

    return Object.assign(
      {},
      ...settingsData.map((object) => {
        const value = object?.value ? object?.value[0] : null;

        if (value) {
          const menu = _.find(menusData, (o) => o.id === value);
          return { [object.key]: { value, label: menu?.name } };
        }
      })
    );
  },
  handleSubmit: (values, { props }) => {
    const { settings, addToast } = props;

    const data = _.map(values, (value, key) => {
      return {
        key,
        value: [value.value],
        category: settings?.list?.args?.category || 'form'
      };
    });

    return settings
      .updateSettings({
        websiteId: get(props, 'match.params.websiteId'),
        data: {
          settings: data
        }
      })
      .then(() =>
        addToast?.({
          type: 'success',
          title: 'Saved',
          content: 'Theme successfully updated'
        })
      )
      .catch((e) =>
        addToast?.({
          title: 'Error',
          type: 'error',
          content: e?.message
        })
      );
  },
  asyncValuesReady: (props) => {
    return (
      props.settings.list.status === 'loaded' &&
      props.menus.list.status === 'loaded'
    );
  }
};

@withToast
@withQuery(menusSettingsQuery)
@withQuery(menusQuery)
@autobind
class MenuSettings extends PureComponent {
  constructor() {
    super();

    this.state = {
      menus: []
    };
  }

  componentDidMount() {
    window.analytics?.page?.('Menu Settings');
  }

  UNSAFE_componentWillReceiveProps() {
    // creating selects data object
    const { menus } = this.props;

    this.setState({
      menus: menus?.list?.items.map((item) => {
        return { label: item.name, value: item.id };
      })
    });
  }

  trackSave() {
    window.analytics?.track?.('siteloft.design', {
      action: 'updated header behaviour',
      location: 'Header behaviour'
    });
  }

  render() {
    const { menus } = this.state;

    return (
      <Box flex={1}>
        <Box flex={1} padding={'20px'} flexDirection='column'>
          <RenderLoading
            isLoading={
              this.props.settings.list.status !== 'loaded' &&
              this.props.menus.list.status !== 'loaded'
            }
          >
            <ReactForms
              initialValues={menusSettings.mapPropsToValues(this.props)}
              handleSubmit={(values) =>
                menusSettings.handleSubmit(values, { props: this.props })
              }
            >
              {({ submitForm, isSubmitting }) => (
                <>
                  <Box
                    padding='10px'
                    flexDirection='column'
                    style={{ width: '30%' }}
                  >
                    <h1
                      style={{
                        fontSize: '28px',
                        fontWeight: '700'
                      }}
                    >
                      {' '}
                      Menu Locations{' '}
                    </h1>
                    <p>Select where menus should appear</p>
                    <PrimaryButton
                      onClick={(e) => {
                        this.trackSave();
                        submitForm(e);
                      }}
                      isLoading={isSubmitting}
                    >
                      Save
                    </PrimaryButton>
                  </Box>

                  <Form name='menusSettings' style={{ width: '80%' }}>
                    <Box flexDirection={'column'} flexWrap={'wrap'}>
                      <Box padding='10px' flex={1}>
                        <Field
                          label='Header menu'
                          name='header_menu'
                          Input={Dropdown}
                          inputProps={{
                            items: menus
                          }}
                          required
                        />
                      </Box>

                      <Box padding='10px' flex={1}>
                        <Field
                          label='Homepage menu'
                          name='homepage_menu'
                          optional={false}
                          Input={Dropdown}
                          inputProps={{
                            items: menus
                          }}
                        />
                      </Box>
                    </Box>
                  </Form>
                </>
              )}
            </ReactForms>
          </RenderLoading>
        </Box>
      </Box>
    );
  }
}

export default MenuSettings;
