import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { ReactForms, Form, Field } from '@rexlabs/form';
import { RenderLoading } from 'view/components/render-loading';
import { withQuery, query } from '@rexlabs/model-generator';
import { autobind } from 'core-decorators';
import settings from 'data/models/entities/settings';
import { handleSettingsSubmit } from 'utils/handle-settings-submit';
import { PrimaryButton } from '@rexlabs/button';
import { withToast } from 'view/components/toast';
import { CodeInput } from '@rexlabs/code-input';

function getWebsiteId(props) {
  return props?.match?.params?.websiteId;
}

const settingQuery = query`{
  ${settings} (websiteId: ${getWebsiteId}, category: customjs) {
    key
    value
    label
    category
  }
}`;

const mapProps = (props) => {
  const { settings } = props;
  const settingsData = settings?.list?.items;

  const data = Object.assign(
    {},
    ...settingsData.map((object) => {
      return { [object.key]: object?.value ? object?.value[0] : null };
    })
  );

  return data;
};

const customJavascript = {
  name: 'customJavascript',
  mapPropsToValues: mapProps,
  handleSubmit: handleSettingsSubmit,
  asyncValuesReady: (props) => {
    return props.settings.list.status === 'loaded';
  }
};

@withToast
@withQuery(settingQuery)
@autobind
class CustomJavascript extends PureComponent {
  render() {
    return (
      <Box
        flex={1}
        style={{ padding: '20px 20px 100px 20px', overflow: 'auto' }}
      >
        <RenderLoading isLoading={this.props.settings.list.status !== 'loaded'}>
          <ReactForms
            initialValues={customJavascript.mapPropsToValues(this.props)}
            handleSubmit={(values) =>
              customJavascript.handleSubmit(values, { props: this.props })
            }
          >
            {({ submitForm, isSubmitting }) => (
              <Form name='customJavascript' style={{ width: '100%' }}>
                <Box
                  flex={1}
                  flexDirection={'row'}
                  padding='10px'
                  alignItems={'center'}
                >
                  <h1
                    style={{
                      fontSize: '28px',
                      fontWeight: '700',
                      marginRight: '20px'
                    }}
                  >
                    {' '}
                    Custom Javascript{' '}
                  </h1>

                  <PrimaryButton onClick={submitForm} isLoading={isSubmitting}>
                    Save
                  </PrimaryButton>
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='header_js'
                    label='Header JS (include <script>...</script> tags around plain JS)'
                    Input={CodeInput}
                    inputProps={{
                      mode: 'javascript',
                      theme: 'github',
                      charLimit: 9000
                    }}
                    optional={false}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='footer_js'
                    label='Footer JS (include <script>...</script> tags around plain JS)'
                    Input={CodeInput}
                    inputProps={{
                      mode: 'javascript',
                      theme: 'github',
                      charLimit: 9000
                    }}
                    optional={false}
                  />
                </Box>
              </Form>
            )}
          </ReactForms>
        </RenderLoading>
      </Box>
    );
  }
}

export default CustomJavascript;
