import { InputProps } from 'view/components/theme-settings/theme-setting-input';
import { Dropdown } from '@rexlabs/select';

export type VariantOption = { [key: string]: { value: string; label: string } };

export const BLOCK_VARIANT_OPTIONS: VariantOption = {
  one: { value: 'one', label: 'Option 1' },
  two: { value: 'two', label: 'Option 2' },
  three: { value: 'three', label: 'Option 3' }
};

export function getBlockVariantInput(options: VariantOption[]): InputProps {
  return {
    input: Dropdown,
    inputProps: {
      items: Object.values(options)
    }
  };
}
