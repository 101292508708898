import { InputProps } from 'view/components/theme-settings/theme-setting-input';
import { Dropdown } from '@rexlabs/select';

const STAFF_OPTIONS = {
  featured: { value: 'featured', label: 'Featured Staff' },
  all: { value: 'all', label: 'All Staff' }
};

export function getStaffOptionsInput(): InputProps {
  return {
    input: Dropdown,
    inputProps: {
      items: Object.values(STAFF_OPTIONS)
    }
  };
}
