/*
|-------------------------------------------------------------------------------
| App Styling Constants
|-------------------------------------------------------------------------------
|
| The theme of an app encompasses:
|  1. Design Rules
|  2. Style Utilities
|
| These are here so we can apply discipline to styling our app, and avoid
| spaghetti styling code.
|
*/

import { insertCss, reset, keyframes } from '@rexlabs/styling';

export const COLORS = {
  WHITE: '#fff',
  PRIMARY_DARK: '#222',
  GRAY: '#676C7E',
  BACKGROUND: '#fbf9f6',
  RED: '#c00',
  RED_LIGHT: '#FEF6F6',
  RED_BORDER: '#D11515',

  SAND: Object.assign('#e9e6e1', {
    DARK: '#86827b'
  })
};

export const PADDINGS = {
  XXS: '.4rem',
  XS: '.8rem',
  S: '1.6rem',
  M: '2.4rem',
  L: '3.2rem',
  XL: '4rem',
  XXL: '4.8rem'
};

export const FONT = {
  WEIGHTS: {
    NORMAL: 400,
    SEMIBOLD: 700,
    BOLD: 900
  }
};

export const BORDERS = {};
export const EASINGS = {};
export const SHADOWS = {};

export const LAYOUTS = {};
export const TEXTS = {};

export const UTILS = {
  TRUNCATE: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
};

export const KEYFRAMES = {
  FADE_IN: keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 }
  }),
  FADE_OUT: keyframes({
    '0%': { opacity: 1 },
    '100%': { opacity: 0 }
  })
};

export function initTheme() {
  reset();
  insertCss(`
    @import url('https://fonts.googleapis.com/css?family=Lato:400,700,900');

    html {
      font-size: 62.5% !important;
      height: 100%;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      box-sizing: border-box;
    }

    body {
      font-size: 1.6rem;
      line-height: 1.4;
      font-weight: ${FONT.WEIGHTS.NORMAL};
      color: ${COLORS.PRIMARY_DARK};
      font-family: Lato, "Open Sans", sans-serif;
    }
    
    *, *:before, *:after {
      box-sizing: inherit;
    }

    p {
      padding-top: ${PADDINGS.XS};
      padding-bottom: ${PADDINGS.XS};
    }
  `);
}
