import React, { useEffect } from 'react';
import {
  TableProvider,
  Table,
  SimplePagination,
  TextCell,
  DateCell,
  useTableQuery
} from '@rexlabs/table';
import { Heading } from '@rexlabs/text';

import { websitesQuery } from 'data/queries/websites';
import Screen from 'view/components/screen';
import ROUTES from 'src/routes/app';

function WebsitesScreen() {
  useEffect(() => {
    window.analytics?.page?.('Website list');
  }, []);

  const { getTableProps: getTableQueryProps } = useTableQuery({
    getQuery: () => websitesQuery
  });

  const getRowLinkProps = ({ item }) => {
    return {
      to: ROUTES.CONTENT_POSTS,
      params: { websiteId: item?.id }
    };
  };

  const columns = [
    {
      id: 'name',
      width: 'auto',
      Header: 'NAME',
      Cell: TextCell
    },
    {
      id: 'account',
      width: 'auto',
      Header: 'ACCOUNT',
      Cell: TextCell
    },
    {
      id: 'created_at',
      width: 'auto',
      Header: 'CREATED AT',
      Cell: DateCell
    }
  ];

  return (
    <Screen>
      <Heading style={{ 'margin-bottom': '16px' }}>Websites</Heading>
      <TableProvider
        columns={columns}
        {...getTableQueryProps()}
        getRowLinkProps={getRowLinkProps}
      >
        <Table />
        <SimplePagination />
      </TableProvider>
    </Screen>
  );
}

export default WebsitesScreen;
