import React, { useCallback } from 'react';
import { Dialog } from '@rexlabs/dialog';
import { ReactForms, Form, Field } from '@rexlabs/form';
import { UrlInput } from '@rexlabs/text-input';
import { withQuery } from '@rexlabs/model-generator';
import { ButtonGroup, GhostButton, PrimaryButton } from '@rexlabs/button';
import { createValidationRules } from '@rexlabs/validator';

import { redirectQuery } from 'data/queries/redirects';

const validate = createValidationRules({
  redirects_from: 'required',
  redirects_to: 'required'
});

function EditRedirectDialog({ match, redirects, onClose, ...props }) {
  const redirect = redirects?.item?.data;

  const handleSubmit = useCallback(
    async (values) => {
      await redirects.updateItem({
        data: {
          websiteId: match?.params?.websiteId,
          redirects_from: values?.redirects_from,
          redirects_to: values?.redirects_to
        }
      });
      onClose();
    },
    [redirects, match, onClose]
  );

  return (
    <Dialog title='Edit Redirect' onClose={onClose} width='40rem' {...props}>
      <ReactForms
        handleSubmit={handleSubmit}
        validate={validate}
        initialValues={{
          redirects_to: redirect?.redirects_to,
          redirects_from: redirect?.redirects_from
        }}
        asyncValuesReady={redirects?.item?.status === 'loaded'}
      >
        {({ isSubmitting, submitForm }) => (
          <Form>
            <Field
              fullWidth
              Input={UrlInput}
              name='redirects_from'
              label='From'
            />
            <Field fullWidth Input={UrlInput} name='redirects_to' label='To' />
            <ButtonGroup mt='2rem' end>
              <GhostButton onClick={onClose}>Cancel</GhostButton>
              <PrimaryButton isLoading={isSubmitting} onClick={submitForm}>
                Save
              </PrimaryButton>
            </ButtonGroup>
          </Form>
        )}
      </ReactForms>
    </Dialog>
  );
}

export default withQuery(redirectQuery)(EditRedirectDialog);
