import React, { useState, useMemo, useEffect } from 'react';
import { compose } from 'lodash/fp';
import { Body, Heading } from '@rexlabs/text';
import Screen from 'view/components/screen';
import {
  TableProvider,
  Table,
  SimplePagination,
  TextCell,
  useTableQuery
} from '@rexlabs/table';
import Box from '@rexlabs/box';
import { Link } from '@rexlabs/react-whereabouts';
import {
  ButtonGroup,
  PrimaryButton,
  DestructiveButton,
  SecondaryButton
} from '@rexlabs/button';
import { push } from '@rexlabs/whereabouts';
import ROUTES from '../../../../routes/app';
import { popupsQuery } from 'data/queries/popups';
import popupsModel from 'data/models/entities/popups';
import { pagesQuery } from 'data/queries/pages';
import { withQuery, withModel } from '@rexlabs/model-generator';
import { Dialog } from '@rexlabs/dialog';
import { styled, StyleSheet } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  truncate: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
});

const PopupScreen = ({ styles: s, match, popups }) => {
  useEffect(() => {
    window.analytics?.page?.('Popup Screen');
  }, []);

  const websiteId = match?.params?.websiteId;
  const [deletePopupModal, setDeletePopupModal] = useState();

  const tableQuery = useMemo(() => {
    popupsQuery.args.websiteId = websiteId;
    popupsQuery.args.include = 'triggers,form';
    // popupsQuery.id = 'popups-list';
    return popupsQuery;
  }, [websiteId]);

  const deletePopup = () => {
    if (!deletePopupModal) return;

    return popups
      .trashItem({
        id: deletePopupModal?.id,
        args: {
          websiteId: websiteId
        }
      })
      .then(() => setDeletePopupModal(null));
  };

  const { getTableProps: getTableQueryProps } = useTableQuery({
    getQuery: () => tableQuery
  });

  const getActionMenuItems = ({ item }) => {
    return [
      {
        label: 'Edit',
        onClick: () =>
          push(ROUTES.MARKETING_POPUPS.MARKETING_POPUP_EDIT, {
            params: {
              websiteId: websiteId,
              popupId: item?.id
            }
          })
      },
      {
        label: 'Delete',
        onClick: () => setDeletePopupModal(item)
      }
    ];
  };

  const columns = [
    {
      id: 'name',
      width: 'auto',
      Header: 'Name',
      Cell: TextCell
    },
    {
      id: 'title',
      width: 'auto',
      Header: 'Title',
      Cell: TextCell
    },
    {
      id: 'content',
      width: 'auto',
      Header: 'Content',
      cellProps: { ...s('truncate') },
      Cell: TextCell
    },
    {
      id: 'form.definition.name',
      width: 'auto',
      Header: 'Form',
      Cell: TextCell
    }
  ];

  return (
    <Screen>
      <Heading>Popups</Heading>
      <Body grey>Create/edit popups</Body>
      <Box
        justifyContent='space-between'
        alignItems='center'
        pt={'1.6rem'}
        pb={'1.6rem'}
      >
        <Link
          to={ROUTES.MARKETING_POPUPS.MARKETING_POPUP_NEW}
          params={{ websiteId }}
        >
          {({ onClick }) => (
            <PrimaryButton onClick={onClick}>Create New</PrimaryButton>
          )}
        </Link>
      </Box>
      <TableProvider
        columns={columns}
        {...getTableQueryProps()}
        getActionMenuItems={getActionMenuItems}
      >
        <Table />
        <SimplePagination />
      </TableProvider>
      {/* <T.TableProvider>
        <Box justifyContent='space-between' alignItems='center'>
          <Link
            to={ROUTES.MARKETING_POPUPS.MARKETING_POPUP_NEW}
            params={{ websiteId }}
          >
            {({ onClick }) => (
              <PrimaryButton onClick={onClick}>Create New</PrimaryButton>
            )}
          </Link>
          <T.SimplePagination />
        </Box>
        <T.Data getQuery={() => tableQuery}>
          {({ items }) => (
            <T.Table>
              <T.Head>
                <T.Row>
                  <T.Cell>Name</T.Cell>
                  <T.Cell>Title</T.Cell>
                  <T.Cell>Content</T.Cell>
                  <T.Cell>Form</T.Cell>
                  <T.Cell width={30}>&nbsp;</T.Cell>
                </T.Row>
              </T.Head>
              <T.Body>
                {items.map((item) => (
                  <T.Row key={item?.id}>
                    <T.Cell>{item?.name}</T.Cell>
                    <T.Cell>{item?.title}</T.Cell>
                    <T.Cell {...s('truncate')}>{item?.content}</T.Cell>
                    <T.Cell>{item?.form?.definition?.name}</T.Cell>
                    <T.ActionMenuCell
                      width={30}
                      items={[
                        {
                          label: 'Edit',
                          onClick: () =>
                            push(ROUTES.MARKETING_POPUPS.MARKETING_POPUP_EDIT, {
                              params: {
                                websiteId: websiteId,
                                popupId: item?.id
                              }
                            })
                        },
                        {
                          label: 'Delete',
                          onClick: () => setDeletePopupModal(item)
                        }
                      ]}
                    />
                  </T.Row>
                ))}
              </T.Body>
            </T.Table>
          )}
        </T.Data>
        <Box justifyContent='flex-end' alignItems='center'>
          <T.SimplePagination />
        </Box>
      </T.TableProvider> */}
      {!!deletePopupModal && (
        <Dialog
          title={'Are you sure you want to delete this popup?'}
          onClose={() => setDeletePopupModal(null)}
        >
          <Box justifyContent={'center'}>
            <ButtonGroup>
              <SecondaryButton onClick={() => setDeletePopupModal(null)}>
                Cancel
              </SecondaryButton>
              <DestructiveButton onClick={() => deletePopup()}>
                Delete
              </DestructiveButton>
            </ButtonGroup>
          </Box>
        </Dialog>
      )}
    </Screen>
  );
};

export default compose(
  withModel(popupsModel, 'popups'),
  withQuery(pagesQuery),
  styled(defaultStyles)
)(PopupScreen);
