import React from 'react';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';

function ReapitFoundationSyncFields() {
  return (
    <>
      <Field
        Input={TextInput}
        name={'client_id'}
        label={'Client ID'}
        optional={false}
      />
      <Field
        Input={TextInput}
        name={'customer'}
        label={'Customer'}
        optional={false}
      />
    </>
  );
}

export default ReapitFoundationSyncFields;
