import React, { useCallback } from 'react';
import { Dialog } from '@rexlabs/dialog';
import { ReactForms, Form, Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { withQuery } from '@rexlabs/model-generator';
import { ButtonGroup, GhostButton, PrimaryButton } from '@rexlabs/button';
import { createValidationRules } from '@rexlabs/validator';

import { submissionsQuery } from 'data/queries/submissions';

const validate = createValidationRules({
  name: 'required'
});

function SubmissionDialog({ match, submissions, onClose, ...props }) {
  const handleSubmit = useCallback(
    async (values) => {
      await submissions.createItem({
        data: {
          websiteId: match?.params?.websiteId,
          name: values?.name
        }
      });
      await submissions.refreshList();
      onClose();
    },
    [submissions, match, onClose]
  );

  return (
    <Dialog
      title='Create New Submission'
      onClose={onClose}
      width='40rem'
      {...props}
    >
      <ReactForms handleSubmit={handleSubmit} validate={validate}>
        {({ isSubmitting, submitForm }) => (
          <Form>
            <Field fullWidth Input={TextInput} name='name' label='Name' />
            <ButtonGroup mt='2rem' end>
              <GhostButton onClick={onClose}>Cancel</GhostButton>
              <PrimaryButton isLoading={isSubmitting} onClick={submitForm}>
                Save
              </PrimaryButton>
            </ButtonGroup>
          </Form>
        )}
      </ReactForms>
    </Dialog>
  );
}

export default withQuery(submissionsQuery)(SubmissionDialog);
