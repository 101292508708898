import React, { useEffect } from 'react';
import { compose } from 'redux';
import Box from '@rexlabs/box';
import { ReactForms, Form, Field } from '@rexlabs/form';
import { RenderLoading } from 'view/components/render-loading';
import { withQuery, query } from '@rexlabs/model-generator';
import settings from 'data/models/entities/settings';
import { handleSettingsSubmit } from 'utils/handle-settings-submit';
import { PrimaryButton } from '@rexlabs/button';
import { withToast } from 'view/components/toast';
import { Dropdown } from '@rexlabs/select';

function getWebsiteId(props) {
  return props?.match?.params?.websiteId;
}

const settingQuery = query`{
  ${settings} (websiteId: ${getWebsiteId}, category: agency_landing_page_info) {
    key
    value
    label
    type
    category
  }
}`;

const agencyinfo = {
  name: 'agencyinfo',
  mapPropsToValues: (props) => {
    const { settings } = props;
    const settingsData = settings?.list?.items;

    const data = Object.assign(
      {},
      ...settingsData.map((object) => {
        switch (object.type) {
          case 'image':
            return {
              [object.key]: object?.value?.data
                ? object?.value.data
                : object?.value
            };
          default:
            return { [object.key]: object?.value ? object?.value[0] : null };
        }
      })
    );
    return {
      ...data
    };
  },
  handleSubmit: handleSettingsSubmit,
  asyncValuesReady: (props) => {
    return props.settings.list.status === 'loaded';
  }
};

function AgencyInfo(props) {
  const { settings } = props;

  const trackSave = () => {
    window.analytics?.track?.('siteloft.design', {
      action: 'updated agency landing page',
      location: 'Agency Landing Page'
    });
  };

  useEffect(() => {
    window.analytics?.page?.('Agency Landing Page');
  }, []);

  return (
    <Box flex={1} style={{ padding: '20px 20px 100px 20px', overflow: 'auto' }}>
      <RenderLoading isLoading={settings.list.status !== 'loaded'}>
        <ReactForms
          initialValues={agencyinfo.mapPropsToValues(props)}
          handleSubmit={(values) =>
            agencyinfo.handleSubmit(values, { props: props })
          }
        >
          {({ submitForm, isSubmitting }) => (
            <Form name='customCss' style={{ width: '100%' }}>
              <Box
                flex={1}
                flexDirection={'row'}
                padding='10px'
                alignItems={'center'}
              >
                <h1
                  style={{
                    fontSize: '28px',
                    fontWeight: '700',
                    marginRight: '20px'
                  }}
                >
                  {' '}
                  Agency Page Settings{' '}
                </h1>

                <PrimaryButton
                  onClick={(e) => {
                    trackSave();
                    submitForm(e);
                  }}
                  isLoading={isSubmitting}
                >
                  Save
                </PrimaryButton>
              </Box>

              <Box
                padding={10}
                flex={1}
                data-testId={'agency-testimonial-variant'}
              >
                <Field
                  label='Testimonials Variant'
                  name='agency_testimonials_variant'
                  optional={false}
                  Input={Dropdown}
                  HelpTooltipContent={() =>
                    'If testimonials are enabled on a agency profile page, this setting determines which style option to use. This setting does not apply to sites using the Minimal theme.'
                  }
                  inputProps={{
                    items: [
                      { label: 'Option One', value: 'one' },
                      { label: 'Option Two', value: 'two' }
                    ]
                  }}
                />
              </Box>
            </Form>
          )}
        </ReactForms>
      </RenderLoading>
    </Box>
  );
}

export default compose(withToast, withQuery(settingQuery))(AgencyInfo);
