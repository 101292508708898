import { query } from '@rexlabs/model-generator';
import blocksModel from 'data/models/entities/blocks';

export const blocksQuery = query`{
  ${blocksModel} (websiteId: ${(p) => p?.match?.params?.websiteId}) {
    id
    key
    label
    priority
    static
    enabled
  }
}`;
