import React, {
  IframeHTMLAttributes,
  MutableRefObject,
  useEffect,
  useState,
  useRef
} from 'react';
import { mapValues } from 'lodash/fp';
import { styled, StyleSheet } from '@rexlabs/styling';
import { RenderLoading } from 'view/components/render-loading';
import { api } from 'src/utils/api-client';
import { useToast } from 'view/components/toast';
import DeviceButtons from './device-buttons';
import { useIframeClick } from 'view/components/use-iframe-click';
import { useDevicePreview } from 'utils/device-preview/hooks/device-preview';
import useResizeObserver from 'use-resize-observer';
import debounce from 'lodash/debounce';

type ThemePreviewSetting = { [key: string]: any };

interface ThemePreviewValue {
  data?: any[];
  settings?: ThemePreviewSetting[];
}

export interface ThemePreviewProps {
  websiteId: string;
  values: ThemePreviewValue;
  component: string;
  isTheme?: boolean;
}

interface DecoratorProps {
  styles: any;
}

type DeviceView = 'DESKTOP' | 'TABLET' | 'PHONE';

const sizeMap = {
  DESKTOP: { width: 1440, height: 1080 },
  TABLET: { width: 768, height: 1024 },
  PHONE: { width: 375, height: 667 }
};

const defaultStyles = StyleSheet({
  deviceBtn: {
    cursor: 'pointer'
  },

  iframeContainer: {
    margin: '16px',
    backgroundColor: '#BABAC4',

    // HACK: the 84px represents the top button bar, and the 16px represent this div's margin
    height: 'calc(100% - (84px + 16px + 16px))',
    position: 'relative'
  },

  loadingWrapper: {
    pointerEvents: 'none',
    opacity: 0,
    position: 'absolute',
    background: 'white',
    transition: 'opacity 0.5s',
    width: '100%',
    height: '100%'
  },

  showLoading: {
    opacity: 1
  },

  iframeWrapper: {
    borderRadius: 4,
    transformOrigin: '0px 0px',
    position: 'relative',
    height: '100%',
    width: '100%',
    margin: '0px auto'
  },

  iframe: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    backgroundColor: 'white'
  },

  previewContainer: {
    height: '100%',
    width: '100%',
    maxWidth: '100%'
  },

  deviceButtonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

function ThemePreview({
  styles: s,
  websiteId,
  values,
  component,
  isTheme = false
}: ThemePreviewProps & DecoratorProps) {
  const {
    device,
    container,
    setDevice,
    setContainer,
    scale
  } = useDevicePreview();
  const [isLoading, setIsLoading] = useState(true);
  const [deviceView, setDeviceView] = useState<DeviceView>('DESKTOP');
  const [srcdoc, setSrcdoc] = useState(null);
  const { addToast } = useToast();
  const previewFrame: MutableRefObject<any> = useRef(null);
  useIframeClick(previewFrame);

  const { ref } = useResizeObserver<HTMLDivElement>({
    onResize: debounce(
      ({ width, height }) => {
        setContainer({ width, height });
      },
      500,
      { leading: true }
    )
  });

  useEffect(() => {
    setDevice(sizeMap[deviceView]);
  }, [deviceView]);

  const refresh = async () => {
    const iframe: IframeHTMLAttributes<any> | null = previewFrame.current;

    if (iframe) {
      try {
        setIsLoading(true);
        setSrcdoc(null);
        const settingKey = isTheme ? 'theme_settings' : 'settings';
        const res = await api.post(`websites/${websiteId}/preview`, {
          settings: {
            [settingKey]: mapValues((v: any) => [v?.value ?? v])(
              values?.settings
            ),
            data: values?.data
          },
          component: component
        });

        setSrcdoc(res?.data);
      } catch (e) {
        addToast({
          type: 'error',
          title: 'Error',
          content:
            e?.message || 'An unknown error occurred while updating preview'
        });
      }
    }
  };

  useEffect(() => {
    refresh();
  }, [values]);

  return (
    <div {...s('previewContainer')}>
      <div {...s('deviceButtonsContainer')}>
        <DeviceButtons
          items={[
            {
              onSelect: () => setDeviceView('DESKTOP'),
              id: 'desktop-preview-btn'
            },
            {
              onSelect: () => setDeviceView('TABLET'),
              id: 'tablet-preview-btn'
            },
            { onSelect: () => setDeviceView('PHONE'), id: 'phone-preview-btn' }
          ]}
        />
        <span>
          {device.width}px / {Math.round(scale * 100)}%
        </span>
      </div>

      <div {...s('iframeContainer')} ref={ref}>
        <div {...s('loadingWrapper', { showLoading: isLoading })}>
          <RenderLoading isLoading={isLoading} />
        </div>
        <div
          {...s.with('iframeWrapper')({
            transform: `scale(${scale})`,
            width: device.width,
            height: `calc(${container.height}px / ${scale})`,
            display: isLoading ? 'none' : 'block'
          })}
        >
          <iframe
            {...s('iframe')}
            ref={previewFrame}
            id='preview-frame'
            srcDoc={srcdoc}
            onLoad={() => !!srcdoc && setIsLoading(false)}
          />
        </div>
      </div>
    </div>
  );
}

export default styled(defaultStyles)(ThemePreview);
