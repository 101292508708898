import { InputProps } from 'view/components/theme-settings/theme-setting-input';
import { Dropdown } from '@rexlabs/select';

const options = [
  { value: 'featured_available', label: 'Featured (Available)' },
  { value: 'featured_all', label: 'Featured (All Listings)' },
  {
    value: 'recent_all',
    label: 'Recent Listings (Sales & Rentals - including sold & leased)'
  },
  {
    value: 'recent_avail_all',
    label: 'Recent Available (Sales & Rentals)'
  },
  { value: 'recent_avail_sales', label: 'Recent Available (Sales Only)' },
  {
    value: 'recent_avail_rental',
    label: 'Recent Available (Rentals Only)'
  },
  {
    value: 'recent_sold_leased_all',
    label: 'Recent Sold & Leased Listings'
  },
  { value: 'recent_sold_sales', label: 'Recent Sold Listings' },
  { value: 'recent_leased_rental', label: 'Recent Leased Listings' }
];

export function getListingsOptionsInput(): InputProps {
  return {
    input: Dropdown,
    inputProps: {
      items: options
    }
  };
}
