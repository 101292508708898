import { query } from '@rexlabs/model-generator';
import websitesModel from 'data/models/entities/websites';

export const websitesQuery = query`{
  ${websitesModel} (include: domains) {
    id
    name
    theme_id
    created_at
    updated_at
    deleted_at
    domains {
      id
      is_primary
      domain
    }
  }
}`;

export const websiteQuery = query`{
  ${websitesModel} (id: ${(p) => p?.match?.params?.websiteId}) {
    id
    name
    theme_id
    created_at
    updated_at
    deleted_at
    domains {
      id
      is_primary
      domain
    }
  }
}`;
