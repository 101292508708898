import React from 'react';
import Box from '@rexlabs/box';
import { Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { ColorPickerInput } from 'view/components/color-picker';
import { Checkbox } from '@rexlabs/checkbox';
import { FileInputZone } from 'view/components/file-input';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Heading, Body } from '@rexlabs/text';

export const mapPropsToValues = (props) => {
  const { settings } = props;
  const settingsData = settings.list.items;

  const data = Object.assign(
    {},
    ...settingsData.map((object) => {
      switch (object.type) {
        case 'image':
          return {
            [object.key]: object?.value?.data
              ? object?.value.data
              : object?.value
          };
        default:
          return { [object.key]: object?.value ? object?.value[0] : null };
      }
    })
  );

  return data;
};

const defaultStyles = StyleSheet({
  fieldContainer: {
    padding: '10px 0'
  },
  subtitle: {
    color: '#676C7E'
  }
});

function ClassicSiteStylesSettings({ settings, styles: s }) {
  return (
    <Box>
      <Body bold>Brand</Body>
      <Box {...s('fieldContainer')}>
        <Field
          label='Primary font colour'
          name='primary_font_color'
          Input={ColorPickerInput}
          optional={false}
          id={'primary-font-colour-field'}
        />
      </Box>

      <Body bold>Misc</Body>

      <Box {...s('fieldContainer')}>
        <Field
          label='Sold/Leased banner colour'
          name='property_banner_color'
          Input={ColorPickerInput}
          optional={false}
          id={'property-banner-colour-field'}
        />
      </Box>

      <Box {...s('fieldContainer')}>
        <Field
          label='Sold/Leased text colour'
          name='property_banner_text_color'
          Input={ColorPickerInput}
          optional={false}
          id={'property-banner-text-colour-field'}
        />
      </Box>

      <Box {...s('fieldContainer')}>
        <Field
          label='Gallery background colour'
          name='gallery_background_color'
          Input={ColorPickerInput}
          optional={false}
        />
      </Box>

      <Heading>Logo</Heading>

      <Body bold>Desktop</Body>
      <Box {...s('fieldContainer')}>
        <Field
          label='Desktop Logo (JPEG, PNG)'
          name='desktop_logo'
          Input={FileInputZone}
          inputProps={{
            settingKey: 'desktop_logo',
            category: settings?.list?.args?.category,
            type: 'image',
            acceptedTypes: ['image/png', 'image/jpg', 'image/jpeg'],
            acceptedExtensions: ['.png', '.jpg', '.jpeg']
          }}
          optional={false}
          HelpTooltipContent={() => 'Maximum file size is 5 MB.'}
        />
      </Box>

      <Box {...s('fieldContainer')}>
        <Field
          label='Secondary Logo (JPEG, PNG)'
          name='secondary_logo'
          Input={FileInputZone}
          inputProps={{
            settingKey: 'secondary_logo',
            category: settings?.list?.args?.category,
            type: 'image',
            acceptedTypes: ['image/png', 'image/jpg', 'image/jpeg'],
            acceptedExtensions: ['.png', '.jpg', '.jpeg']
          }}
          optional={false}
          HelpTooltipContent={() =>
            'The secondary logo is exclusively avaialble for the footer. You can use the dropdown in the footer settings to select it. Maximum file size is 5 MB.'
          }
        />
      </Box>

      <Box {...s('fieldContainer')}>
        <Field
          label='Desktop Logo Size'
          name='desktop_logo_size'
          Input={TextInput}
          inputProps={{
            type: 'number'
          }}
          optional={false}
        />
      </Box>
      <Body bold>Mobile</Body>

      <Box {...s('fieldContainer')}>
        <Field
          label='Mobile Logo (JPEG, PNG)'
          name='mobile_logo'
          Input={FileInputZone}
          inputProps={{
            settingKey: 'mobile_logo',
            category: settings?.list?.args?.category,
            type: 'image',
            acceptedTypes: ['image/png', 'image/jpg', 'image/jpeg'],
            acceptedExtensions: ['.png', '.jpg', '.jpeg']
          }}
          optional={false}
          HelpTooltipContent={() => 'Maximum file size is 5 MB.'}
        />
      </Box>

      <Box {...s('fieldContainer')}>
        <Field
          label='Mobile Logo Size'
          name='mobile_logo_size'
          Input={TextInput}
          inputProps={{
            type: 'number'
          }}
          optional={false}
        />
      </Box>

      <Box {...s('fieldContainer')}>
        <Field
          label='Logo Alt text'
          name='logo_alt_text'
          Input={TextInput}
          optional={false}
          id={'logo-alt-text-field'}
        />
      </Box>

      <Box {...s('fieldContainer')}>
        <Field
          label='Boxed logo'
          name='boxed_logo'
          Input={Checkbox}
          optional={false}
          id={'boxed-logo-field'}
        />
      </Box>
    </Box>
  );
}

export default styled(defaultStyles)(ClassicSiteStylesSettings);
