import React, { useEffect, useRef, useMemo, useState } from 'react';
import { matchMany } from '@rexlabs/whereabouts';
import { withWhereabouts } from '@rexlabs/react-whereabouts';
import { useDialog } from '@rexlabs/dialog';
import { useIframeClick } from 'view/components/use-iframe-click';

import { compose } from 'redux';
import { withModel } from '@rexlabs/model-generator';
import sessionsModel from 'data/models/custom/session';

import ROUTES from 'src/routes/app';
import config from 'src/config';
import { match } from '@rexlabs/whereabouts/lib/utils/match';
import EditSEODialog from 'view/dialogs/seo/edit';

function FrameScreen({ whereabouts, session }) {
  const frameRef = useRef();
  useIframeClick(frameRef);

  const websiteId = useMemo(
    () =>
      match(whereabouts, { path: '/website/:websiteId' })?.params?.websiteId,
    [whereabouts]
  );

  const url = useMemo(() => {
    let memUrl = `${config.WP_URL}/${websiteId}/wp-admin`;
    const matched = matchMany(whereabouts, ROUTES);
    if (matched?.[0]?.route?.wordpressUrl) {
      memUrl += matched[0].route.wordpressUrl;
    }
    return memUrl;
  }, [websiteId, whereabouts]);

  const [frameUrl] = useState(url);
  useEffect(() => {
    const apiTokenQueryPrefix = url.indexOf('?') === -1 ? '?' : '&';
    frameRef.current.src =
      url + `${apiTokenQueryPrefix}user_key=${session?.apiToken}`;
  }, [session, url]);

  const { open: openSEO } = useDialog(EditSEODialog);

  useEffect(() => {
    const onMessage = (message) => {
      const messageType = message?.data?.message_type;
      // Only display popup if the event is an edit seo event
      if (messageType === 'edit_seo') {
        const postType = message?.data?.message_data?.post_type;
        const postId = message?.data?.message_data?.post_id;
        openSEO({
          title: 'Edit SEO',
          postType: postType,
          postId: postId,
          websiteId: websiteId
        });
      }
    };

    if (window.addEventListener) {
      window.addEventListener('message', onMessage, false);
    }

    return () => {
      if (window.removeEventListener) {
        window.removeEventListener('message', onMessage);
      }
    };
  }, [websiteId, openSEO]);

  return (
    <iframe
      width='100%'
      style={{ height: '100%' }}
      ref={frameRef}
      src={frameUrl}
    />
  );
}

export default compose(withWhereabouts, withModel(sessionsModel))(FrameScreen);
