import React, { useEffect } from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, styled } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  container: {
    textAlign: 'center'
  },

  title: {
    fontSize: '14rem',
    lineHeight: '1.4'
  }
});

function NotFoundScreen({ styles: s }) {
  useEffect(() => {
    window.analytics?.page?.('Not found');
  }, []);

  return (
    <Box {...s('container')}>
      <Box {...s('title')}>404</Box>
    </Box>
  );
}

export default styled(defaultStyles)(NotFoundScreen);
