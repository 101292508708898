import { query } from '@rexlabs/model-generator';
import themesModel from 'data/models/entities/themes';

export const themesQuery = query`{
  ${themesModel} {
    id
    name
    key
    description
    version
    global
    system
  }
}`;

export const getThemeQuery = (themeId) => query`{
  ${themesModel} (id: ${themeId}) {
    id
    name
    key
    description
    version
    global
    system
  }
}`;
