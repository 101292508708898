import { InputProps } from 'view/components/theme-settings/theme-setting-input';
import { Dropdown } from '@rexlabs/select';

export const MAX_COLUMNS_OPTIONS = [
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' }
];

export function getListingsMaxColumnsInput(): InputProps {
  return {
    input: Dropdown,
    inputProps: {
      items: MAX_COLUMNS_OPTIONS
    }
  };
}
