import React from 'react';
import { COLORS, PADDINGS } from 'src/theme';
import Icon from 'src/view/components/icon';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import ErrorIcon from '@rexlabs/icons/medium/warning.svg';

const defaultStyles = StyleSheet({
  container: {
    backgroundColor: COLORS.RED_LIGHT,
    display: 'inline-block',
    border: `1px solid ${COLORS.RED_BORDER}`,
    borderRadius: '2px',
    color: COLORS.RED_BORDER,
    padding: PADDINGS.S,
    marginTop: '10px',
    marginBottom: '10px'
  },
  icon: {
    width: '16px',
    height: 'auto',
    marginRight: PADDINGS.XS,
    position: 'relative',
    color: COLORS.RED_BORDER
  }
});

@styled(defaultStyles)
class ErrorNotification extends React.Component {
  render() {
    const { styles } = this.props;

    return (
      <Box {...styles('container')} flex={1}>
        <Box flexDirection={'column'}>
          <Icon {...styles('icon')} type={ErrorIcon} />
        </Box>
        <Box flexDirection={'column'}>{this.props.children}</Box>
      </Box>
    );
  }
}

export default ErrorNotification;
