import React, { useState } from 'react';
import Box from '@rexlabs/box';
import Blocks from 'view/components/theme-settings/block-list';
import BlockItem from 'view/components/theme-settings/block-item';

function EditHomePageSettings(props: any) {
  const { updatePreviewProps } = props;
  const [setting, setSetting] = useState<string | null>(null);
  const [blockListShouldRefresh, setBlocksListShouldRefresh] = useState(false);

  return (
    <>
      <Box height={'100%'} style={{ display: setting ? 'block' : 'none' }}>
        <BlockItem
          settingId={setting}
          back={() => setSetting(null)}
          updatePreviewProps={updatePreviewProps}
          refreshBlockList={() => {
            setSetting(null);
            setBlocksListShouldRefresh(!blockListShouldRefresh);
          }}
          {...props}
        />
      </Box>

      <Box height={'100%'} style={{ display: setting ? 'none' : 'block' }}>
        <Blocks
          handleClick={(settingId) => setSetting(settingId)}
          updatePreviewProps={updatePreviewProps}
          blockListShouldRefresh={blockListShouldRefresh}
          {...props}
        />
      </Box>
    </>
  );
}

export default EditHomePageSettings;
