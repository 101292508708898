import React, { useCallback, useState } from 'react';

import { styled, StyleSheet } from '@rexlabs/styling';
import Tether from '@rexlabs/tether';

import Picker from './picker';

const defaultStyles = StyleSheet({
  color: {
    minWidth: '37px',
    minHeight: '37px',
    borderRadius: '2px'
  },
  swatch: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    minWidth: '150px',
    height: '37px',
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    cursor: 'pointer'
  },
  label: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '15px',
    fontWeight: '600'
  }
});

function ColorPicker({ styles: s, onChange, onBlur, name, value, id: testId }) {
  const [color, setColor] = useState({ hex: value });
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => setDisplayColorPicker(!displayColorPicker);

  const handleClose = useCallback(() => setDisplayColorPicker(false), []);

  const handleChange = useCallback(
    (newColor) => {
      setColor(newColor);

      const fakeEvent = {
        target: {
          type: 'string',
          name,
          id: name,
          value: newColor.hex
        }
      };

      onChange(fakeEvent);
      onBlur(fakeEvent);
    },
    [name, onChange, onBlur]
  );

  const picker = useCallback(
    () => (
      <Picker
        displayColorPicker={displayColorPicker}
        handleClose={handleClose}
        handleChange={handleChange}
        color={color}
      />
    ),
    [displayColorPicker, handleClose, handleChange]
  );

  return (
    <div>
      <Tether
        placement={'bottom-start'}
        autoFlip={true}
        distance={5}
        Content={picker}
      >
        {({ ref }) => (
          <div
            data-testId={testId}
            ref={ref}
            {...s('swatch')}
            onClick={handleClick}
          >
            <div {...s.with('color')({ background: color.hex })} />
            <div {...s('label')}> {color.hex} </div>
          </div>
        )}
      </Tether>
    </div>
  );
}

export default styled(defaultStyles)(ColorPicker);
