import Generator from '../generator';
import { api } from 'utils/api-client';

const config = {
  entities: {
    api: {
      fetchList: (type, args) =>
        api.get(`/websites/${args.websiteId}/website-listing-sources`, args),
      createItem: (type, args) =>
        api.post(`/websites/${args.websiteId}/website-listing-sources`, args)
    }
  }
};

export default new Generator(
  'website-listing-sources',
  config
).createEntityModel();
