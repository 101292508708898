import React, {
  createContext,
  useMemo,
  useContext,
  useState,
  useEffect
} from 'react';

type resolution = {
  width: number;
  height: number;
};

export type DevicePreviewContextType = {
  device: resolution;
  container: resolution;
  scale: number;

  setDevice: (device: resolution) => void;
  setContainer: (container: resolution) => void;
};

export const DevicePreviewContext = createContext<DevicePreviewContextType>(
  {} as DevicePreviewContextType
);

const initialSize = 0;
const fullWidthSize = 100;

export function DevicePreviewProvider({ children }) {
  const [device, setDevice] = useState<resolution>({
    width: initialSize,
    height: initialSize
  });
  const [container, setContainer] = useState<resolution>({
    width: initialSize,
    height: initialSize
  });
  const [scale, setScale] = useState<number>(1);

  useEffect(() => {
    let result = 1;

    if (container.width < device.width) {
      const widthOverflowPercentage =
        ((fullWidthSize / device.width) * container.width) / fullWidthSize;

      result = widthOverflowPercentage;
    }

    setScale(result);
  }, [device, container]);

  const value = useMemo(
    () => ({ device, container, setDevice, setContainer, scale }),
    [device.width, device.height, container.width, container.height, scale]
  );

  return (
    <DevicePreviewContext.Provider value={value}>
      {children}
    </DevicePreviewContext.Provider>
  );
}

export const useDevicePreview = () => {
  const devicePreview = useContext(DevicePreviewContext);

  if (!devicePreview?.device)
    throw new Error(
      'The device preview context was accessed outside of the provider tree'
    );

  return devicePreview;
};
