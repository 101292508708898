import Generator from '../generator';
import { api } from 'utils/api-client';

const config = {
  entities: {
    api: {
      fetchItem: (type, { websiteId, ...args }, id) =>
        api.get(`/websites/${websiteId}/listings/${id}`, args),
      updateItem: (type, { websiteId, ...args }, id) =>
        api.patch(`/websites/${websiteId}/listings/${id}`, args),
      fetchList: (type, { websiteId, ...args }) =>
        api.get(`/websites/${websiteId}/listings`, args),
      createItem: (type, { websiteId, ...args }) =>
        api.post(`/websites/${websiteId}/listings`, args)
    }
  }
};

export default new Generator('listings', config).createEntityModel();
