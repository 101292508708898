import { query } from '@rexlabs/model-generator';
import popupsModel from 'data/models/entities/popups';

export const popupsQuery = query`{
  ${popupsModel} (websiteId: ${(p) => p?.match?.params?.websiteId}) {
    id
    name
    title
    content
    form
    triggers
  }
}`;

export const popupQuery = query`{
  ${popupsModel} (id: ${(p) => {
  return p?.match?.params?.popupId;
}}, websiteId: ${(p) => p?.match?.params?.websiteId}) {
    id
    type
    position
    name
    title
    content
    form
    animation
    size
    triggers
  }
}`;
