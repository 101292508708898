import Generator from '../generator';
import { api } from 'utils/api-client';

const config = {
  entities: {
    api: {
      fetchList: (type, { websiteId, ...args }) =>
        api.get(`/website/${websiteId}/syncs`, args),
      createItem: (type, args) => api.post(`/syncs`, args)
    }
  }
};

export default new Generator('syncs', config).createEntityModel();
