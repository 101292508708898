import { InputProps } from 'view/components/theme-settings/theme-setting-input';
import { Dropdown } from '@rexlabs/select';

export const CONTENT_CONTAINER_VARIANTS = {
  left: { value: 'left', label: 'Left Aligned' },
  center: { value: 'center', label: 'Centre Aligned' }
};

export function getContentContainerInput(): InputProps {
  return {
    input: Dropdown,
    inputProps: {
      items: Object.values(CONTENT_CONTAINER_VARIANTS)
    }
  };
}
