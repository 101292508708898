import React, { useState, useRef, useEffect } from 'react';
import { compose } from 'redux';
import { ReactForms } from '@rexlabs/form';
import { withQuery, query } from '@rexlabs/model-generator';
import settings from 'data/models/entities/settings';
import { formsQuery } from 'data/queries/forms';
import { menusQuery } from 'data/queries/menus';
import PreviewLayout from '../../layouts/preview';
import { RenderLoading } from 'view/components/render-loading';
import { withToast } from 'view/components/toast';
import EditFooterSettings, {
  mapPropsToValues
} from 'view/forms/edit-footer-settings';
import { websiteQuery } from 'data/queries/websites';

function getWebsiteId({ match }) {
  return match?.params?.websiteId;
}

export const versatileFooterSettings = [
  'quick_links_menu',
  'footer_tagline',
  'legal_menu_title',
  'quick_links_title',
  'contact_details_title',
  'footer_background_color',
  'footer_font_color',
  'footer_logo_link_url',
  'footer_logo_link_target'
];

function getSettingsKeys() {
  const siteSettingsKeys = [
    'hide_agency_details',
    'footer_variant',
    'footer_address_line_1',
    'footer_address_line_2',
    'footer_postcode',
    'footer_state',
    'footer_country',
    'footer_phone',
    'footer_fax',
    'footer_email',
    'footer_agency_name',
    'footer_agency_opening_hours',
    'footer_form',
    'legal_menu',
    'legal_disclaimer',
    'unique_footer_logo',
    ...versatileFooterSettings
  ];

  return `key.in(["${siteSettingsKeys.join('", "')}"])`;
}
const settingsQuery = query`{
  ${settings} (websiteId: ${getWebsiteId}, q: ${getSettingsKeys})  {
    key
    value
    label
    type
  }
 }
`;

function Footer(props) {
  const { settings, forms, addToast, menus } = props;
  const { theme_id: themeId } = props.websites.item.data;
  const defaultPreviewProps = {
    websiteId: getWebsiteId(props),
    values: {
      settings: {},
      component: 'index.twig'
    }
  };
  const [previewProps, setPreviewProps] = useState(defaultPreviewProps);
  const initialPreviewPropsSet = useRef(false);
  const isLoading = !(
    props.settings.list.status === 'loaded' &&
    props.forms.list.status === 'loaded' &&
    props.menus.list.status === 'loaded'
  );

  const handleSubmit = async (values) => {
    try {
      const data = Object.keys(values).reduce((acc, key) => {
        let type = null;
        if (key === 'footer_form') {
          type = 'form';
        } else if (['legal_menu', 'quick_links_menu'].includes(key)) {
          type = 'menu';
        }

        const value = values[key];
        acc.push({
          key: key,
          value: [value?.value ?? value],
          type: type
        });

        return acc;
      }, []);

      await settings.updateSettings({
        websiteId: props.match.params?.websiteId,
        data: {
          settings: data
        }
      });

      setPreviewProps({
        ...defaultPreviewProps,
        values: { ...defaultPreviewProps, settings: values }
      });
    } catch (e) {
      addToast({
        title: 'Error',
        content: e?.message ?? 'An unknown error has occurred',
        type: 'error'
      });
    }
  };

  useEffect(() => {
    window.analytics?.page?.('Footer behaviour');
  }, []);

  useEffect(() => {
    if (initialPreviewPropsSet.current || isLoading) return;
    initialPreviewPropsSet.current = true;

    setPreviewProps({
      ...defaultPreviewProps,
      values: { ...defaultPreviewProps, settings: mapPropsToValues(props) }
    });
  }, [isLoading, props, defaultPreviewProps]);

  return (
    <RenderLoading isLoading={isLoading}>
      <ReactForms
        initialValues={mapPropsToValues(props)}
        handleSubmit={handleSubmit}
      >
        {({ isSubmitting, submitForm }) => (
          <PreviewLayout
            Settings={
              <EditFooterSettings
                isSubmitting={isSubmitting}
                submitForm={submitForm}
                forms={forms}
                menus={menus}
                themeId={themeId}
              />
            }
            previewProps={previewProps}
          />
        )}
      </ReactForms>
    </RenderLoading>
  );
}

export default compose(
  withToast,
  withQuery(formsQuery),
  withQuery(settingsQuery),
  withQuery(menusQuery),
  withQuery(websiteQuery)
)(Footer);
