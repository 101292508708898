import { Dialog } from '@rexlabs/dialog';
import { withModel, withQuery } from '@rexlabs/model-generator';
import { compose } from 'lodash/fp';
import React, { useEffect, useMemo, useState } from 'react';
import Box from '@rexlabs/box';
import {
  TableProvider,
  Table,
  SimplePagination,
  TextCell,
  useTableQuery
} from '@rexlabs/table';
import { Link } from '@rexlabs/react-whereabouts';
import { Heading, Body } from '@rexlabs/text';
import {
  ButtonGroup,
  DestructiveButton,
  PrimaryButton,
  SecondaryButton
} from '@rexlabs/button';
import { push } from '@rexlabs/whereabouts';
import { pagesQuery } from 'src/data/queries/pages';
import ROUTES from 'src/routes/app';
import { formsQuery } from 'data/queries/forms';
import Screen from 'view/components/screen';
import formsModel from 'data/models/entities/forms';

function FormsScreen({ match, forms }) {
  useEffect(() => {
    window.analytics?.page?.('Forms');
  }, []);

  const websiteId = match?.params?.websiteId;
  const [deleteFormModal, setDeleteFormModal] = useState();
  const tableQuery = useMemo(() => {
    formsQuery.args.websiteId = websiteId;
    return formsQuery;
  }, [websiteId]);

  const deleteForm = () => {
    if (!deleteFormModal) {
      return;
    }

    return forms
      .trashItem({
        id: deleteFormModal?.id,
        args: {
          websiteId: websiteId
        }
      })
      .then(() => setDeleteFormModal(null));
  };

  const { getTableProps: getTableQueryProps } = useTableQuery({
    getQuery: () => tableQuery
  });

  const getActionMenuItems = ({ item }) => {
    return [
      {
        label: 'Edit',
        onClick: () =>
          push(ROUTES.FORM_EDIT, {
            params: {
              websiteId: match?.params?.websiteId,
              formId: item?.id
            }
          })
      },
      {
        label: 'Delete',
        onClick: () => {
          setDeleteFormModal(item);
        }
      }
    ];
  };

  const columns = [
    {
      id: 'definition.name',
      width: 'auto',
      Header: 'NAME',
      Cell: TextCell
    },
    {
      id: 'definition.label',
      width: 'auto',
      Header: 'LABEL / TITLE',
      Cell: TextCell
    }
  ];

  return (
    <Screen>
      <Heading>Forms</Heading>
      <Body grey>Create/edit forms</Body>
      <Box
        justifyContent='space-between'
        alignItems='center'
        pt={'1.6rem'}
        pb={'1.6rem'}
      >
        <Link to={ROUTES.FORM_CREATE_NEW} params={{ websiteId }}>
          {({ onClick }) => (
            <PrimaryButton onClick={onClick}>Create New</PrimaryButton>
          )}
        </Link>
      </Box>
      <TableProvider
        columns={columns}
        {...getTableQueryProps()}
        getActionMenuItems={getActionMenuItems}
      >
        <Table />
        <SimplePagination />
      </TableProvider>
      {!!deleteFormModal && (
        <Dialog
          title={'Are you sure you want to delete this form?'}
          onClose={() => setDeleteFormModal(null)}
        >
          <Box justifyContent={'center'}>
            <ButtonGroup>
              <SecondaryButton onClick={() => setDeleteFormModal(null)}>
                Cancel
              </SecondaryButton>
              <DestructiveButton onClick={() => deleteForm()}>
                Delete
              </DestructiveButton>
            </ButtonGroup>
          </Box>
        </Dialog>
      )}
    </Screen>
  );
}

export default compose(
  withModel(formsModel, 'forms'),
  withQuery(pagesQuery)
)(FormsScreen);
