import React, { useCallback } from 'react';
import { Dialog } from '@rexlabs/dialog';
import { ReactForms, Form } from '@rexlabs/form';
import { withQuery, query } from '@rexlabs/model-generator';
import { ButtonGroup, GhostButton } from '@rexlabs/button';
import { createValidationRules } from '@rexlabs/validator';
import submissionsModel from 'data/models/entities/submissions';
import Box from '@rexlabs/box';
import Moment from 'react-moment';

const q = query`{
  ${submissionsModel} (websiteId: ${(p) => p?.match?.params?.websiteId}, id: ${(
  p
) => p.match.params.submissionId}, submissionId: ${(p) =>
  p.match.params.submissionId}) {
    id
    fields
    formatted_payload
    form_id
    created_at
  }
}`;

const validate = createValidationRules({
  name: 'required'
});

function SubmissionEditDialog({ match, submissions, onClose, ...props }) {
  const { data, status } = submissions?.item;

  const handleSubmit = useCallback(
    async (values) => {
      await submissions.createItem({
        data: {
          websiteId: match?.params?.websiteId,
          name: values?.name
        }
      });
      await submissions.refreshList();
      onClose();
    },
    [submissions, match, onClose]
  );

  /**
   * For now we are displaying as tabledata,
   * once issues with forms-api and form renderer are
   * sorted out we can move this to inputs for editing
   */
  const TableData = () => {
    const headerKeys = Object.keys(data.formatted_payload);

    return (
      <Box flex={1} flexDirection={'row'}>
        <Box flexDirection={'column'} width={'100%'}>
          {headerKeys.map((key) => {
            return (
              <Box key={key} flexDirection={'row'} padding={'10px'}>
                <Box style={{ textTransform: 'capitalize' }} width={'20%'}>
                  <b>{data?.formatted_payload[key].label}</b>
                </Box>
                <Box width={'80%'}>{data?.formatted_payload[key].value}</Box>
              </Box>
            );
          })}
          <Box flexDirection={'row'} padding={'10px'}>
            <Box width={'20%'}>
              <b>Date</b>
            </Box>
            <Box width={'80%'}>
              <Moment format={'hh:mma DD/MM/YYYY'}>{data?.created_at}</Moment>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Dialog title='View Submission' onClose={onClose} width='80rem' {...props}>
      <ReactForms handleSubmit={handleSubmit} validate={validate}>
        {() => (
          <Form>
            {status === 'loaded' && <TableData />}

            <ButtonGroup mt='2rem' end>
              <GhostButton onClick={onClose}>Close</GhostButton>
              {/* <PrimaryButton isLoading={isSubmitting} onClick={submitForm}>
                Save
              </PrimaryButton> */}
            </ButtonGroup>
          </Form>
        )}
      </ReactForms>
    </Dialog>
  );
}

export default withQuery(q)(SubmissionEditDialog);
