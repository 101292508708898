import React, { useEffect, useState, useMemo } from 'react';
import {
  TableProvider,
  Table,
  SimplePagination,
  TextCell,
  DateCell,
  useTableQuery
} from '@rexlabs/table';
import { Heading } from '@rexlabs/text';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'src/routes/app';
import Screen from 'view/components/screen';
import { useEntityListQuery } from '@rexlabs/model-generator';
import { formsQuery } from 'data/queries/forms';
import { generateSubmissionsQuery } from 'data/queries/submissions';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { GhostButton } from '@rexlabs/button';
import ChevronDown from '../../../assets/rexlabs-chevron-down.svg';
import userConfig from 'src/config';

const styles = StyleSheet({
  headerRow: {
    columnGap: '20px'
  },

  selectContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '300px',
    background: 'white',
    border: '1px solid rgb(147, 150, 154)',
    borderRadius: '4px'
  },

  select: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    padding: '0 32px 0 16px',
    width: '100%',
    border: 'none',
    borderRadius: '4px',
    appearance: 'none',
    background: 'transparent',
    height: '40px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },

  dropdownIcon: {
    position: 'relative',
    right: '16px',
    pointerEvents: 'none'
  }
});

const columns = [
  {
    id: 'form_id',
    width: 'auto',
    Header: 'Form ID',
    Cell: TextCell
  },
  {
    id: 'form_name',
    width: 'auto',
    Header: 'Form Name',
    Cell: TextCell
  },
  {
    id: 'created_at',
    width: 'auto',
    Header: 'Created At',
    Cell: DateCell
  }
];

function FormsSubmissionsScreen({ match }) {
  const s = useStyles(styles);
  const [fetchParams, setFetchParams] = useState({
    selectedFormId: null,
    pageIndex: 0
  });
  const websiteId = match?.params?.websiteId;

  // Track page views for analytics
  useEffect(() => {
    window.analytics?.page?.('Forms Submissions');
  }, []);

  // Define a unique refetch key based on fetch parameters
  const refetchKey = useMemo(
    () => `refetch-${fetchParams.selectedFormId}-${fetchParams.pageIndex}`,
    [fetchParams]
  );

  // Define queries using useMemo to prevent unnecessary recalculations
  const submissionsQuery = useMemo(
    () => ({
      ...generateSubmissionsQuery,
      args: {
        ...generateSubmissionsQuery.args,
        websiteId,
        formId: fetchParams.selectedFormId
      }
    }),
    [websiteId, fetchParams.selectedFormId]
  );

  const formsQueryWithKey = useMemo(
    () => ({
      ...formsQuery,
      args: { ...formsQuery.args, websiteId },
      refetchKey
    }),
    [websiteId, refetchKey]
  );

  // Fetch data using the queries defined above
  const { getTableProps } = useTableQuery({ getQuery: () => submissionsQuery });
  const allFormsQueryResult = useEntityListQuery(formsQueryWithKey);

  // Handle changes in filter and pagination
  const handleFilterChange = (formId) =>
    setFetchParams({ selectedFormId: formId, pageIndex: 0 });
  const handlePageChange = (newPageIndex) =>
    setFetchParams((prevParams) => ({
      ...prevParams,
      pageIndex: newPageIndex
    }));

  // Extract and transform data for the forms dropdown
  const allForms =
    allFormsQueryResult.data?.map((form) => ({
      id: form.id,
      name: form.name
    })) || [];

  // Define action menu items for each row
  const getActionMenuItems = ({ item }) => [
    {
      label: 'View',
      onClick: () =>
        push(ROUTES.FORMS_SUBMISSIONS.EDIT, {
          params: { websiteId, submissionId: item?.id }
        })
    }
  ];

  function DownloadButton(props) {
    const websiteId = props.websiteId;
    const formId = props.formId;

    if (formId) {
      return (
        <GhostButton
          onClick={() =>
            window.open(
              userConfig.API_URL +
                '/websites/' +
                websiteId +
                '/submissions/' +
                fetchParams.selectedFormId +
                '/export',
              '_blank'
            )
          }
        >
          Download Submissions
        </GhostButton>
      );
    }
    return '';
  }

  return (
    <Screen>
      <Heading>Submissions</Heading>
      <Box
        {...s('headerRow')}
        flex={1}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        marginTop={'10px'}
        marginBottom={'10px'}
      >
        <div {...s('selectContainer')}>
          <select
            {...s('select')}
            value={fetchParams.selectedFormId || ''}
            onChange={(e) => handleFilterChange(e.target.value || null)}
          >
            <option value=''>All Forms</option>
            {allForms.map((form) => (
              <option key={form.id} value={form.id}>
                {form.name}
              </option>
            ))}
          </select>
          <ChevronDown {...s('dropdownIcon')} />
        </div>

        <DownloadButton
          websiteId={websiteId}
          formId={fetchParams.selectedFormId}
        />
      </Box>

      <TableProvider
        key={refetchKey}
        columns={columns}
        getActionMenuItems={getActionMenuItems}
        {...getTableProps()}
      >
        <Table />
        <SimplePagination onPageChange={handlePageChange} />
      </TableProvider>
    </Screen>
  );
}

export default FormsSubmissionsScreen;
