import React, { useCallback } from 'react';
import { Dialog } from '@rexlabs/dialog';
import { ReactForms, Form, Field } from '@rexlabs/form';
import { UrlInput } from '@rexlabs/text-input';
import { withQuery } from '@rexlabs/model-generator';
import { ButtonGroup, GhostButton, PrimaryButton } from '@rexlabs/button';
import { createValidationRules } from '@rexlabs/validator';

import { redirectsQuery } from 'data/queries/redirects';

const validate = createValidationRules({
  redirects_from: 'required',
  redirects_to: 'required'
});

function CreateRedirectDialog({ match, redirects, onClose, ...props }) {
  const handleSubmit = useCallback(
    async (values) => {
      await redirects.createItem({
        data: {
          websiteId: match?.params?.websiteId,
          redirects_from: values?.redirects_from,
          redirects_to: values?.redirects_to
        }
      });
      await redirects.refreshList();
      onClose();
    },
    [redirects, match, onClose]
  );

  return (
    <Dialog
      title='Create New Redirect'
      onClose={onClose}
      width='40rem'
      {...props}
    >
      <ReactForms handleSubmit={handleSubmit} validate={validate}>
        {({ isSubmitting, submitForm }) => (
          <Form>
            <Field
              fullWidth
              optional={false}
              Input={UrlInput}
              name='redirects_from'
              label='From'
            />
            <Field
              fullWidth
              optional={false}
              Input={UrlInput}
              name='redirects_to'
              label='To'
            />
            <ButtonGroup mt='2rem' end>
              <GhostButton onClick={onClose}>Cancel</GhostButton>
              <PrimaryButton
                type='submit'
                isLoading={isSubmitting}
                onClick={submitForm}
              >
                Save
              </PrimaryButton>
            </ButtonGroup>
          </Form>
        )}
      </ReactForms>
    </Dialog>
  );
}

export default withQuery(redirectsQuery)(CreateRedirectDialog);
