import React, { useCallback } from 'react';
import { Dialog } from '@rexlabs/dialog';
import { ReactForms, Form, Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { PasswordInput } from '@rexlabs/password-input';
import { withQuery, withModel } from '@rexlabs/model-generator';
import { ButtonGroup, GhostButton, PrimaryButton } from '@rexlabs/button';
import { createValidationRules } from '@rexlabs/validator';
import { compose } from 'redux';

import sessionsModel from 'data/models/custom/session';
import { usersQuery } from 'data/queries/users';

const validate = createValidationRules({
  first_name: 'required',
  last_name: 'required',
  email: 'required|email',
  password: 'required'
});

function CreateDomainDialog({ match, users, session, onClose, ...props }) {
  const handleSubmit = useCallback(
    async (values) => {
      await users.createItem({
        data: {
          first_name: values?.first_name,
          last_name: values?.last_name,
          email: values?.email,
          password: values?.password,
          roles: 'administrator',
          website_id: match?.params?.websiteId
        }
      });
      window.analytics?.track?.('siteloft.user_added', {
        email: values?.email
      });
      await users.refreshList();
      onClose();
    },
    [users, onClose]
  );

  return (
    <Dialog title='New User' onClose={onClose} width='40rem' {...props}>
      <ReactForms handleSubmit={handleSubmit} validate={validate}>
        {({ isSubmitting, submitForm }) => (
          <Form>
            <Field
              fullWidth
              optional={false}
              Input={TextInput}
              name='first_name'
              label='First Name'
            />
            <Field
              fullWidth
              optional={false}
              Input={TextInput}
              name='last_name'
              label='Last Name'
            />
            <Field
              fullWidth
              optional={false}
              Input={TextInput}
              name='email'
              label='Email'
            />
            <Field
              fullWidth
              optional={false}
              Input={PasswordInput}
              name='password'
              label='Password'
            />
            <ButtonGroup mt='2rem' end>
              <GhostButton onClick={onClose}>Cancel</GhostButton>
              <PrimaryButton
                type='submit'
                isLoading={isSubmitting}
                onClick={submitForm}
              >
                Save
              </PrimaryButton>
            </ButtonGroup>
          </Form>
        )}
      </ReactForms>
    </Dialog>
  );
}

export default compose(
  withQuery(usersQuery),
  withModel(sessionsModel)
)(CreateDomainDialog);
