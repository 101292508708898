import { query } from '@rexlabs/model-generator';
import syncsModel from 'data/models/entities/syncs';

export const syncsQuery = query`{
  ${syncsModel} (websiteId: ${(p) => p?.match?.params?.websiteId}) {
    id
    label
    source_name
    sync_frequency
    source_options
    last_run
  }
}`;
