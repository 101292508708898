import React from 'react';
import Box from '@rexlabs/box';
import { Form, Field } from '@rexlabs/form';
import { PrimaryButton } from '@rexlabs/button';
import { ColorPickerInput } from 'view/components/color-picker';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Heading, Body } from '@rexlabs/text';
import { TextArea, TextInput } from '@rexlabs/text-input';
import VersatileSiteStylesSettings from './personas/versatile/VersatileSiteStylesSettings';
import ClassicSiteStylesSettings from './personas/classic/ClassicSiteStyleSettings';
import MinimalSiteStylesSettings from './personas/minimal/MinimalSiteStylesSettings';

export const mapPropsToValues = (props) => {
  const { settings } = props;
  const settingsData = settings.list.items;

  const data = Object.assign(
    {},
    ...settingsData.map((object) => {
      switch (object.type) {
        case 'image':
          return {
            [object.key]: object?.value?.data
              ? object?.value.data
              : object?.value
          };
        default:
          return { [object.key]: object?.value ? object?.value[0] : null };
      }
    })
  );

  return data;
};

const defaultStyles = StyleSheet({
  fieldContainer: {
    padding: '10px 0'
  },
  subtitle: {
    color: '#676C7E'
  }
});

function EditSiteStylesSettings({
  isSubmitting,
  submitForm,
  settings,
  theme,
  styles: s
}) {
  return (
    <Box p={24}>
      <Form name='siteStyles'>
        <Box
          {...s('fieldContainer')}
          flex={1}
          flexDirection='row'
          alignItems='center'
        >
          <h1
            style={{
              fontSize: '28px',
              marginRight: '20px',
              fontWeight: 700,
              lineHeight: '28px'
            }}
          >
            Company Branding
          </h1>
          <PrimaryButton
            onClick={(e) => {
              window.analytics?.track?.('siteloft.design', {
                action: 'updated header behaviour',
                location: 'Header behaviour'
              });

              submitForm(e);
            }}
            isLoading={isSubmitting}
          >
            Save
          </PrimaryButton>
        </Box>
        <Body {...s('subtitle')}>
          Customise your Siteloft theme with your personal branding to make it
          feel unique and personal.
        </Body>

        <Box {...s('fieldContainer')}>
          <Field
            fullWidth
            label='Primary colour'
            name='primary_color'
            Input={ColorPickerInput}
            optional={false}
            id={'primary-colour-field'}
          />
        </Box>

        <Box {...s('fieldContainer')}>
          <Field
            fullWidth
            label='Secondary colour'
            name='secondary_color'
            Input={ColorPickerInput}
            optional={false}
            id={'secondary-colour-field'}
          />
        </Box>

        {theme === 'byron' ? (
          <ClassicSiteStylesSettings settings={settings} />
        ) : null}
        {['versatile', 'minimal'].includes(theme) ? (
          <VersatileSiteStylesSettings settings={settings} />
        ) : null}
        {theme === 'minimal' ? (
          <MinimalSiteStylesSettings settings={settings} />
        ) : null}

        <Box flexDirection='row'></Box>

        <Heading>Custom Fonts</Heading>

        <Body>
          For self-hosted fonts first upload the font file(s) to the Media
          Library and copy the full URL to the file(s)
        </Body>

        <Body bold>Heading Font</Body>

        <Box {...s('fieldContainer')}>
          <Field
            name='custom_heading_font'
            label='Custom Heading Font File'
            Input={TextArea}
            optional={false}
            HelpTooltipContent={() =>
              'Either input the full URL to the font file, or the @import or <link> embed code from a cloud font host'
            }
          />
        </Box>

        <Box {...s('fieldContainer')}>
          <Field
            name='custom_heading_font_name'
            label='Custom Heading Font Name'
            Input={TextInput}
            optional={true}
            HelpTooltipContent={() =>
              'Required for cloud fonts - otherwise optional'
            }
          />
        </Box>

        <Body bold>Body Font</Body>

        <Box {...s('fieldContainer')}>
          <Field
            name='custom_body_font'
            label='Custom Body Font File'
            Input={TextArea}
            optional={false}
            HelpTooltipContent={() =>
              'Either input the full URL to the font file, or the @import or <link> embed code from a cloud font host'
            }
          />
        </Box>

        <Box {...s('fieldContainer')}>
          <Field
            name='custom_body_font_name'
            label='Custom Body Font Name'
            Input={TextInput}
            optional={true}
            HelpTooltipContent={() =>
              'Required for cloud fonts - otherwise optional'
            }
          />
        </Box>
      </Form>
    </Box>
  );
}

export default styled(defaultStyles)(EditSiteStylesSettings);
