import React, { useEffect, useState, useMemo } from 'react';

import { Field, Form } from '@rexlabs/form';
import Box from '@rexlabs/box';
import { TextArea, TextInput } from '@rexlabs/text-input';
import { PrimaryButton, SecondaryButton } from '@rexlabs/button';
import { Body } from '@rexlabs/text';
import { Dropdown } from '@rexlabs/select';
import { StyleSheet, styled } from '@rexlabs/styling';

import PopupTriggers from 'view/dialogs/marketing/popups/popup-triggers';

const POSITION_OPTIONS = {
  BANNER: [
    { value: 'top', label: 'Top' },
    { value: 'bottom', label: 'Bottom' }
  ],
  STANDARD: [
    { value: 'top_center', label: 'Top Center' },
    { value: 'right_center', label: 'Right Center' },
    { value: 'left_center', label: 'Left Center' },
    { value: 'center', label: 'Center' }
  ]
};

const ANIMATION_OPTIONS = {
  BANNER: [
    { value: 'slide', label: 'Slide' },
    { value: 'none', label: 'None' }
  ],
  STANDARD: [
    { value: 'fade', label: 'Fade' },
    { value: 'slide', label: 'Slide' },
    { value: 'none', label: 'None' }
  ]
};

const TYPE_OPTIONS = [
  { value: 'standard', label: 'Standard' },
  { value: 'banner', label: 'Banner' }
];

const defaultStyles = StyleSheet({
  container: {
    marginBottom: 100
  },
  title: {
    fontWeight: 'bold'
  },
  field: {
    padding: '10px 0'
  },
  btnRow: {
    position: 'absolute',
    width: '100vh',
    background: 'white',
    padding: '15px 0',
    bottom: 0
  }
});

function EditPopupForm({
  styles: s,
  submitForm,
  isSubmitting,
  values,
  pages,
  triggers,
  handleAddTrigger,
  forms,
  closeModal
}) {
  const popupType = values?.type?.value ?? 'standard';
  const triggerType = values?.triggerType?.value;

  const [positionOptions, setPositionOptions] = useState(
    POSITION_OPTIONS.STANDARD
  );

  const formList = forms?.list?.items?.map?.((form) => ({
    value: form?.id,
    label: form?.definition?.name
  }));

  useEffect(() => {
    if (popupType === 'banner') {
      setPositionOptions(POSITION_OPTIONS.BANNER);
    } else {
      setPositionOptions(POSITION_OPTIONS.STANDARD);
    }
  }, [popupType]);

  const animationOptions = useMemo(() => {
    return ANIMATION_OPTIONS[popupType.toUpperCase()];
  }, [popupType]);

  return (
    <Form>
      <Box {...s('container')}>
        <Body {...s('title')}>Content</Body>
        <Box flexDirection='column' flex={1}>
          <Box {...s('field')} flex={1}>
            <Field fullWidth label='Popup Name' name='name' Input={TextInput} />
          </Box>

          <Box {...s('field')} flex={1}>
            <Field
              fullWidth
              label='Popup Type'
              name='type'
              Input={Dropdown}
              inputProps={{
                items: TYPE_OPTIONS
              }}
            />
          </Box>

          <Box {...s('field')} flex={1}>
            <Field
              fullWidth
              label='Popup Title'
              name='title'
              Input={TextInput}
            />
          </Box>

          <Box {...s('field')}>
            <Field fullWidth label='Content' name='content' Input={TextArea} />
          </Box>

          {/* TODO: Hook up to forms api and change to select */}
          {popupType !== 'banner' && (
            <Box {...s('field')} flex={1}>
              <Field
                fullWidth
                label='Form'
                name='form'
                Input={Dropdown}
                inputProps={{
                  items: formList,
                  shouldAddFreeformValue: true
                }}
              />
            </Box>
          )}

          <>
            <Body {...s('title')}>Display</Body>
            <Box {...s('field')} flex={1}>
              <Field
                fullWidth
                label='Custom CSS Class'
                name='css'
                Input={TextInput}
              />
            </Box>

            {popupType !== 'banner' && (
              <Box {...s('field')} flex={1}>
                <Field
                  fullWidth
                  label={'Popup Size'}
                  name={'size'}
                  Input={Dropdown}
                  inputProps={{
                    items: [
                      { value: 'small', label: 'Small' },
                      { value: 'medium', label: 'Medium' },
                      { value: 'large', label: 'Large' }
                    ]
                  }}
                />
              </Box>
            )}

            <Box {...s('field')} flex={1}>
              <Field
                fullWidth
                label={'Popup Position'}
                name={'position'}
                Input={Dropdown}
                inputProps={{
                  items: positionOptions
                }}
              />
            </Box>

            <Box {...s('field')} flex={1}>
              <Field
                fullWidth
                label={'Popup Animation'}
                name={'animation'}
                Input={Dropdown}
                inputProps={{
                  items: animationOptions
                }}
              />
            </Box>
          </>

          <Body {...s('title')}>Triggers</Body>

          <PopupTriggers
            pages={pages}
            forms={forms}
            type={triggerType}
            triggers={triggers}
            handleAddTrigger={handleAddTrigger}
          />

          <Box {...s('btnRow')} flexDirection='row' justifyContent='flex-end'>
            <Box mr={10}>
              <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
            </Box>
            <PrimaryButton
              onClick={submitForm}
              isLoading={isSubmitting}
              isDisabled={isSubmitting}
            >
              Submit
            </PrimaryButton>
          </Box>
        </Box>
      </Box>
    </Form>
  );
}

export default styled(defaultStyles)(EditPopupForm);
