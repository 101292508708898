import Generator from '../generator';
import { api } from 'utils/api-client';

const config = {
  entities: {
    api: {
      fetchList: (type, { websiteId, ...args }) =>
        api.get(`/websites/${websiteId}/files`, args),
      createItem: (type, { websiteId, ...args }) =>
        api.post(`/websites/${websiteId}/files`, args),
      fetchItem: (type, { websiteId, ...args }, id) =>
        api.get(`/websites/${websiteId}/files/${id}`, args)
    }
  }
};

export default new Generator('files', config).createEntityModel();
