import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ReactForms } from '@rexlabs/form';
import { withQuery, query, useEntityQuery } from '@rexlabs/model-generator';
import settings from 'data/models/entities/settings';
import { withToast } from 'view/components/toast';
import PreviewLayout from 'view/layouts/preview';
import { compose } from 'redux';
import { RenderLoading } from 'view/components/render-loading';
import EditSiteStylesSettings, {
  mapPropsToValues
} from 'view/forms/edit-site-styles-settings';
import { websiteQuery } from 'data/queries/websites';
import { getThemeQuery } from 'data/queries/themes';

function getWebsiteId({ match }) {
  return match.params?.websiteId;
}

function getSettingsKeys() {
  const siteSettingsKeys = [
    'primary_color',
    'secondary_color',
    'primary_font_color',
    'property_banner_color',
    'property_banner_text_color',
    'desktop_logo',
    'desktop_logo_size',
    'mobile_logo',
    'mobile_logo_size',
    'secondary_logo',
    'secondary_logo_size',
    'tertiary_logo',
    'tertiary_logo_size',
    'logo_alt_text',
    'boxed_logo',
    'gallery_background_color',
    'custom_heading_font',
    'custom_body_font',
    'custom_heading_font_name',
    'custom_body_font_name'
  ];

  return `key.in(["${siteSettingsKeys.join('", "')}"])`;
}

const siteStylesQuery = query`{
  ${settings} (websiteId: ${getWebsiteId}, q: ${getSettingsKeys})  {
    key
    value
    label
    type
    category
  }
 }
`;

function SiteStylesScreen(props) {
  const { settings, addToast } = props;
  const { theme_id: themeId } = props.websites.item.data;
  const defaultPreviewProps = {
    websiteId: getWebsiteId(props),
    values: {
      settings: {}
    }
  };
  const [previewProps, setPreviewProps] = useState(defaultPreviewProps);
  const initialPreviewPropsSet = useRef(false);

  const themeQuery = useMemo(() => {
    if (themeId) return getThemeQuery(themeId);
  }, [themeId]);

  const { status: themeStatus, data: themeData } = useEntityQuery(themeQuery);

  const isLoading =
    !(props.settings.list.status === 'loaded') || themeStatus === 'loading';

  const handleSubmit = async (values) => {
    try {
      const data = Object.keys(values).reduce((acc, key) => {
        let value = values[key];

        // We never want to send BE an empty array
        // Empty values should just be null
        if (Array.isArray(value) && value.length === 0) {
          value = null;
        }

        acc.push({
          key: key,
          value: [value?.value ?? value?.id ?? value],
          category: 'style',
          type:
            key === 'desktop_logo' ||
            key === 'mobile_logo' ||
            key === 'secondary_logo' ||
            key === 'tertiary_logo'
              ? 'image'
              : null
        });

        return acc;
      }, []);

      await settings.updateSettings({
        websiteId: props.match.params?.websiteId,
        data: {
          settings: data
        }
      });

      setPreviewProps({
        ...defaultPreviewProps,
        values: { ...defaultPreviewProps, settings: values }
      });
    } catch (e) {
      addToast({
        title: 'Error',
        type: 'error',
        content: e?.message
      });
    }
  };

  useEffect(() => {
    window.analytics?.page?.('Site Styles');
  }, []);

  useEffect(() => {
    if (initialPreviewPropsSet.current || isLoading) return;
    initialPreviewPropsSet.current = true;

    setPreviewProps({
      ...defaultPreviewProps,
      values: { ...defaultPreviewProps, settings: mapPropsToValues(props) }
    });
  }, [isLoading, props, defaultPreviewProps]);

  return (
    <RenderLoading isLoading={isLoading}>
      <ReactForms
        initialValues={mapPropsToValues(props)}
        handleSubmit={handleSubmit}
      >
        {({ isSubmitting, submitForm }) => {
          return (
            <PreviewLayout
              Settings={
                <EditSiteStylesSettings
                  isSubmitting={isSubmitting}
                  submitForm={submitForm}
                  settings={settings}
                  theme={themeData?.key}
                />
              }
              previewProps={previewProps}
            />
          );
        }}
      </ReactForms>
    </RenderLoading>
  );
}

export default compose(
  withToast,
  withQuery(siteStylesQuery),
  withQuery(websiteQuery)
)(SiteStylesScreen);
