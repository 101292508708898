import React, { useCallback } from 'react';
import { Dialog } from '@rexlabs/dialog';
import { ReactForms, Form, Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import CodeInput from '@rexlabs/code-input';
import { withQuery } from '@rexlabs/model-generator';
import { ButtonGroup, GhostButton, PrimaryButton } from '@rexlabs/button';
import { Dropdown } from '@rexlabs/select';
import { syncsQuery } from 'data/queries/syncs';
import Box from '@rexlabs/box';
import JupixSyncFields from 'view/dialogs/account/syncs/jupix-sync-fields';
import ReapitFoundationSyncFields from 'view/dialogs/account/syncs/reapit-foundation-sync-fields';
import ReapitSyncFields from 'view/dialogs/account/syncs/reapit-sync-fields';

// const validate = createValidationRules({
//   name: 'required'
// });

function CreateSyncDialog({ match, syncs, onClose, ...props }) {
  const handleSubmit = useCallback(
    async (values) => {
      const { label, source_name, sync_frequency, ...options } = values;

      if (options.filter) {
        // Turn the filter string into an object
        options.filter = JSON.parse(options.filter);
      }

      await syncs.createItem({
        data: {
          label: label,
          source_name: source_name.value,
          sync_frequency: sync_frequency.value,
          source_options: options
        }
      });
      await syncs.refreshList();
      onClose();
    },
    [syncs, onClose]
  );

  return (
    <Dialog title='Add a sync' onClose={onClose} width='40rem' {...props}>
      <ReactForms handleSubmit={handleSubmit}>
        {({ values, isSubmitting, submitForm }) => (
          <Form>
            <Field
              label='Sync Source'
              name='source_name'
              optional={false}
              Input={Dropdown}
              inputProps={{
                items: [
                  { label: 'Rex', value: 'rex' },
                  { label: 'DezRez', value: 'dezrez' },
                  { label: 'Alto', value: 'alto' },
                  { label: 'Jupix', value: 'jupix' },
                  { label: 'Reapit', value: 'reapit' },
                  { label: 'Reapit Foundation', value: 'reapit_foundation' }
                ]
              }}
              required
            />
            <Field label='Sync Label' name='label' Input={TextInput} required />
            <Field
              fullWidth
              Input={Dropdown}
              optional={false}
              inputProps={{
                items: [
                  { label: 'Hourly', value: '3600' },
                  { label: 'Twice daily', value: '43200' },
                  { label: 'Daily', value: '86400' }
                ]
              }}
              name='sync_frequency'
              label='Sync frequency'
            />

            <Box>
              {(values?.source_name?.value === 'rex' && (
                <>
                  <Box flex={1}>
                    <Field
                      label='Account username'
                      name='username'
                      optional={false}
                      Input={TextInput}
                      required
                    />
                  </Box>
                  <Box flex={1}>
                    <Field
                      label='Password'
                      name='password'
                      optional={false}
                      Input={TextInput}
                      inputProps={{
                        type: 'password'
                      }}
                      required
                    />
                  </Box>
                  <Box flex={1}>
                    <Field
                      label='Account ID'
                      name='account_id'
                      optional={false}
                      Input={TextInput}
                      inputProps={{
                        type: 'number'
                      }}
                      required
                    />
                  </Box>
                </>
              )) ||
                (values?.source_name?.value === 'dezrez' && (
                  <Box flex={1}>
                    <Field
                      label='API Key'
                      name='api_key'
                      optional={false}
                      Input={TextInput}
                      required
                    />
                  </Box>
                )) ||
                (values?.source_name?.value === 'alto' && (
                  <>
                    <Field
                      label='Account username'
                      name='username'
                      Input={TextInput}
                      required
                    />
                    <Field
                      label='Password'
                      name='password'
                      Input={TextInput}
                      inputProps={{
                        type: 'password'
                      }}
                      required
                    />
                    <Field
                      label='Datafeed ID'
                      name='datafeed_id'
                      Input={TextInput}
                      required
                    />
                    <Field
                      label='Client ID'
                      name='client_id'
                      Input={TextInput}
                      inputProps={{
                        type: 'number'
                      }}
                      required
                    />
                  </>
                ))}

              {values?.source_name?.value === 'jupix' && <JupixSyncFields />}
              {values?.source_name?.value === 'reapit_foundation' && (
                <ReapitFoundationSyncFields />
              )}
              {values?.source_name?.value === 'reapit' && <ReapitSyncFields />}

              {['dezrez', 'rex'].includes(values?.source_name?.value) && (
                <Box flex={1}>
                  <Field
                    label={'Filter'}
                    name={'filter'}
                    optional={false}
                    Input={CodeInput}
                  />
                </Box>
              )}
            </Box>

            <ButtonGroup mt='2rem' end>
              <GhostButton onClick={onClose}>Cancel</GhostButton>
              <PrimaryButton isLoading={isSubmitting} onClick={submitForm}>
                Save
              </PrimaryButton>
            </ButtonGroup>
          </Form>
        )}
      </ReactForms>
    </Dialog>
  );
}

export default withQuery(syncsQuery)(CreateSyncDialog);
