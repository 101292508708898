import { InputProps } from 'view/components/theme-settings/theme-setting-input';
import { Dropdown } from '@rexlabs/select';

export const CONTENT_BLOCK_ALIGNMENT = {
  left: { value: 'left', label: 'Left' },
  right: { value: 'right', label: 'Right' },
  center: { value: 'center', label: 'Centre' }
};

export function contentBlockAlignment(): InputProps {
  return {
    input: Dropdown,
    inputProps: {
      items: Object.values(CONTENT_BLOCK_ALIGNMENT)
    }
  };
}
