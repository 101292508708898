import Generator from '../generator';
import { api } from 'utils/api-client';

const config = {
  entities: {
    api: {
      fetchList: (type, args) =>
        api.get(`/websites/${args.websiteId}/submissions`, args),
      createItem: (type, args) =>
        api.post(`/websites/${args.websiteId}/submissions`, args),
      fetchItem: (type, args) => {
        return api.get(
          `/websites/${args.websiteId}/submissions/${args.submissionId}`
        );
      }
    }
  }
};

export default new Generator('submissions', config).createEntityModel();
