import { query } from '@rexlabs/model-generator';
import domainsModel from 'data/models/entities/domains';

export const domainsQuery = query`{
  ${domainsModel} (websiteId: ${(p) => p?.match?.params?.websiteId}) {
    id
    domain
    is_primary
    redirect_to_path
    is_microsite
  }
}`;

export const domainQuery = query`{
  ${domainsModel} (websiteId: ${(p) => p?.match?.params?.websiteId}, id: ${(
  p
) => p?.match?.params?.domainId}) {
    id
    domain
    is_primary
    redirect_to_path
    is_microsite
  }
}`;
