/*
|-------------------------------------------------------------------------------
| API Client
|-------------------------------------------------------------------------------
|
| The singleton API client is created with @rexlabs/api-client. It includes:
|  - Base URL sourced from the env config
|  - Default headers for laravel servers
|  - Middleware (interceptors) for common tasks
|
*/

import _ from 'lodash';
import { create } from '@rexlabs/api-client';
import config from 'config';

const clientConfig = {
  baseURL: config.API_URL
};
const api = create(clientConfig);
api.setHeader('Accept', 'application/json');
api.setHeader('Content-Type', 'application/json');
api.addResponseInterceptor(flattenResponseData);
api.addResponseInterceptor(printError);

function setAuthToken(apiToken) {
  api.setHeader('Authorization', `Bearer ${apiToken}`);
}

function setAccountId(accountId) {
  api.setHeader('X-Account-Id', accountId);
}

export { api, setAuthToken, setAccountId };

/**
 * Flattens res.data.error or res.data.data to res.data. Also hoists
 * res.data.meta to res.meta.
 */
function flattenResponseData(response) {
  if (_.isPlainObject(response.data) && response.data.data) {
    response.pagination = response.data.pagination;
    response.data = response.data.error || response.data.data;
  }
  return response;
}

function printError(response) {
  if (response.problem) {
    const e = new Error(
      response?.data?.error?.display_message || response?.data?.error?.message
    );
    e.serverCode = response?.data?.code;
    e.statusCode = response?.status;
    e.statusText = response?.statusText;

    if (__DEV__) {
      console.error('API Error:', response.data);
      console.error(e);
    }

    return Promise.reject(e);
  }
  return response;
}
