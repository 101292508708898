import React, { useCallback } from 'react';
import { Dialog } from '@rexlabs/dialog';
import { ReactForms, Form, Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { Checkbox } from '@rexlabs/checkbox';
import { withQuery } from '@rexlabs/model-generator';
import { ButtonGroup, GhostButton, PrimaryButton } from '@rexlabs/button';
import { createValidationRules } from '@rexlabs/validator';

import { domainQuery } from 'data/queries/domains';

const validate = createValidationRules({
  domain: 'required',
  is_primary: 'required'
});

function EditDomainDialog({ match, domains, onClose, ...props }) {
  const domain = domains?.item?.data;

  const handleSubmit = useCallback(
    async (values) => {
      await domains.updateItem({
        data: {
          websiteId: match?.params?.websiteId,
          domain: values?.domain,
          is_primary: values?.is_primary,
          redirect_to_path: values?.redirect_to_path,
          is_microsite: values?.is_microsite
        }
      });
      onClose();
    },
    [domains, match, onClose]
  );

  return (
    <Dialog title='Edit Domain' onClose={onClose} width='40rem' {...props}>
      <ReactForms
        handleSubmit={handleSubmit}
        validate={validate}
        initialValues={{
          domain: domain?.domain,
          is_primary: domain?.is_primary,
          redirect_to_path: domain?.redirect_to_path,
          is_microsite: domain?.is_microsite
        }}
        asyncValuesReady={domains?.item?.status === 'loaded'}
      >
        {({ isSubmitting, submitForm }) => (
          <Form>
            <Field
              fullWidth
              Input={TextInput}
              name='domain'
              label='Domain'
              optional={false}
            />
            <Field
              fullWidth
              Input={Checkbox}
              name='is_primary'
              label='Primary'
              optional={true}
            />
            <Field
              fullWidth
              Input={TextInput}
              name='redirect_to_path'
              label='Redirects to path'
              optional={true}
            />
            <Field
              fullWidth
              Input={Checkbox}
              name='is_microsite'
              label='Acts as microsite'
              optional={true}
            />
            <ButtonGroup mt='2rem' end>
              <GhostButton onClick={onClose}>Cancel</GhostButton>
              <PrimaryButton isLoading={isSubmitting} onClick={submitForm}>
                Save
              </PrimaryButton>
            </ButtonGroup>
          </Form>
        )}
      </ReactForms>
    </Dialog>
  );
}

export default withQuery(domainQuery)(EditDomainDialog);
