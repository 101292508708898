/* eslint-disable */
/* NOTE: ideally we use @rexlabs/analytics instead! */

export function initSprig(sprigId) {
  sprigId &&
    (function(l,e,a,p) {
      if (window.Sprig) return;
      window.Sprig = function(){S._queue.push(arguments)}
      var S = window.Sprig;S.appId = a;S._queue = [];window.UserLeap=S;
      a=l.createElement('script');
      a.async=1;a.src=e+'?id='+S.appId;
      p=l.getElementsByTagName('script')[0];
      p.parentNode.insertBefore(a, p);
    })(document, 'https://cdn.sprig.com/shim.js', sprigId);
}
