/* eslint-disable */

import React, { useMemo, useState } from 'react';
import Box from '@rexlabs/box';
import {
  TableProvider,
  Table,
  SimplePagination,
  TextCell,
  useTableQuery
} from '@rexlabs/table';
import { Link } from '@rexlabs/react-whereabouts';
import { Heading, Body } from '@rexlabs/text';
import {
  ButtonGroup,
  DestructiveButton,
  PrimaryButton,
  SecondaryButton
} from '@rexlabs/button';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'src/routes/app';
import { actionsQuery } from 'data/queries/actions';
import Screen from 'view/components/screen';
import { Dialog } from '@rexlabs/dialog';
import { withModel, withQuery } from '@rexlabs/model-generator';
import actionsModel from 'data/models/entities/actions';

function FormActionsList({ match, actions }) {
  const websiteId = match?.params?.websiteId;
  const formId = match?.params?.formId;
  const [deleteActionModal, setDeleteActionModal] = useState();
  const tableQuery = useMemo(() => {
    actionsQuery.args.websiteId = websiteId;
    actionsQuery.args.formId = formId;
    return actionsQuery;
  }, [websiteId, formId]);

  const deleteAction = () => {
    if (!deleteActionModal) {
      return;
    }

    return actions
      .trashItem({
        id: deleteActionModal?.id,
        args: {
          websiteId: websiteId,
          formId: formId
        }
      })
      .then(() => setDeleteActionModal(null));
  };

  const { getTableProps: getTableQueryProps } = useTableQuery({
    getQuery: () => tableQuery
  });

  const columns = [
    {
      id: 'name',
      width: 'auto',
      Header: 'Name',
      Cell: TextCell
    },
    {
      id: 'type.label',
      width: 'auto',
      Header: 'Type',
      Cell: TextCell
    }
  ];

  const getActionMenuItems = ({ item }) => {
    return [
      {
        label: 'Edit',
        onClick: () => {
          push(ROUTES.FORM_EDIT.EDIT_ACTION, {
            params: {
              websiteId: match?.params?.websiteId,
              formId: formId,
              actionId: item?.id
            }
          })
        }
      },
      {
        label: 'Delete',
        onClick: () => setDeleteActionModal(item)
      }
    ];
  };

  return (
    <Screen>
      {formId ? (
        <>
          <Body grey>Create/edit post submission actions</Body>
          <Box justifyContent='space-between' alignItems='center'>
            <Link
              to={ROUTES.FORM_EDIT.NEW_ACTION}
              params={{ websiteId, formId }}
            >
              {({ onClick }) => (
                <PrimaryButton onClick={onClick}>Create New</PrimaryButton>
              )}
            </Link>
          </Box>
          <TableProvider columns={columns} {...getTableQueryProps()} getActionMenuItems={getActionMenuItems}>
            <Table />
            <SimplePagination />
          </TableProvider>
        </>
      ) : (
        <div>
          <span>Form must be created before actions can be added.</span>
        </div>
      )}
      {!!deleteActionModal && (
        <Dialog
          title={'Are you sure you want to delete this action?'}
          onClose={() => setDeleteActionModal(null)}
        >
          <Box justifyContent={'center'}>
            <ButtonGroup>
              <SecondaryButton onClick={() => setDeleteActionModal(null)}>
                Cancel
              </SecondaryButton>
              <DestructiveButton onClick={() => deleteAction()}>
                Delete
              </DestructiveButton>
            </ButtonGroup>
          </Box>
        </Dialog>
      )}
    </Screen>
  );
}

export default withModel(actionsModel, 'actions')(FormActionsList);
