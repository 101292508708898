import { InputProps } from 'view/components/theme-settings/theme-setting-input';
import { Dropdown } from '@rexlabs/select';

const options = [
  { value: 'three_columns', label: '3 Columns' },
  { value: 'four_columns', label: '4 Columns' }
];

export function getColumnAmountInput(): InputProps {
  return {
    input: Dropdown,
    inputProps: {
      items: options
    }
  };
}
