import React, { useEffect } from 'react';
import { compose } from 'redux';
import Box from '@rexlabs/box';
import { ReactForms, Form, Field } from '@rexlabs/form';
import { RenderLoading } from 'view/components/render-loading';
import { withQuery, query } from '@rexlabs/model-generator';
import settings from 'data/models/entities/settings';
import { handleSettingsSubmit } from 'utils/handle-settings-submit';
import { PrimaryButton } from '@rexlabs/button';
import { TextInput } from '@rexlabs/text-input';
import { withToast } from 'view/components/toast';
import { Heading } from '@rexlabs/text';

function getWebsiteId(props) {
  return props?.match?.params?.websiteId;
}

const settingQuery = query`{
  ${settings} (websiteId: ${getWebsiteId}, category: site_info) {
    key
    value
    label
    type
    category
  }
}`;

const customTerminology = {
  name: 'customTerminology',
  mapPropsToValues: (props) => {
    const { settings } = props;
    const settingsData = settings?.list?.items;

    const data = Object.assign(
      {},
      ...settingsData.map((object) => {
        return { [object.key]: object?.value ? object?.value[0] : null };
      })
    );
    return data;
  },
  handleSubmit: handleSettingsSubmit,
  asyncValuesReady: (props) => {
    return props.settings.list.status === 'loaded';
  }
};

function CustomTerminology(props) {
  const { settings } = props;
  const trackSave = () => {
    window.analytics?.track?.('siteloft.design', {
      action: 'updated header behaviour',
      location: 'Header behaviour'
    });
  };

  useEffect(() => {
    window.analytics?.page?.('Custom Terminology');
  }, []);

  return (
    <Box flex={1} style={{ padding: '20px 20px 100px 20px', overflow: 'auto' }}>
      <RenderLoading isLoading={settings.list.status !== 'loaded'}>
        <ReactForms
          initialValues={customTerminology.mapPropsToValues(props)}
          handleSubmit={(values) =>
            customTerminology.handleSubmit(values, { props: props })
          }
        >
          {({ submitForm, isSubmitting }) => (
            <Form name='customCss' style={{ width: '100%' }}>
              <Box
                flex={1}
                flexDirection={'row'}
                padding='10px'
                alignItems={'center'}
              >
                <h1
                  style={{
                    fontSize: '28px',
                    fontWeight: '700',
                    marginRight: '20px'
                  }}
                >
                  {' '}
                  Custom Terminology{' '}
                </h1>

                <PrimaryButton
                  onClick={(e) => {
                    trackSave();
                    submitForm(e);
                  }}
                  isLoading={isSubmitting}
                >
                  Save
                </PrimaryButton>
              </Box>

              <Heading
                style={{
                  marginTop: '40px',
                  marginBottom: '20px',
                  marginLeft: '10px'
                }}
              >
                Listing Status
              </Heading>

              <Box padding='10px' flex={1}>
                <Field
                  name='terminology_available'
                  label='Available'
                  Input={TextInput}
                  optional={false}
                />
              </Box>

              <Box padding='10px' flex={1}>
                <Field
                  name='terminology_underoffer'
                  label='Under Offer (Sold STC in UK)'
                  Input={TextInput}
                  optional={false}
                />
              </Box>

              <Box padding='10px' flex={1}>
                <Field
                  name='terminology_sold'
                  label='Sold'
                  Input={TextInput}
                  optional={false}
                />
              </Box>

              <Box padding='10px' flex={1}>
                <Field
                  name='terminology_buy'
                  label='Buy'
                  Input={TextInput}
                  optional={false}
                />
              </Box>

              <Box padding='10px' flex={1}>
                <Field
                  name='terminology_lease'
                  label='Lease'
                  Input={TextInput}
                  optional={false}
                />
              </Box>

              <Box padding='10px' flex={1}>
                <Field
                  name='terminology_leased'
                  label='Leased'
                  Input={TextInput}
                  optional={false}
                />
              </Box>

              <Box padding='10px' flex={1}>
                <Field
                  name='terminology_letagreed'
                  label='Let Agreed'
                  Input={TextInput}
                  optional={false}
                />
              </Box>

              <Heading
                style={{
                  marginTop: '40px',
                  marginBottom: '20px',
                  marginLeft: '10px'
                }}
              >
                Residential Listings
              </Heading>

              <Box padding='10px' flex={1}>
                <Field
                  name='terminology_residential_buildarea'
                  label='Build Area'
                  Input={TextInput}
                  optional={false}
                />
              </Box>

              <Box padding='10px' flex={1}>
                <Field
                  name='terminology_residential_landarea'
                  label='Land Area'
                  Input={TextInput}
                  optional={false}
                />
              </Box>

              <Heading
                style={{
                  marginTop: '40px',
                  marginBottom: '20px',
                  marginLeft: '10px'
                }}
              >
                Commercial Listings
              </Heading>

              <Box padding='10px' flex={1}>
                <Field
                  name='terminology_commercial_buildarea'
                  label='Build Area'
                  Input={TextInput}
                  optional={false}
                />
              </Box>

              <Box padding='10px' flex={1}>
                <Field
                  name='terminology_commercial_landarea'
                  label='Land Area'
                  Input={TextInput}
                  optional={false}
                />
              </Box>
            </Form>
          )}
        </ReactForms>
      </RenderLoading>
    </Box>
  );
}

export default compose(withToast, withQuery(settingQuery))(CustomTerminology);
