import React, { useEffect } from 'react';
import { compose } from 'redux';
import Box from '@rexlabs/box';
import { ReactForms, Form, Field } from '@rexlabs/form';
import { RenderLoading } from 'view/components/render-loading';
import { withQuery, query } from '@rexlabs/model-generator';
import settings from 'data/models/entities/settings';
import { handleSettingsSubmit } from 'utils/handle-settings-submit';
import { PrimaryButton } from '@rexlabs/button';
import { TextInput } from '@rexlabs/text-input';
import { withToast } from 'view/components/toast';
import { FileInputZone } from 'view/components/file-input';

function getWebsiteId(props) {
  return props?.match?.params?.websiteId;
}

const settingQuery = query`{
  ${settings} (websiteId: ${getWebsiteId}, category: homepage_seo_info) {
    key
    value
    label
    type
    category
  }
}`;

const homepageinfo = {
  name: 'homepageinfo',
  mapPropsToValues: (props) => {
    const { settings } = props;
    const settingsData = settings?.list?.items;

    const data = Object.assign(
      {},
      ...settingsData.map((object) => {
        switch (object.type) {
          case 'image':
            return {
              [object.key]: object?.value?.data
                ? object?.value.data
                : object?.value
            };
          default:
            return { [object.key]: object?.value ? object?.value[0] : null };
        }
      })
    );
    return data;
  },
  handleSubmit: handleSettingsSubmit,
  asyncValuesReady: (props) => {
    return props.settings.list.status === 'loaded';
  }
};

function HomepageInfo(props) {
  const { settings } = props;

  const trackSave = () => {
    window.analytics?.track?.('siteloft.design', {
      action: 'updated homepage seo',
      location: 'Homepage SEO'
    });
  };

  useEffect(() => {
    window.analytics?.page?.('Homepage SEO');
  }, []);

  return (
    <Box flex={1} style={{ padding: '20px 20px 100px 20px', overflow: 'auto' }}>
      <RenderLoading isLoading={settings.list.status !== 'loaded'}>
        <ReactForms
          initialValues={homepageinfo.mapPropsToValues(props)}
          handleSubmit={(values) =>
            homepageinfo.handleSubmit(values, { props: props })
          }
        >
          {({ submitForm, isSubmitting }) => (
            <Form name='customCss' style={{ width: '100%' }}>
              <Box
                flex={1}
                flexDirection={'row'}
                padding='10px'
                alignItems={'center'}
              >
                <h1
                  style={{
                    fontSize: '28px',
                    fontWeight: '700',
                    marginRight: '20px'
                  }}
                >
                  {' '}
                  Homepage SEO{' '}
                </h1>

                <PrimaryButton
                  onClick={(e) => {
                    trackSave();
                    submitForm(e);
                  }}
                  isLoading={isSubmitting}
                >
                  Save
                </PrimaryButton>
              </Box>

              <Box padding='10px' flex={1}>
                <Field
                  name='homepage_seo_title'
                  label='Page Title'
                  Input={TextInput}
                  optional={true}
                />
              </Box>
              <Box padding='10px' flex={1}>
                <Field
                  name='homepage_seo_description'
                  label='Description'
                  Input={TextInput}
                  optional={true}
                />
              </Box>
              <Box padding='10px' flex={1}>
                <Field
                  name='homepage_seo_canonical_url'
                  label='Canonical URL'
                  Input={TextInput}
                  optional={true}
                />
              </Box>
              <Box padding='10px' flex={1}>
                <Field
                  name='homepage_seo_image'
                  label='Social Image'
                  Input={FileInputZone}
                  HelpTooltipContent={() => (
                    <span>
                      The featured image for sites like Facebook & Twitter
                    </span>
                  )}
                  inputProps={{
                    type: 'image',
                    settingKey: 'homepage_seo_image',
                    acceptedTypes: ['image/png', 'image/jpg', 'image/jpeg'],
                    acceptedExtensions: ['.png', '.jpg', '.jpeg']
                  }}
                  optional={true}
                />
              </Box>
            </Form>
          )}
        </ReactForms>
      </RenderLoading>
    </Box>
  );
}

export default compose(withToast, withQuery(settingQuery))(HomepageInfo);
