import Generator from 'data/models/generator';
import _ from 'lodash';
import { api, setAuthToken } from 'utils/api-client';

export const TYPE = 'session';

const initialState = {
  ready: false,
  apiToken: localStorage.getItem('apiToken'),
  accountId: null,
  user: null
};

const selectors = {
  ready: (state) => state.session.ready,
  apiToken: (state) => state.session.apiToken,
  user: (state) => state.session.user,
  accountId: (state) => state.session.accountId
};

async function initSession({ apiToken }) {
  // Check api token and set token and org for api client here
  setAuthToken(apiToken);

  // verify token is still valid
  try {
    const response = await api.get('/user');
    return {
      apiToken,
      accountId: response?.data?.account_id,
      user: response?.data,
      ready: true
    };
  } catch (e) {
    return {
      apiToken: null,
      accountId: null,
      ready: true
    };
  }
}

const actionCreators = {
  init: {
    request: (payload, actions, dispatch, getState) => {
      const currSession = getState().session;

      if (_.get(currSession, 'user.id') && window.Sprig) {
        window.Sprig('setUserId', _.get(currSession, 'user.id'));
        window.Sprig('setEmail', _.get(currSession, 'user.email'));
        window.Sprig('setAttributes', {
          first_name: _.get(currSession, 'user.first_name'),
          last_name: _.get(currSession, 'user.last_name'),
          name: _.get(currSession, 'user.name'),
          account_id: _.get(currSession, 'user.account_id'),
          roles: _.get(currSession, 'user.roles')
        });
      }

      return initSession(currSession);
    },
    reduce: {
      initial: _.identity,
      success: (state, action) => {
        return {
          ...state,
          ready: true,
          user: action?.payload?.user
        };
      },
      failure: (state) => ({
        ...state,
        ready: true,
        apiToken: null
      })
    }
  },

  login: {
    request: (payload) =>
      // eslint-disable-next-line
      new Promise(async (resolve, reject) => {
        const response = await api
          .post('/login', {
            email: payload.email,
            password: payload.password
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });

        if (!response?.data?.access_token) {
          reject(new Error('No token found!'));
          return;
        }

        if (payload.remember_login) {
          localStorage.setItem('apiToken', response?.data?.access_token);
        } else {
          // If people don't want to be remembered, let's just wipe whatever we have
          localStorage.removeItem('apiToken');
        }

        setAuthToken(response?.data?.access_token);

        // Track the login event and identify the user
        window.analytics?.identify?.(response?.data?.user?.id, {
          name:
            response?.data?.user?.first_name +
            ' ' +
            response?.data?.user?.last_name,
          email_address: response?.data?.user?.email,
          user_id: response?.data?.user?.id,
          created_at: response?.data?.user?.created_at
        });

        window.analytics?.track?.('siteloft.login_successful');

        resolve({
          apiToken: response.data.access_token,
          ready: true,
          user: response?.data?.user
        });
      }),
    reduce: {
      initial: _.identity,
      success: (state, action) => {
        return {
          ...state,
          user: action?.payload?.user,
          apiToken: action?.payload?.apiToken,
          ready: action?.payload?.ready
        };
      },
      failure: (state) => ({
        ...state,
        apiToken: null
      })
    }
  },

  logout: {
    reduce: (state) => ({
      ...state,
      user: null,
      apiToken: null
    })
  },

  forgotPassword: {
    request: ({ email }) =>
      api.post('/password-reset', {
        email
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  resetPassword: {
    request: ({ token, email, new_password }) =>
      api.put('/password-reset', {
        token,
        email,
        new_password: new_password
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const session = new Generator(TYPE).createModel({
  initialState,
  selectors,
  actionCreators
});

export default session;
