import Generator from '../generator';
import domainsModel from './domains';

const config = {
  entities: {
    related: {
      domain: {
        include: 'domain',
        model: domainsModel
      }
    }
  }
};

export default new Generator('websites', config).createEntityModel();
