import { query } from '@rexlabs/model-generator';
import websiteListingSourceModel from 'data/models/entities/website-listing-sources';

export const websiteListingSourcesQuery = query`{
  ${websiteListingSourceModel} (websiteId: ${(p) =>
  p?.match?.params?.websiteId}) {
    id
    website
    listing_source_id
    listing_source
    filters
  }
}`;
