import { query } from '@rexlabs/model-generator';
import actionsModel from 'data/models/entities/actions';

export const actionsQuery = query`{
  ${actionsModel} (websiteId: ${(p) => p?.match?.params?.websiteId}, formId: ${(
  p
) => p?.match?.params?.formId}) {
    id
    name
    form_id
    type
    email_to
    email_reply_to
    email_subject
    email_message
    include_submission_data
    redirect_url
    success_message
  }
}`;

export const actionQuery = query`{
  ${actionsModel} (websiteId: ${(p) => p?.match?.params?.websiteId}, formId: ${(
  p
) => p?.match?.params?.formId}, id: ${(p) => p?.match?.params?.actionId}) {
    id
    name
    form_id
    type
    email_to
    email_reply_to
    email_subject
    email_message
    include_submission_data
    redirect_url
    success_message
  }
}`;
