import React, { useCallback, useEffect, useState } from 'react';
import { compose, startCase } from 'lodash/fp';
import { Dialog } from '@rexlabs/dialog';
import { ReactForms } from '@rexlabs/form';
import { createValidationRules } from '@rexlabs/validator';
import { popupQuery } from 'data/queries/popups';
import { withQuery } from '@rexlabs/model-generator';
import { pagesQuery } from 'data/queries/pages';
import { formsQuery } from 'data/queries/forms';
import EditPopupForm from './edit-form';

const validate = createValidationRules({
  name: 'required',
  title: 'required',
  content: 'required'
});

function EditPopupsDialog({
  popups,
  match,
  closeModal,
  onClose,
  pages,
  forms,
  ...props
}) {
  const popup = popups?.item?.data;

  const [triggers, setTriggers] = useState([]);

  useEffect(() => {
    setTriggers(popup?.triggers?.data);
  }, [popup]);

  const onSubmit = useCallback(
    (values) => {
      return popups
        .updateItem({
          id: popup.id,
          data: {
            websiteId: match?.params?.websiteId,
            type: values?.type?.value ?? null,
            position: values?.position?.value ?? null,
            name: values?.name ?? null,
            title: values?.title ?? null,
            content: values?.content ?? null,
            form_id: values?.form?.value ?? null,
            css_class: values?.css ?? null,
            size: values?.size?.value ?? null,
            animation: values?.animation?.value ?? null,
            triggers: triggers ?? []
          }
        })
        .then(() => {
          popups.refreshList();
          onClose();
        })
        .catch((e) => {
          console.error(e);
        });
    },
    [popups, popup.id, match, triggers, onClose]
  );

  const handleAddTrigger = (values) => {
    const newTrigger = {
      type: values?.triggerType?.value,
      form_id: values?.triggerForm,
      trigger_class: values?.triggerClass,
      page_id: values?.triggerPage?.value,
      delay: values?.triggerDelay
    };

    setTriggers([...triggers, newTrigger]);
  };

  const initialFormValue = popup?.form
    ? {
        value: popup?.form?.id,
        label: popup?.form?.definition?.name
      }
    : null;

  return (
    <Dialog title={'Edit Popup'} width='80%' onClose={onClose} {...props}>
      {popups.item?.status === 'loading' ||
      forms?.list?.status === 'loading' ||
      pages?.list?.status === 'loading' ? (
        <div>Loading</div>
      ) : (
        <ReactForms
          asyncValuesReady={
            popups.item?.status === 'loading' ||
            forms?.list?.status === 'loading'
          }
          validate={validate}
          handleSubmit={onSubmit}
          validateOnBlur={false}
          initialValues={{
            name: popup?.name,
            title: popup?.title,
            type: {
              value: popup?.type,
              label: startCase(popup?.type)
            },
            position: {
              value: popup?.position,
              label: startCase(popup?.position)
            },
            content: popup?.content,
            form: initialFormValue,
            css: popup?.css_class,
            size: {
              value: popup?.size,
              label: startCase(popup?.size)
            },
            animation: {
              value: popup?.animation,
              label: startCase(popup?.animation)
            }
          }}
        >
          {({ submitForm, isSubmitting, values }) => (
            <EditPopupForm
              submitForm={submitForm}
              isSubmitting={isSubmitting}
              values={values}
              pages={pages}
              forms={forms}
              triggers={triggers}
              handleAddTrigger={handleAddTrigger}
              closeModal={closeModal}
            />
          )}
        </ReactForms>
      )}
    </Dialog>
  );
}

popupQuery.args.include = 'triggers,form';

export default compose(
  withQuery(pagesQuery),
  withQuery(formsQuery),
  withQuery(popupQuery)
)(EditPopupsDialog);
