import React from 'react';
import { compose, isFunction } from 'lodash/fp';

import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import ThemePreview, { ThemePreviewProps } from '../components/theme/preview';

const defaultStyles = StyleSheet({
  settingsContainer: {
    flex: '0 0 375px',
    width: 375,
    background: '#ffffff',
    overflow: 'auto',
    position: 'relative'
  },
  previewContainer: {
    maxWidth: '100%',
    flexGrow: 1,
    height: '100%',
    overflow: 'auto'
  }
});

export interface PreviewLayoutProps {
  Settings: React.ComponentType;
  previewProps: ThemePreviewProps;
}

interface DecoratorProps {
  styles: any;
}

function PreviewLayout({
  styles: s,
  Settings,
  previewProps
}: PreviewLayoutProps & DecoratorProps) {
  return (
    <Box
      flexDirection='row'
      justifyContent='flex-start'
      flex={1}
      style={{ maxWidth: '100%' }}
    >
      <Box {...s('settingsContainer')}>
        {isFunction(Settings) ? <Settings /> : Settings}
      </Box>

      <Box {...s('previewContainer')}>
        <ThemePreview {...previewProps} />
      </Box>
    </Box>
  );
}

export default compose(styled(defaultStyles))(PreviewLayout);
