// TODO: add types
// TODO: make duration cell more generic e.g takes the input value type (seconds|minutes|hours) and make the output conversion also configurable

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

export default function DurationCell({ value, column }) {
  const { cellProps: { emptyValue = '-' } = {} } = column;

  return value
    ? `${dayjs.duration({ seconds: value }).asHours()} hour(s)`
    : emptyValue;
}
