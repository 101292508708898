import React, { useEffect, useState } from 'react';
import { Checkbox } from '@rexlabs/checkbox';
import { ReactForms, Form, Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { PasswordInput } from '@rexlabs/password-input';
import { PrimaryButton } from '@rexlabs/button';
import Box from '@rexlabs/box';
import { createValidationRules } from '@rexlabs/validator';
import { useModelActions } from '@rexlabs/model-generator';
import { Link } from '@rexlabs/react-whereabouts';
import { StyleSheet, styled, StylesProps } from '@rexlabs/styling';
import { Heading, Body } from '@rexlabs/text';
import { COLORS, PADDINGS } from 'src/theme';
import Logo from 'assets/login-logo.svg';

import ROUTES from 'src/routes/auth';
import sessionModel from 'data/models/custom/session';
import { ErrorNotification } from 'src/view/components/notification';

const validate = createValidationRules({
  email: 'required|email',
  password: 'required'
});

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    height: '100%',
    background: ({ token }) => token('color.primary.idle.default'),
    padding: ({ token }) => token('spacing.s')
  },

  loginButton: {
    width: '100%',
    marginTop: '10px',
    marginBottom: '10px'
  },

  description: {
    color: COLORS.GRAY,
    padding: '10px 0'
  },

  logo: {
    height: 'auto',
    width: '15rem',
    marginBottom: '9rem',
    color: ({ token }) => token('color.primary.idle.default')
  },

  checkboxWrapper: {
    width: '50%',
    padding: '10px 0'
  },

  formField: {
    marginBottom: '2rem'
  },

  fieldWrapper: {
    padding: '10px 0'
  }
});

function LoginScreen({ styles: s }: StylesProps<typeof defaultStyles>) {
  const session = useModelActions(sessionModel);
  const [error, setError] = useState('');

  const handleSubmit = (values) => {
    setError('');

    return session
      .login({
        email: values.email,
        password: values.password,
        remember_login: values.remember_login
      })
      .catch((e) => {
        console.error(e.message);
        setError(e.message || 'An error occurred.');
      });
  };

  useEffect(() => {
    window.analytics?.page?.('Login');
  }, []);

  return (
    <>
      <Logo {...s('logo')} />
      <Heading>Welcome back to Rex Websites!</Heading>
      <Body {...s('description')}>{"Let's get you logged in."} </Body>

      {error && <ErrorNotification>{error}</ErrorNotification>}

      <ReactForms validate={validate} handleSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <Box {...s('fieldWrapper')}>
              <Field
                Input={TextInput}
                name='email'
                label='Email'
                optional={false}
                inputProps={{
                  type: 'email',
                  placeholder: 'e.g. name@company.com',
                  styles: { paddingTop: PADDINGS.XL }
                }}
              />
            </Box>
            <Box {...s('fieldWrapper')}>
              <Field
                Input={PasswordInput}
                name='password'
                label='Password'
                optional={false}
                inputProps={{
                  placeholder: 'Password'
                }}
              />
            </Box>
            <Box
              flexDirection='row'
              alignItems='center'
              justifyContent='space-between'
            >
              <Box {...s('checkboxWrapper')}>
                <Field
                  optional={false}
                  Input={Checkbox}
                  name='remember_login'
                  inputProps={{
                    label: 'Keep me signed in'
                  }}
                />
              </Box>
              <Link to={ROUTES.FORGOT_PASSWORD}>Forgot password?</Link>
            </Box>
            <Box
              flexDirection='row'
              alignItems='center'
              justifyContent='space-between'
            >
              <PrimaryButton
                {...s('loginButton')}
                type='submit'
                isLoading={isSubmitting}
              >
                Log in
              </PrimaryButton>
            </Box>
          </Form>
        )}
      </ReactForms>
    </>
  );
}

export default styled(defaultStyles)(LoginScreen);
