import { useEffect } from 'react';

// Clicking in an iframe doesn't propagate the event out of the iframe.
// This causes an issue when clicking in an iframe to close the side menu.
// This hook takes an iframe ref and adds mouseover/mouseout events to set
// a global variable which is checked on window blur events to fire a body
// click event.
function useIframeClick({ current: iframe }) {
  useEffect(() => {
    if (!iframe) return;

    iframe.addEventListener('mouseover', () => (window.iframeMouseOver = true));
    iframe.addEventListener('mouseout', () => (window.iframeMouseOver = false));

    const handleBodyBlur = () => {
      if (window.iframeMouseOver) {
        window.document.body.click();
      }
    };
    window.addEventListener('blur', handleBodyBlur);

    return () => window.removeEventListener('blur', handleBodyBlur);
  }, [iframe]);

  return null;
}

export { useIframeClick };
