import React, { useState, useMemo } from 'react';
import { compose } from 'lodash/fp';

import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { ButtonGroup, PrimaryButton, SecondaryButton } from '@rexlabs/button';

import Desktop from 'assets/icons/template-preview/desktop.svg';
import Tablet from 'assets/icons/template-preview/tablet.svg';
import Mobile from 'assets/icons/template-preview/mobile.svg';

interface ButtonInput {
  onSelect: (...any) => any;
  id: string;
}

interface DeviceButtonsProps {
  items: ButtonInput[];
}

interface DecoratorProps {
  styles: any;
}

const defaultStyles = StyleSheet({
  whiteBG: {
    fill: '#000'
  },
  blueBG: {
    fill: '#fff'
  },
  btn: {
    borderRadius: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    ':first-child': {
      borderTopLeftRadius: '4px',
      borderTopRightRadius: 0,
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: 0,
      borderLeftWidth: 1,
      borderRightWidth: 1
    },
    ':last-child': {
      borderTopLeftRadius: 0,
      borderTopRightRadius: '4px',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: '4px',
      borderLeftWidth: 1,
      borderRightWidth: 1
    }
  }
});

const icons = [Desktop, Tablet, Mobile];

function DeviceButtons({
  styles: s,
  items = []
}: DeviceButtonsProps & DecoratorProps) {
  const [selected, setSelected] = useState<number>(0);
  const buttons = useMemo(
    () => (
      <>
        {items?.map((button: ButtonInput, index: number) => {
          const Btn = selected === index ? PrimaryButton : SecondaryButton;
          const Icon = icons[index];
          return (
            <Btn
              id={button.id}
              {...s.with('btn', { whiteBG: selected !== index })()}
              key={`preview-button-${index}`}
              onClick={() => {
                button.onSelect();
                setSelected(index);
              }}
            >
              <Icon {...s(selected === index ? 'blueBG' : 'whiteBG')} />
            </Btn>
          );
        })}
      </>
    ),
    [items, s, selected]
  );
  return (
    <Box {...s('container')}>
      <ButtonGroup {...s('btnGroup')} spacing={0} p={24}>
        {buttons}
      </ButtonGroup>
    </Box>
  );
}

export default compose(styled(defaultStyles))(DeviceButtons);
