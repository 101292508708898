import React, { useMemo, useState } from 'react';
import Box from '@rexlabs/box';
import {
  TableProvider,
  Table,
  SimplePagination,
  TextCell,
  useTableQuery
} from '@rexlabs/table';
import { Link } from '@rexlabs/react-whereabouts';
import { Heading } from '@rexlabs/text';
import {
  ButtonGroup,
  PrimaryButton,
  DestructiveButton,
  SecondaryButton,
  GhostButton
} from '@rexlabs/button';
import { withModel } from '@rexlabs/model-generator';
import { push } from '@rexlabs/whereabouts';
import { Dialog } from '@rexlabs/dialog';
import redirectsModel from 'data/models/entities/redirects';
import ROUTES from 'src/routes/app';
import { redirectsQuery } from 'data/queries/redirects';
import Screen from 'view/components/screen';
import userConfig from 'src/config';

function RedirectsScreen({ match, redirects }) {
  const websiteId = match?.params?.websiteId;
  const [deleteRedirectModal, setDeleteRedirectModal] = useState();
  const tableQuery = useMemo(() => {
    redirectsQuery.args.websiteId = websiteId;
    return redirectsQuery;
  }, [websiteId]);

  const deleteRedirect = () => {
    if (!deleteRedirectModal) return;

    return redirects
      .trashItem({
        id: deleteRedirectModal?.id,
        args: {
          websiteId: websiteId
        }
      })
      .then(() => setDeleteRedirectModal(null));
  };

  const { getTableProps: getTableQueryProps } = useTableQuery({
    getQuery: () => tableQuery
  });

  const getActionMenuItems = ({ item }) => {
    return [
      {
        label: 'Edit',
        onClick: () =>
          push(ROUTES.ADMIN_REDIRECTS.EDIT, {
            params: {
              websiteId: match?.params?.websiteId,
              redirectId: item?.id
            }
          })
      },
      {
        label: 'Delete',
        onClick: () => {
          setDeleteRedirectModal(item);
        }
      }
    ];
  };

  const columns = [
    {
      id: 'redirects_from',
      width: 'auto',
      Header: 'From',
      Cell: TextCell
    },
    {
      id: 'redirects_to',
      width: 'auto',
      Header: 'To',
      Cell: TextCell
    }
  ];

  return (
    <Screen>
      <Heading>Redirects</Heading>
      <Box
        justifyContent='space-between'
        alignItems='center'
        pt={'1.6rem'}
        pb={'1.6rem'}
      >
        <Link
          to={ROUTES.ADMIN_REDIRECTS.CREATE}
          params={{ websiteId: match?.params?.websiteId }}
        >
          {({ onClick }) => (
            <PrimaryButton onClick={onClick}>Create New</PrimaryButton>
          )}
        </Link>

        <GhostButton
          onClick={() =>
            window.open(
              userConfig.API_URL + '/websites/' + websiteId + '/sitemap',
              '_blank'
            )
          }
        >
          Download Sitemap
        </GhostButton>
      </Box>
      <TableProvider
        columns={columns}
        {...getTableQueryProps()}
        getActionMenuItems={getActionMenuItems}
      >
        <Table />
        <SimplePagination />
      </TableProvider>

      {!!deleteRedirectModal && (
        <Dialog
          title={'Are you sure you want to delete this redirect?'}
          onClose={() => setDeleteRedirectModal(null)}
        >
          <Box justifyContent={'center'}>
            <ButtonGroup>
              <SecondaryButton onClick={() => setDeleteRedirectModal(null)}>
                Cancel
              </SecondaryButton>
              <DestructiveButton onClick={() => deleteRedirect()}>
                Delete
              </DestructiveButton>
            </ButtonGroup>
          </Box>
        </Dialog>
      )}
    </Screen>
  );
}

export default withModel(redirectsModel, 'redirects')(RedirectsScreen);
