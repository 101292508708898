import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { ReactForms, Form, Field } from '@rexlabs/form';
import { RenderLoading } from 'view/components/render-loading';
import { withQuery, query } from '@rexlabs/model-generator';
import { get } from 'lodash';
import { autobind } from 'core-decorators';
import settings from 'data/models/entities/settings';
import { handleSettingsSubmit } from 'utils/handle-settings-submit';
import { PrimaryButton } from '@rexlabs/button';
import { TextInput } from '@rexlabs/text-input';
import { withToast } from 'view/components/toast';

function getWebsiteId(props) {
  return get(props, 'match.params.websiteId');
}

const settingQuery = query`{
  ${settings} (websiteId: ${getWebsiteId}, category: social) {
    key
    value
    label
    category
  }
}`;

const mapProps = (props) => {
  const { settings } = props;
  const settingsData = get(settings, 'list.items');

  const data = Object.assign(
    {},
    ...settingsData.map((object) => {
      return { [object.key]: object?.value ? object?.value[0] : null };
    })
  );

  return data;
};

const socialLinks = {
  name: 'socialLinks',
  mapPropsToValues: mapProps,
  handleSubmit: handleSettingsSubmit,
  asyncValuesReady: (props) => {
    return props.settings.list.status === 'loaded';
  }
};

@withToast
@withQuery(settingQuery)
@autobind
class SocialLinks extends PureComponent {
  componentDidMount() {
    window.analytics?.page?.('Social Links');
  }

  trackSave() {
    window.analytics?.track?.('siteloft.design', {
      action: 'updated header behaviour',
      location: 'Header behaviour'
    });
  }

  render() {
    return (
      <Box
        flex={1}
        style={{ padding: '20px 20px 100px 20px', overflow: 'auto' }}
      >
        <RenderLoading isLoading={this.props.settings.list.status !== 'loaded'}>
          <ReactForms
            initialValues={socialLinks.mapPropsToValues(this.props)}
            handleSubmit={(values) =>
              socialLinks.handleSubmit(values, { props: this.props })
            }
          >
            {({ submitForm, isSubmitting }) => (
              <Form name='customCss' style={{ width: '100%' }}>
                <Box
                  flex={1}
                  flexDirection={'row'}
                  padding='10px'
                  alignItems={'center'}
                >
                  <h1
                    style={{
                      fontSize: '28px',
                      fontWeight: '700',
                      marginRight: '20px'
                    }}
                  >
                    {' '}
                    Social Links{' '}
                  </h1>

                  <PrimaryButton
                    onClick={(e) => {
                      this.trackSave();
                      submitForm(e);
                    }}
                    isLoading={isSubmitting}
                  >
                    Save
                  </PrimaryButton>
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='fb_link'
                    label='Facebook'
                    Input={TextInput}
                    optional={false}
                  />
                </Box>
                <Box padding='10px' flex={1}>
                  <Field
                    name='ig_link'
                    label='Instagram'
                    Input={TextInput}
                    optional={false}
                  />
                </Box>
                <Box padding='10px' flex={1}>
                  <Field
                    name='pinterest_link'
                    label='Pinterest'
                    Input={TextInput}
                    optional={false}
                  />
                </Box>
                <Box padding='10px' flex={1}>
                  <Field
                    name='youtube_link'
                    label='Youtube'
                    Input={TextInput}
                    optional={false}
                  />
                </Box>
                <Box padding='10px' flex={1}>
                  <Field
                    name='linkedin_link'
                    label='Linkedin'
                    Input={TextInput}
                    optional={false}
                  />
                </Box>
                <Box padding='10px' flex={1}>
                  <Field
                    name='twitter_link'
                    label='Twitter'
                    Input={TextInput}
                    optional={false}
                  />
                </Box>
              </Form>
            )}
          </ReactForms>
        </RenderLoading>
      </Box>
    );
  }
}

export default SocialLinks;
