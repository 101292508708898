import { InputProps } from 'view/components/theme-settings/theme-setting-input';
import { Dropdown } from '@rexlabs/select';

export const BANNER_COMPONENT_OPTIONS = [
  { value: 'property_search', label: 'Property Search Bar' },
  { value: 'cta_buttons', label: 'Call To Action' },
  { value: 'none', label: 'None' }
];

export const VERSATILE_BANNER_COMPONENT_OPTIONS = [
  { value: 'property_search', label: 'Property Search Bar' },
  { value: 'cta_buttons', label: 'Call To Action' },
  // { value: 'email_subscription', label: 'Email Subscription' },
  { value: 'none', label: 'None' }
];

export const MINIMAL_BANNER_COMPONENT_OPTIONS = [
  { value: 'property_search', label: 'Property Search Bar' },
  { value: 'cta_buttons', label: 'Call To Action' },
  // { value: 'email_subscription', label: 'Email Subscription' },
  { value: 'none', label: 'None' },
  { value: 'quick_menu', label: 'Quick Links Menu' }
];

export const allOptions = {
  byron: BANNER_COMPONENT_OPTIONS,
  versatile: VERSATILE_BANNER_COMPONENT_OPTIONS,
  minimal: MINIMAL_BANNER_COMPONENT_OPTIONS
};

export function getOptionalBannerComponentInput(persona): InputProps {
  return {
    input: Dropdown,
    inputProps: {
      items: allOptions[persona] ?? {}
    }
  };
}
