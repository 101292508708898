import Generator from '../generator';
import { api } from 'utils/api-client';

const config = {
  entities: {
    api: {
      fetchList: (type, { websiteId, ...args }) => api.get(`/themes`, args)
    }
  }
};

export default new Generator('themes', config).createEntityModel({});
