import { InputProps } from 'view/components/theme-settings/theme-setting-input';
import { Dropdown } from '@rexlabs/select';

export const LAUNCHPAD_VARIANT_OPTIONS = {
  'display-two': { value: 'display-two', label: 'Display 2 Cards' },
  'display-three': { value: 'display-three', label: 'Display 3 Cards' },
  'display-four': { value: 'display-four', label: 'Display 4 Cards' }
};

export function getLaunchpadVariantInput(): InputProps {
  return {
    input: Dropdown,
    inputProps: {
      items: Object.values(LAUNCHPAD_VARIANT_OPTIONS)
    }
  };
}
