// TODO: remove this eslint disable comment below
/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import Box from '@rexlabs/box';
import { Field } from '@rexlabs/form';
import { withModel } from '@rexlabs/model-generator';
import formsModel from 'data/models/entities/forms';
import menuModel from 'data/models/entities/menus';
import listingsModel from 'data/models/entities/listings';
import filesModel from 'data/models/entities/files';
import LoadingLayout from '../../layouts/loading';
import { getMultiPostTypeInput } from 'view/components/theme-settings/dynamic-inputs/multiPostTypeInput';
import { getInputFromType } from 'view/components/theme-settings/helpers';
import { VariantOption } from 'view/components/theme-settings/dynamic-inputs/blockVariantInput';

export type Setting = {
  type: string;
  value: Array<any>;
  key: string;
  label: string;
  tooltip: {
    label: string;
  };
  input_props?: {
    charLimit?: number;
    isSoftLimit?: boolean;
    options?: VariantOption[];
  };
};

export interface InputProps {
  input: React.ReactNode;
  inputProps?: Record<string, any>;
  persona?: string;
}

// TODO: Types
interface Props {
  setting: Setting;
  setFieldValue: any;
  match: any;
  persona: string;
}

// TODO: Types
interface DecoratorProps {
  agencies: any;
  forms: any;
  listings: any;
  menus: any;
  files: any;
}

export type Resource = {
  value: string;
  label: string;
};

export function getWebsiteId(props) {
  return props?.match?.params?.websiteId;
}

const ThemeSettingInput = ({
  setting,
  agencies,
  forms,
  listings,
  menus,
  files,
  setFieldValue,
  persona,
  ...props
}: Props & DecoratorProps) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [resourceList, setResourceList] = useState<any>([]);

  // Load any extra data we need
  useEffect(() => {
    if (setting?.type === 'form') {
      forms
        .fetchList({
          id: 'fetch-input-forms',
          websiteId: getWebsiteId(props),
          args: {
            websiteId: getWebsiteId(props)
          },
          data: {
            websiteId: getWebsiteId(props)
          }
        })
        .then((res) => {
          setResourceList(
            res.data?.map((form) => {
              return {
                value: form.item.id,
                label: form.item.name
              };
            })
          );
          setLoaded(true);
        });
    } else if (setting?.type === 'menu') {
      menus
        .fetchList({
          id: 'fetch-input-menus',
          websiteId: getWebsiteId(props),
          args: {
            websiteId: getWebsiteId(props)
          },
          data: {
            websiteId: getWebsiteId(props)
          }
        })
        .then((res) => {
          const items = res.data.map((item) => item.item);
          setResourceList(
            items?.map((menu) => {
              return {
                value: menu.id,
                label: menu.name
              };
            })
          );
          const foundItem = items.find((i) => i.setting?.value?.[0]);
          if (foundItem) {
            setFieldValue(setting.key, {
              value: foundItem.id,
              label: foundItem.name
            });
          }
          setLoaded(true);
        });
    } else if (setting?.type === 'image' || setting?.type === 'multi-image') {
      if (typeof setting?.value?.[0] === 'string') {
        // Fetch the file
        files
          .fetchItem({
            id: setting?.value[0],
            args: {
              id: setting?.value[0],
              websiteId: getWebsiteId(props)
            }
          })
          .then((res) => {
            setFieldValue(setting.key, res?.data);
            setLoaded(true);
          })
          .catch((e) => {
            console.error(e);
          });
      } else if (Array.isArray(setting?.value?.[0])) {
        Promise.all(
          setting?.value?.[0]?.filter(Boolean).map((file) => {
            return files.fetchItem({
              id: file,
              args: {
                id: file,
                websiteId: getWebsiteId(props)
              }
            });
          })
        )
          .then((res) => {
            setFieldValue(
              setting.key,
              res.map((item) => item.data)
            );
            setLoaded(true);
          })
          .catch(() => {
            console.error('error on array value', setting);
          });
      } else {
        setFieldValue(setting.key, setting?.value[0]);
        setLoaded(true);
      }
    } else {
      setLoaded(true);
    }
  }, [setting]);

  if (!loaded || !setting) {
    return <LoadingLayout />;
  }

  let inputSettings: InputProps | null;
  if (/^(multi-)?post-type:(.+)$/.test(setting?.type)) {
    inputSettings = getMultiPostTypeInput(setting?.type, agencies, listings);
  } else {
    inputSettings = getInputFromType(setting, resourceList, persona);
  }
  if (!inputSettings) return null;

  return (
    <Box
      padding='10px'
      data-testid={`${setting.label.toLowerCase().replaceAll(' ', '-')}-field`}
    >
      <Field
        label={setting.label}
        name={setting.key}
        HelpTooltipContent={
          setting?.tooltip?.label ? () => setting?.tooltip?.label : undefined
        }
        // eslint-disable-next-line
        // @ts-ignore
        Input={inputSettings?.input}
        inputProps={inputSettings?.inputProps}
        optional={false}
        showErrorMessage={true}
      />
    </Box>
  );
};

// TODO Remove <any, any...> hint to compose once the passed functions have
// better typing.
export default compose<any, any, any, any, any>(
  withModel(listingsModel),
  withModel(formsModel),
  withModel(menuModel),
  withModel(filesModel)
)(ThemeSettingInput);
