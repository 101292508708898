/**
 * Convert reponse from forms-api to form builder format
 * @param {*} values
 */
export const transformFieldsApiResponseToBuilder = (values) => {
  return {
    components: values.definition.fields.map((field) => {
      return {
        label: field.label,
        type: field.type,
        key: field.key,
        values: field.values,
        ...field
      };
    })
  };
};

export const transformFieldsBuilderToApiResponse = (values) => {
  return values.form.components.map((field) => {
    return {
      label: field.label,
      type: field.type,
      key: field.key,
      name: field.label.toLowerCase().replace(/ /g, '_'),
      values: field.values,
      ...field
    };
  });
};
