import React, { useMemo } from 'react';
import Box from '@rexlabs/box';
import {
  TableProvider,
  Table,
  SimplePagination,
  TextCell,
  DateCell,
  useTableQuery
} from '@rexlabs/table';
import { Link } from '@rexlabs/react-whereabouts';
import { Heading } from '@rexlabs/text';
import { PrimaryButton } from '@rexlabs/button';
import ROUTES from 'src/routes/app';
import { websiteListingSourcesQuery } from 'data/queries/website-listing-sources';
import Screen from 'view/components/screen';
import DurationCell from 'view/components/table/custom-cells/duration';
import { api } from 'utils/api-client';

function ListingSources({ match }) {
  const websiteId = match?.params?.websiteId;
  const tableQuery = useMemo(() => {
    websiteListingSourcesQuery.args.websiteId = websiteId;
    return websiteListingSourcesQuery;
  }, [websiteId]);

  const { getTableProps: getTableQueryProps } = useTableQuery({
    getQuery: () => tableQuery
  });

  const runSyncNow = (id) => {
    return api.post(`/syncs/${id}/run`);
  };

  const getActionMenuItems = ({ item }) => {
    return [
      {
        label: 'Sync now',
        onClick: () => runSyncNow(item?.listing_source_id)
      }
    ];
  };

  const columns = [
    {
      id: 'listing_source.label',
      width: 'auto',
      Header: 'Label',
      Cell: TextCell
    },
    {
      id: 'listing_source.source_name',
      width: 'auto',
      Header: 'Source',
      Cell: TextCell
    },
    {
      id: 'filters',
      width: 'auto',
      Header: 'Filters',
      Cell: DurationCell
    },
    {
      id: 'listing_source.last_run',
      width: 'auto',
      Header: 'Last Run',
      cellProps: {
        format: 'MMM DD, h:mma'
      },
      Cell: DateCell
    }
  ];

  return (
    <Screen>
      <Heading>Listing sources</Heading>
      <Box
        justifyContent='space-between'
        alignItems='center'
        pt={'1.6rem'}
        pb={'1.6rem'}
      >
        <Link to={ROUTES.LISTING_SOURCES.ADD} params={{ websiteId }}>
          {({ onClick }) => (
            <PrimaryButton onClick={onClick}>Add listing source</PrimaryButton>
          )}
        </Link>
      </Box>
      <TableProvider
        columns={columns}
        getActionMenuItems={getActionMenuItems}
        {...getTableQueryProps()}
      >
        <Table />
        <SimplePagination />
      </TableProvider>
    </Screen>
  );
}

export default ListingSources;
