import {
  Toast,
  InfoToast,
  WarningToast,
  ErrorToast,
  SuccessToast,
  ToastContext
} from '@rexlabs/toast';

import { ToastProvider, useToast } from './context';

import ToastTarget from './target';
import withToast from './with-toast';

export {
  Toast,
  InfoToast,
  WarningToast,
  ErrorToast,
  SuccessToast,
  ToastTarget,
  ToastContext,
  ToastProvider,
  useToast,
  withToast
};
