import React, { useMemo, useState } from 'react';
import Box from '@rexlabs/box';
import {
  TableProvider,
  Table,
  SimplePagination,
  TextCell,
  useTableQuery
} from '@rexlabs/table';
import { Link } from '@rexlabs/react-whereabouts';
import { Heading } from '@rexlabs/text';
import {
  ButtonGroup,
  PrimaryButton,
  DestructiveButton,
  SecondaryButton
} from '@rexlabs/button';
import { push } from '@rexlabs/whereabouts';
import domainsModel from 'data/models/entities/domains';

import ROUTES from 'src/routes/app';
import { domainsQuery } from 'data/queries/domains';
import Screen from 'view/components/screen';
import { Dialog } from '@rexlabs/dialog';
import { withModel } from '@rexlabs/model-generator';

function DomainsScreen({ match, domains }) {
  const websiteId = match?.params?.websiteId;
  const [deleteDomainModal, setDeleteDomainModal] = useState();
  const tableQuery = useMemo(() => {
    domainsQuery.args.websiteId = websiteId;
    return domainsQuery;
  }, [websiteId]);

  const { getTableProps: getTableQueryProps } = useTableQuery({
    getQuery: () => tableQuery
  });

  const createNew = () => {
    window.analytics?.track?.('siteloft.domains', {
      action: 'clicked_create_domain'
    });
  };

  const deleteDomain = () => {
    if (!deleteDomainModal) return;

    return domains
      .trashItem({
        id: deleteDomainModal?.id,
        args: {
          websiteId: websiteId
        }
      })
      .then(() => setDeleteDomainModal(null));
  };

  const getActionMenuItems = ({ item }) => {
    return [
      {
        label: 'Edit',
        onClick: () =>
          push(ROUTES.ADMIN_DOMAINS.EDIT, {
            params: {
              websiteId: match?.params?.websiteId,
              domainId: item?.id
            }
          })
      },
      {
        label: 'Delete',
        onClick: () => {
          setDeleteDomainModal(item);
        }
      }
    ];
  };

  const columns = [
    {
      id: 'domain',
      width: 'auto',
      Header: 'Domain',
      Cell: TextCell
    },
    {
      id: 'is_primary',
      width: 'auto',
      Header: 'Primary',
      Cell: ({ value }) => {
        return value ? '✓' : '';
      }
    },
    {
      id: 'redirect_to_path',
      width: 'auto',
      Header: 'Redirects to path',
      Cell: TextCell
    },
    {
      id: 'is_microsite',
      width: 'auto',
      Header: 'Acts as microsite',
      Cell: ({ value }) => {
        return value ? '✓' : '';
      }
    }
  ];

  return (
    <Screen>
      <Heading>Domains</Heading>
      <Box
        justifyContent='space-between'
        alignItems='center'
        pt={'1.6rem'}
        pb={'1.6rem'}
      >
        <Link to={ROUTES.ADMIN_DOMAINS.CREATE} params={{ websiteId }}>
          {({ onClick }) => (
            <PrimaryButton
              onClick={(e) => {
                createNew();
                onClick(e);
              }}
            >
              Create New
            </PrimaryButton>
          )}
        </Link>
      </Box>
      <TableProvider
        columns={columns}
        {...getTableQueryProps()}
        getActionMenuItems={getActionMenuItems}
      >
        <Table />
        <SimplePagination />
      </TableProvider>

      {!!deleteDomainModal && (
        <Dialog
          title={'Are you sure you want to delete this domain?'}
          onClose={() => setDeleteDomainModal(null)}
        >
          <Box justifyContent={'center'}>
            <ButtonGroup>
              <SecondaryButton onClick={() => setDeleteDomainModal(null)}>
                Cancel
              </SecondaryButton>
              <DestructiveButton onClick={() => deleteDomain()}>
                Delete
              </DestructiveButton>
            </ButtonGroup>
          </Box>
        </Dialog>
      )}
    </Screen>
  );
}

export default withModel(domainsModel, 'domains')(DomainsScreen);
