import { query } from '@rexlabs/model-generator';
import usersModel from 'data/models/entities/users';

export const usersQuery = query`{
  ${usersModel} (websiteId: ${(p) => p?.match?.params?.websiteId}) {
    id
    account_id
    name
    first_name
    last_name
    email
    roles
    created_at
  }
}`;

export const userQuery = query`{
  ${usersModel} (websiteId: ${(p) => p?.match?.params?.websiteId}, id: ${(p) =>
  p?.match?.params?.userId}) {
    id
    account_id
    name
    first_name
    last_name
    email
    roles
    created_at
  }
}`;
