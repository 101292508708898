import { InputProps } from 'view/components/theme-settings/theme-setting-input';
import { Dropdown } from '@rexlabs/select';
const BLOG_OPTIONS = {
  featured_posts: { value: 'featured_posts', label: 'Featured Blog Posts' },
  latest_posts: { value: 'latest_posts', label: 'Last Updated Blog Posts' }
};

export function getBlogOptionsInput(): InputProps {
  return {
    input: Dropdown,
    inputProps: {
      items: Object.values(BLOG_OPTIONS)
    }
  };
}
