import { query } from '@rexlabs/model-generator';
import formsModel from 'data/models/entities/forms';

export const formsQuery = query`{
  ${formsModel} (websiteId: ${(p) => p?.match?.params?.websiteId}) {
    id
    name
    definition
    created_at
  }
}`;
