import { TOKENS as DEFAULTS } from '@rexlabs/theme-luna';
import { merge } from 'lodash';

export const TOKENS = merge(DEFAULTS, {
  topBar: {
    height: '6.5rem',

    padding: {
      left: '1.2rem',
      right: '1.2rem'
    },

    background: {
      color: ({ token }) => token('palette.white')
    }
  },

  avatar: {
    color: ({ token }) => token('palette.white'),

    background: {
      color: ({ token }) => token('color.primary.idle.default')
    }
  },

  select: {
    popper: {
      zIndex: 1000
    }
  },

  navigation: {
    item: {
      hover: {
        background: {
          color: ({ token }) => token('palette.white')
        }
      }
    }
  }
});
