import { push } from '@rexlabs/whereabouts';
import React, { forwardRef } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { Body } from '@rexlabs/text';
import ROUTES from 'src/routes/app';
import ArrowDownFilled from 'src/view/components/icons/arrow-down-filled';
import ArrowLeft from 'src/view/components/icons/chevron-left';
import ActionMenu from '@rexlabs/action-menu';

const defaultStyles = StyleSheet({
  container: {
    cursor: 'pointer'
  },

  websiteBackButton: {
    paddingTop: '0.8rem',
    paddingBottom: '0.8rem'
  },

  currentWebsite: {
    fontSize: '1.8rem',
    fontWeight: 600,
    lineHeight: '2.6rem'
  },

  dropdownIcon: {
    color: ({ token }) => token('palette.black'),
    height: '16px',
    width: '16px',
    marginLeft: '8px',
    marginTop: '18px'
  },

  leftArrowIcon: {
    height: '16px',
    width: '16px',
    marginRight: '8px',
    marginTop: '2px'
  }
});

function WebsiteSwitcher({ websites, styles: s, currentWebsite }) {
  const websiteOptions = websites.map((website) => {
    return {
      onClick: () =>
        push(ROUTES.CONTENT_POSTS, {
          params: { websiteId: website?.id }
        }),
      label: website.name,
      subLabel: website?.domains.items[0]?.domain || '',
      useAlternateBackground: false
    };
  });

  const websiteBackButton = {
    label: (
      <Box flexDirection={'row'} {...s('websiteBackButton')}>
        <ArrowLeft {...s('leftArrowIcon')} />
        Back to website list
      </Box>
    ),
    useAlternateBackground: true,
    onClick: () => {
      push(ROUTES.WEBSITES);
    }
  };

  // Add the back button to the action menu
  // TODO limit height of action menu and add scrollbar
  websiteOptions.push(websiteBackButton);

  return (
    <ActionMenu
      items={websiteOptions}
      Button={forwardRef((props, ref) => (
        <Box {...props} ref={ref} flexDirection='row' {...s('container')}>
          <Body {...s('currentWebsite')}>{currentWebsite?.name}</Body>
          {!!currentWebsite?.name && <ArrowDownFilled {...s('dropdownIcon')} />}
        </Box>
      ))}
    />
  );
}

export default styled(defaultStyles)(WebsiteSwitcher);
