export default {
  LOGIN: {
    config: {
      path: '/login',
      Component: require('view/screens/auth/login').default
    }
  },

  FORGOT_PASSWORD: {
    config: {
      path: '/forgot-password',
      Component: require('view/screens/auth/forgot-password').default
    }
  }
};
