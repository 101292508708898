import React, { useMemo } from 'react';
import { compose } from 'redux';
import Box from '@rexlabs/box';
import TopBar from '@rexlabs/top-bar';
import {
  RenderMatchedRoutes,
  withWhereabouts
} from '@rexlabs/react-whereabouts';
import { match } from '@rexlabs/whereabouts';
import { StyleSheet, styled } from '@rexlabs/styling';
import { TwoTierNavigation } from '@rexlabs/navigation';
import { withQuery } from '@rexlabs/model-generator';
import ROUTES from 'src/routes/app';
import getNavigation from 'data/navigation';
import HeaderAvatar from 'src/view/components/header/avatar';
import HelpButton from 'src/view/components/header/help-button';
import WebsiteSwitcher from 'src/view/components/header/website-switcher';
import NotFoundScreen from 'view/screens/not-found';
import { websitesQuery } from 'data/queries/websites';
import { PrimaryButton, GhostButton } from '@rexlabs/button';
import { api } from 'utils/api-client';
import { useToast } from '../components/toast';

import LogoSvg from 'assets/rex-websites-logo.svg';

const defaultStyles = StyleSheet({
  container: {
    overflow: 'hidden'
  },

  innerContainer: {
    overflow: 'auto'
  },

  logo: {
    height: 'auto',
    width: '14rem',
    color: ({ token }) => token('color.primary.idle.default')
  }
});

function AppLayout({ whereabouts, websites, styles: s }) {
  const [matched, NAVIGATION] = useMemo(() => {
    const matched = match(whereabouts, { path: '/website/:websiteId' });
    return [matched, getNavigation({ match: matched })];
  }, [whereabouts]);

  const { addToast } = useToast();

  const websitesData = websites?.list?.items;
  const currentWebsite =
    matched &&
    websitesData.find((website) => website.id === matched.params.websiteId);
  const primaryDomain = currentWebsite?.domains?.items?.find(
    (domain) => domain.is_primary
  )?.domain;

  const clearCache = () => {
    api
      .get(`/websites/${currentWebsite.id}/cache`)
      .then((res) => {
        if (res.status === 200) {
          addToast?.({
            type: 'success',
            title: 'Cache cleared',
            content: 'Cache has been successfully cleared.'
          });
        }
      })
      .catch(() => {
        addToast?.({
          title: 'Error',
          type: 'error',
          content: 'Unable to clear cache. Please try again later.'
        });
      });
  };

  return (
    <Box flexDirection='row' h='100%'>
      {matched && <TwoTierNavigation items={NAVIGATION} />}

      <Box flexDirection='column' w='100%' {...s('container')}>
        <TopBar
          left={
            matched ? (
              <WebsiteSwitcher
                websites={websitesData
                  .filter((website) => website.id !== matched.params.websiteId)
                  .sort((a, b) => a.name > b.name)}
                currentWebsite={currentWebsite}
              />
            ) : (
              <LogoSvg {...s('logo')} />
            )
          }
          right={
            <Box flexDirection={'row'} spacing={'16px'}>
              {currentWebsite && (
                <GhostButton onClick={clearCache}>Clear Cache</GhostButton>
              )}
              {primaryDomain && (
                <PrimaryButton
                  onClick={() =>
                    window.open('http://' + primaryDomain, '_blank')
                  }
                >
                  Preview Website
                </PrimaryButton>
              )}
              <HelpButton />
              <HeaderAvatar />
            </Box>
          }
        />

        <Box flexDirection='row' h='100%' {...s('innerContainer')}>
          <RenderMatchedRoutes routes={ROUTES} NotFound={NotFoundScreen} />
        </Box>
      </Box>
    </Box>
  );
}

export default compose(
  withWhereabouts,
  withQuery(websitesQuery),
  styled(defaultStyles)
)(AppLayout);
