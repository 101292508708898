import Generator from '../generator';
import { api } from 'utils/api-client';

const config = {
  entities: {
    api: {
      fetchList: (type, { websiteId, ...args }) =>
        api.get(`/websites/${websiteId}/pages`, args)
    }
  }
};

export default new Generator('pages', config).createEntityModel({});
