import React from 'react';
import { Helmet } from 'react-helmet';
import { withQuery } from '@rexlabs/model-generator';
import FormEditScreen from 'view/dialogs/forms/tabs/edit';
import FormActionList from 'view/dialogs/forms/tabs/action-list';
import { Tabs } from '@rexlabs/tabs';
import { push } from '@rexlabs/whereabouts';
import { formsQuery } from 'data/queries/forms';
import Screen from 'view/components/screen';
import Box from '@rexlabs/box';
import { Heading } from '@rexlabs/text';
import { LinkButton } from '@rexlabs/button';
import ROUTES from 'src/routes/app';

function CreateFormScreen({ match }) {
  const heading = {
    FORM_CREATE_NEW: 'Create New Form',
    FORM_EDIT: 'Edit Form'
  };

  return (
    <Screen>
      <Box sx={'32px'} flexDirection={'row'} alignItems={'flex-end'}>
        <Heading style={{ margin: 0 }}>{heading[match.key]}</Heading>
        <Box>
          <LinkButton
            onClick={() =>
              push(ROUTES.FORMS_LIST, {
                params: {
                  websiteId: match?.params?.websiteId
                }
              })
            }
          >
            Go back
          </LinkButton>
        </Box>
      </Box>
      <Helmet>
        <link
          id='bootstrap'
          rel='stylesheet'
          href='https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css'
        />
      </Helmet>
      <Box>
        <Tabs
          initialTab='build'
          items={[
            {
              name: 'build',
              label: 'Build',
              Tab: () => <FormEditScreen match={match} />
            },
            {
              name: 'actions',
              label: 'Actions',
              Tab: () => <FormActionList match={match} />
            }
          ]}
        />
      </Box>
    </Screen>
  );
}

export default withQuery(formsQuery)(CreateFormScreen);
