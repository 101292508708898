import React, { useState } from 'react';
import { compose } from 'lodash/fp';

import Box from '@rexlabs/box';
import { StyleSheet, styled, StylesProps } from '@rexlabs/styling';
import { Body, Label } from '@rexlabs/text';
import Tooltip from '@rexlabs/tooltip';

import { Block } from 'data/models/entities/blocks';

import Drag from 'assets/icons/theme-settings/drag.svg';
import Edit from 'assets/icons/theme-settings/edit.svg';
import Show from 'assets/icons/theme-settings/show.svg';
import Hidden from 'assets/icons/theme-settings/hidden.svg';
import Locked from 'assets/icons/theme-settings/locked.svg';

interface BlockItemProps {
  item: Block;
  toggle: (id: string) => void;
  handleEditClick: (...any) => any;
  handleToggleClick: (...any) => any;
}

const defaultStyle = StyleSheet({
  container: {
    cursor: 'grab',
    transition: 'background-color 300ms ease',
    border: '1px solid #F1F1F3',
    borderRadius: 4,
    padding: 20,
    ':hover': {
      transition: 'background-color 300ms ease',
      background: '#F6F7FE',
      'div:nth-child(1) > svg:nth-child(1)': {
        visibility: 'visible'
      }
    }
  },
  containerStatic: {
    cursor: 'inherit'
  },
  editContainer: {
    transition: 'background-color 300ms ease',
    padding: 8,
    height: 32,
    borderRadius: 16,
    cursor: 'pointer',
    '> *': {
      cursor: 'pointer'
    },
    ':hover': {
      transition: 'background-color 300ms ease',
      background: '#E8ECFC'
    }
  },
  hide: {
    transition: 'visibility 3000ms ease',
    visibility: 'hidden'
  },
  hidden: {
    '> svg': {
      marginRight: 8
    },
    '> label': {
      cursor: 'pointer',
      color: '#4768EB'
    }
  },
  left: {
    minHeight: 32,
    '> p': {
      padding: 0
    }
  }
});

function BlockListItem({
  styles: s,
  handleEditClick,
  handleToggleClick,
  item
}: BlockItemProps & StylesProps<any>) {
  const [hover, setHover] = useState(false);
  const onHover = () => setHover(true);
  const onHoverLeave = () => setHover(false);

  return (
    <Box
      {...s('container', { containerStatic: item?.static })}
      justifyContent='space-between'
      // TODO: fix types
      // eslint-disable-next-line
      // @ts-ignore
      onMouseEnter={onHover}
      onMouseLeave={onHoverLeave}
    >
      <Box {...s('left')} flexDirection={'row'} alignItems={'center'}>
        <Box mr={12} w={12}>
          {item.static ? (
            <Tooltip
              Content={() => 'This component can not be moved or hidden.'}
              placement={'bottom-start'}
            >
              <Locked />
            </Tooltip>
          ) : (
            <Drag />
          )}
        </Box>
        <Body bold>{item.label}</Body>
      </Box>
      <Box {...s('right')} alignItems={'center'}>
        <Box
          {...s('editContainer')}
          alignItems={'center'}
          justifyContent={'center'}
          // TODO: fix types
          // eslint-disable-next-line
          // @ts-ignore
          onClick={() => handleEditClick(item.id)}
          data-testid={`${item.label
            .toLowerCase()
            .replace(' ', '-')}-edit-button`}
        >
          {hover && (
            <>
              <Edit />
            </>
          )}
        </Box>
        {/* If block is static then we don't want to allow it to be toggled on. */}
        {!item?.static && (
          <Box
            {...s('editContainer')}
            alignItems={'center'}
            justifyContent={'center'}
            // TODO: fix types
            // eslint-disable-next-line
            // @ts-ignore
            onClick={() => handleToggleClick?.(item)}
            data-testid={`${item.label
              .toLowerCase()
              .replace(' ', '-')}-hide-button`}
          >
            {!item.enabled ? (
              <Box flexDirection={'row'} alignItems={'center'} {...s('hidden')}>
                <Hidden />
                <Label>Hidden</Label>
              </Box>
            ) : (
              <>{hover && <Show />}</>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default compose(styled(defaultStyle))(BlockListItem);
